/**
 *订单管理
 */
const setStoredValue = () => import("@/pages/trade/stored/setStoredValue.vue");
const mainStoredValue = () => import("@/pages/trade/stored/mainStoread.vue");
const storedList = () => import("@/pages/trade/stored/storedList/storedList.vue");
const editStoredInfo = () => import("@/pages/trade/stored/editStored/editStoredInfo.vue");
const storedRuleList = () => import("@/pages/trade/stored/storedRuleList/storedRuleList.vue");
const storedDetail = () => import("@/pages/trade/stored/storedDetail/index.vue");

const mainStoredListInfo = () => import("@/pages/trade/stored/mainStoredList/mainStoredListInfo.vue");
const userList = () => import("@/pages/trade/stored/userList.vue");




export default [
    {
        path: "/trade/stored/setStoredValue",
        name: "setStoredValue",
        component: setStoredValue,
        meta: {
            isStoreManage: false,
        },
    },
    {
        path: "/trade/stored/mainStoread",
        name: "mainStoread",
        component: mainStoredValue,
        meta: {
            isStoreManage: false,
        },
    },
    {
        path: "/trade/stored/storedList/storedList",
        name: "storedList",
        component: storedList,
        meta: {
            isStoreManage: false,
        },
    },
    {
        path: "/trade/stored/editStored/editStoredInfo",
        name: "editStoredInfo",
        component: editStoredInfo,
        meta: {
            isStoreManage: false,
        },
    },
    {
        path: "/trade/stored/storedRuleList/storedRuleList",
        name: "storedRuleList",
        component: storedRuleList,
        meta: {
            isStoreManage: false,
        },
    },
    {
        path: "/trade/stored/storedDetail",
        name: "storedDetail",
        component: storedDetail,
        meta: {
            isStoreManage: false,
        },
    },
    {
        path: "/trade/stored/mainStoredList",
        name: "mainStoredList",
        component: mainStoredListInfo,
        meta: {
            isStoreManage: false,
        },
    },
    {
        path: "/trade/stored/userList",
        name: "/trade/stored/userList",
        component: userList,
    },
    
];
