
// 商城
//分类管理
const shopClassify = () => import(/*  webpackChunkName: "shopClassify" */ '../../pages/shop/classify/classifyList.vue');
const shopclassifyAdd = () => import(/*  webpackChunkName: "shopclassifyAdd" */ '../../pages/shop/classify/classifyAdd.vue');
// 商品管理
const shopGoods = () => import(/*  webpackChunkName: "shopGoods" */ '../../pages/shop/goods/goodsList.vue');
const shopGoodsAdd = () => import(/*  webpackChunkName: "shopGoodsAdd" */ '../../pages/shop/goods/goodsAdd.vue');
//库存
const shopStock = () => import(/*  webpackChunkName: "shopGoodsAdd" */ '../../pages/shop/stock/stockList.vue');
//"订单"
const shopOrder = () => import(/*  webpackChunkName: "shopOrder" */ '../../pages/shop/order/orderList.vue');
const shopOrderView = () => import(/*  webpackChunkName: "shopOrderView" */ '../../pages/shop/order/orderView.vue');
//首页banner
const shopBanner = () => import(/*  webpackChunkName: "shopBanner" */ '../../pages/shop/homepage/banner/bannerList.vue');
const shopBannerAdd = () => import(/*  webpackChunkName: "shopBannerAdd" */ '../../pages/shop/homepage/banner/bannerAdd.vue');
//首页分类
const shopHomeClassify = () => import(/*  webpackChunkName: "shopHomeClassify" */ '../../pages/shop/homepage/home-classify/classifyList.vue');
const shopHomeClassifyAdd = () => import(/*  webpackChunkName: "shopHomeClassifyAdd" */ '../../pages/shop/homepage/home-classify/classifyAdd.vue');
//邮费管理
const postage = () => import(/*  webpackChunkName: "shopGoodsAdd" */ '../../pages/shop/postage/postageList.vue');
const postageAdd = () => import(/*  webpackChunkName: "shopGoodsAdd" */ '../../pages/shop/postage/postageAdd.vue');
//接龙
const solitaire = () => import(/*  webpackChunkName: "solitaire" */ '../../pages/shop/solitaire/solitaireList.vue');
const solitaireAdd = () => import(/*  webpackChunkName: "solitaireAdd" */ '../../pages/shop/solitaire/solitaireAdd.vue');
//返佣
const partner = () => import(/*  webpackChunkName: "partner" */ '../../pages/shop/partner/partnerList.vue');
const partnerAdd = () => import(/*  webpackChunkName: "partnerAdd" */ '../../pages/shop/partner/partnerAdd.vue');

//门店管理
const storeManage = () => import(/*  webpackChunkName: "storeManage" */ '../../pages/shop/storeManage/storeManage.vue');

//门店配送
const delivery = () => import(/*  webpackChunkName: "delivery" */ '../../pages/shop/delivery/deliveryList.vue');
const deliveryAdd = () => import(/*  webpackChunkName: "delivery" */ '../../pages/shop/delivery/deliveryAdd.vue');



export default [
	{
		path: '/shopClassify',
		name: 'shopClassify',
		component: shopClassify
	},
	{
		path: '/shopClassify/shopClassifyAdd',
		name: 'shopClassifyAdd',
		component: shopclassifyAdd
	},
	{
		path: '/shopClassify/shopClassifyEdit/:uid',
		name: 'shopClassifyEdit',
		component: shopclassifyAdd
	},
	{
		path: '/shopGoods',
		name: 'shopGoods',
		component: shopGoods
	},
	{
		path: '/shopGoods/shopGoodsAdd',
		name: 'shopGoodsAdd',
		component: shopGoodsAdd
	},
	{
		path: '/shopGoods/shopGoodsEdit/:uid',
		name: 'shopGoodsEdit',
		component: shopGoodsAdd
	},
	{
		path: '/shopStock',
		name: 'shopStock',
		component: shopStock
	},
	{
		path: '/shopOrder',
		name: 'shopOrder',
		component: shopOrder
	},
	{
		path: '/shopOrder/shopOrderView/:id',
		name: 'shopOrderAdd',
		component: shopOrderView
	},
	{
		path: '/homepage',
		name: 'shopBanner',
		component: shopBanner
	},
	{
		path: '/homepage/shopBanner',
		name: 'shopBanner',
		component: shopBanner
	},
	{
		path: '/homepage/shopBanner/shopBannerAdd',
		name: 'shopBannerAdd',
		component: shopBannerAdd
	},
	{
		path: '/homepage/shopBanner/shopBannerEdit/:uid',
		name: 'shopBannerAdd',
		component: shopBannerAdd
	},
	{
		path: '/homepage/shopHomeClassify',
		name: 'shopBanner',
		component: shopHomeClassify
	},
	{
		path: '/homepage/shopHomeClassify/shopHomeClassifyAdd',
		name: 'shopBannerAdd',
		component: shopHomeClassifyAdd
	},
	{
		path: '/homepage/shopHomeClassify/shopHomeClassifyEdit/:uid',
		name: 'shopHomeClassifyEdit',
		component: shopHomeClassifyAdd
	},
	//运费管理
	{
		path: '/postage',
		name: 'postage',
		component: postage
	},
	{
		path: '/postage/postageAdd',
		name: 'postageAdd',
		component: postageAdd
	},
	{
		path: '/postage/postageEdit/:uid',
		name: 'postageAdd',
		component: postageAdd
	},
	{
		path: '/solitaire',
		name: 'solitaire',
		component: solitaire
	},
	{
		path: '/solitaire/solitaireAdd',
		name: 'solitaireAdd',
		component: solitaireAdd
	},
	{
		path: '/solitaire/solitaireEdit/:uid',
		name: 'solitaireEdit',
		component: solitaireAdd
	},
	{
		path: '/partner',
		name: 'partner',
		component: partner
	},
	{
		path: '/partner/partnerAdd',
		name: 'partnerAdd',
		component: partnerAdd
	},
	{
		path: '/partner/partnerEdit/:uid',
		name: 'partnerEdit',
		component: partnerAdd
	},
	//分佣
	//门店管理
	{
		path: '/storeManage',
		name: 'storeManage',
		component: storeManage
	},
	//门店配送
	{
		path: '/delivery',
		name: 'delivery',
		component: delivery
	},
	{
		path: '/delivery/deliveryAdd',
		name: 'deliveryAdd',
		component: deliveryAdd
	},
	{
		path: '/delivery/deliveryEdit/:uid',
		name: 'deliveryEdit',
		component: deliveryAdd
	},
	
];