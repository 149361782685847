// 数据中台
const PrivateDeployment = () => import(/* webpackChunkName: "dataMiddlePlatform" */ '../../pages/dataMiddlePlatform/PrivateDeployment/advertise.vue');

export default [
	{
		path: '/dataMiddlePlatform/PrivateDeployment',
    name: 'PrivateDeployment',
    component: PrivateDeployment
	}
]
