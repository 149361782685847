
//商品管理
const goods = () => import(/*  webpackChunkName: "wxapp" */ '@/pages/wxapp/goods/index.vue');
const packageManage = () => import(/*  webpackChunkName: "wxapp" */ '@/pages/wxapp/goods/package/index.vue');
const packageDetail = () => import(/*  webpackChunkName: "wxapp" */ '@/pages/wxapp/goods/package/detail.vue');

export default [
	{
		path: '/wxapp/goods/index',
		name: 'goods',
		component: goods
	},
	{
		path: '/wxapp/goods/package',
		name: 'packageManage',
		component: packageManage
	},
	{
		path: '/wxapp/goods/package/detail',
		name: 'packageDetail',
		component: packageDetail
	},
]