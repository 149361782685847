export default {
  state: {
    groupList: [],
    fieldList: [],
    selectFormItem: null,
    // 当前选择字段
    activeTab: '',
  },
  mutations: {
    // setSelectFormItem(state, val) {
    //   state.selectFormItem = val;
    // },
    setActiveTab(state, val) {
      if (val === state.activeTab) {
        state.activeTab = '';
        return;
      }
      state.activeTab = val;
    },
    setGroupList(state, val) {
      state.groupList = val;
    },
    updateGroupItem(state, val) {
      const { idx, value } = val;
      state.groupList[idx].name = value;
    },
    setFieldList(state, val) {
      state.fieldList = val;
    },
    setSelectFormItem(state, val) {
      state.selectFormItem = val;
    },
  },
  getters: {
    requireFieldList: (state) => state.fieldList.filter((field) => field.props.isSystem),
    activeFields: (state) => state.activeTab ? state.fieldList.filter((field) => field.props.groupName === state.activeTab) : state.fieldList
      ,
  },
  actions: {},
  modules: {},
  namespaced: true,
};
