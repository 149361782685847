// 门店对比mock
export const contrastAnalysisMock = () => {
  return {
    data: {
      code: 0,
      message: 'success',
      data: {
        xaxis: [
          {
            title: '12月',
            stroe_agv_day: '46',
          },
          {
            title: '11月',
            stroe_agv_day: '45',
          },
        ],
        list: [
          {
            store_id: '93732',
            store_name: '马记永上海万里家乐福店1',
            dimension: '2022-01-04',
            net_sales: { value: 6800.2, contrast: -1 },
            avg_turnover_by_day: 123,
            total_turnover: null,
            avg_turnover_by_store: null,
            total_order_count: null,
            avg_price_by_customer: null,
            associate_rate: null,
            gross_profit_rate: null,
            gross_profit: null,
            turnover_rate: null,
            ac: null,
            flat_effect: { value: 14.624086, contrast: -1 },
            display: '2022-01-04（周二）',
          },
          {
            store_id: '93732',
            store_name: '马记永上海万里家乐福店2',
            dimension: '2022-01-05',
            net_sales: { value: 6984.16, contrast: 1 },
            total_turnover: null,
            avg_turnover_by_store: null,
            total_order_count: null,
            avg_price_by_customer: null,
            associate_rate: null,
            gross_profit_rate: null,
            gross_profit: null,
            turnover_rate: null,
            ac: null,
            flat_effect: { value: 11.264774, contrast: -1 },
            display: '2022-01-05（周三）',
          },
          {
            store_id: '93732',
            store_name: '马记永上海万里家乐福店3',
            dimension: '2022-01-06',
            avg_turnover_by_day: 123,
            net_sales: { value: 11371.62, contrast: 0 },
            total_turnover: { value: null, contrast: 0 },
            avg_turnover_by_store: { value: null, contrast: 0 },
            total_order_count: { value: null, contrast: 0 },
            avg_price_by_customer: { value: null, contrast: 0 },
            associate_rate: { value: null, contrast: 0 },
            gross_profit_rate: { value: null, contrast: 0 },
            gross_profit: { value: null, contrast: 0 },
            turnover_rate: { value: null, contrast: 0 },
            ac: { value: null, contrast: 0 },
            flat_effect: { value: 18.341323, contrast: 0 },
            display: '2022-01-06（周四）',
          },
        ],
      },
    },
  }
}
// 门店分组mock
export const constrastanalysisGroup = () => {
  return {
    data: {
      code: 0,
      data: {
        xaxis: [
          {
            title: '12月',
            stroe_agv_day: '46',
          },
          {
            title: '11月',
            stroe_agv_day: '45',
          },
        ],
        listObj: {
          all: [
            {
              store_id: '93732',
              store_name: '交通枢纽',
              dimension: '2022-01-04',
              net_sales: { value: 6800.2, contrast: -1 },
              avg_turnover_by_day: 123,
              total_turnover: null,
              avg_turnover_by_store: null,
              total_order_count: null,
              avg_price_by_customer: null,
              associate_rate: null,
              gross_profit_rate: null,
              gross_profit: null,
              turnover_rate: null,
              ac: null,
              flat_effect: { value: 14.624086, contrast: -1 },
              display: '2022-01-04（周二）',
            },
            {
              store_id: '93732',
              store_name: '非交通枢纽',
              dimension: '2022-01-04',
              net_sales: { value: 6800.2, contrast: -1 },
              avg_turnover_by_day: 123,
              total_turnover: null,
              avg_turnover_by_store: null,
              total_order_count: null,
              avg_price_by_customer: null,
              associate_rate: null,
              gross_profit_rate: null,
              gross_profit: null,
              turnover_rate: null,
              ac: null,
              flat_effect: { value: 14.624086, contrast: -1 },
              display: '2022-01-04（周二）',
            },
          ],
          detail: {},
        },
      },
    },
  }
}
// 门店分组详情mock
export const storeGroupDetailMock = () => {
  return {
    data: {
      code: 0,
      data: {
        xaxis: [
          {
            title: '12月',
            stroe_agv_day: '46',
          },
          {
            title: '11月',
            stroe_agv_day: '45',
          },
        ],
        list: [
          {
            store_id: '93732',
            store_name: '交通枢纽',
            dimension: '2022-01-04',
            net_sales: { value: 6800.2, contrast: -1 },
            avg_turnover_by_day: 123,
            total_turnover: null,
            avg_turnover_by_store: null,
            total_order_count: null,
            avg_price_by_customer: null,
            associate_rate: null,
            gross_profit_rate: null,
            gross_profit: null,
            turnover_rate: null,
            ac: null,
            flat_effect: { value: 14.624086, contrast: -1 },
            display: '2022-01-04（周二）',
          },
          {
            store_id: '93732',
            store_name: '非交通枢纽',
            dimension: '2022-01-04',
            net_sales: { value: 6800.2, contrast: -1 },
            avg_turnover_by_day: 123,
            total_turnover: null,
            avg_turnover_by_store: null,
            total_order_count: null,
            avg_price_by_customer: null,
            associate_rate: null,
            gross_profit_rate: null,
            gross_profit: null,
            turnover_rate: null,
            ac: null,
            flat_effect: { value: 14.624086, contrast: -1 },
            display: '2022-01-04（周二）',
          },
        ],
      },
    },
  }
}

// 客流分析mock
export const passengerAnalysisMock = () => {
  return {
    data: {
      code: 0,
      message: 'success',
      data: {
        list: [
          {
            first_id: '新客数量',
            first_des: '新客数量',
            second_des: null,
            unit: null,
            values: [
              {
                dimension: '2021_3',
                minute: null,
                value: 549361,
                total: 748636,
                rate: 0.7338158998498603,
                display: '2021(第三季度)',
              },
              {
                dimension: '2021_4',
                minute: null,
                value: 1623891,
                total: 2265916,
                rate: 0.7166598408767139,
                display: '2021(第四季度)',
              },
              {
                dimension: '2022_1',
                minute: null,
                value: 1151553,
                total: 1576023,
                rate: 0.7306701742296908,
                display: '2022(第一季度)',
              },
            ],
          },
          {
            first_id: '老客数量',
            first_des: '老客数量',
            second_des: null,
            unit: null,
            values: [
              {
                dimension: '2021_3',
                minute: null,
                value: 199275,
                total: 748636,
                rate: 0.2661841001501397,
                display: '2021(第三季度)',
              },
              {
                dimension: '2021_4',
                minute: null,
                value: 642025,
                total: 2265916,
                rate: 0.28334015912328614,
                display: '2021(第四季度)',
              },
              {
                dimension: '2022_1',
                minute: null,
                value: 424470,
                total: 1576023,
                rate: 0.2693298257703092,
                display: '2022(第一季度)',
              },
            ],
          },
          {
            first_id: '老客消费金额',
            first_des: '老客消费金额',
            second_des: null,
            unit: null,
            values: [
              {
                dimension: '2021_3',
                minute: null,
                value: 199275,
                total: 748636,
                rate: 0.2661841001501397,
                display: '2021(第三季度)',
              },
              {
                dimension: '2021_4',
                minute: null,
                value: 642025,
                total: 2265916,
                rate: 0.28334015912328614,
                display: '2021(第四季度)',
              },
              {
                dimension: '2022_1',
                minute: null,
                value: 424470,
                total: 1576023,
                rate: 0.2693298257703092,
                display: '2022(第一季度)',
              },
            ],
          },
          {
            first_id: '新客消费金额',
            first_des: '新客消费金额',
            second_des: null,
            unit: null,
            values: [
              {
                dimension: '2021_3',
                minute: null,
                value: 549361,
                total: 748636,
                rate: 0.7338158998498603,
                display: '2021(第三季度)',
              },
              {
                dimension: '2021_4',
                minute: null,
                value: 1623891,
                total: 2265916,
                rate: 0.7166598408767139,
                display: '2021(第四季度)',
              },
              {
                dimension: '2022_1',
                minute: null,
                value: 1151553,
                total: 1576023,
                rate: 0.7306701742296908,
                display: '2022(第一季度)',
              },
            ],
          },
        ],
        xaxis: ['2021(第三季度)', '2021(第四季度)', '2022(第一季度)'],
      },
    },
  }
}

// 客户分层-饼图
export const customerCascaderMock = () => {
  return {
    code: 0,
    message: 'success',
    data: {
      consumption_price: [
        {
          name: '100-200',
          value: 354691,
          percentage: 0.22430669,
        },
        {
          name: '200',
          value: 209565,
          percentage: 0.13252896,
        },
        {
          name: '35-100',
          value: 666740,
          percentage: 0.42164657,
        },
        {
          name: '0-35',
          value: 350281,
          percentage: 0.2215178,
        },
      ],
      consumption_times: [
        {
          name: '3',
          value: 115089,
          percentage: 0.072782315,
        },
        {
          name: '6',
          value: 86341,
          percentage: 0.05460207,
        },
        {
          name: '4',
          value: 62645,
          percentage: 0.039616715,
        },
        {
          name: '1',
          value: 991484,
          percentage: 0.62701476,
        },
        {
          name: '2',
          value: 289461,
          percentage: 0.1830552,
        },
        {
          name: '5',
          value: 36257,
          percentage: 0.022928936,
        },
      ],
      dissipate: [
        {
          name: '180',
          value: 366966,
          percentage: 0.2320694,
        },
        {
          name: '0-7',
          value: 123121,
          percentage: 0.077861756,
        },
        {
          name: '90-180',
          value: 412506,
          percentage: 0.2608689,
        },
        {
          name: '7-21',
          value: 186356,
          percentage: 0.117851585,
        },
        {
          name: '30-90',
          value: 407866,
          percentage: 0.25793457,
        },
        {
          name: '21-30',
          value: 84462,
          percentage: 0.05341379,
        },
      ],
    },
  }
}

// 小程序装修 - 模板获取
export const getMiniTemplateInfoMock = () => {
  return {
    data: {
      "code": 0,
      "message": "处理成功",
      "messageUuid": "1180fd03-c1ac-4865-b5e6-7c94daca407a",
      "data": [
      {
      "type": "fast_food",
      "title": "快餐",
      "image": "https://pic.mzdata.top/f2/ae/f2ae3ebb778742ea9ce977befcfd1e10_1080_2400.jpg"
      },
      {
      "type": "tea",
      "title": "茶饮",
      "image": "https://pic.mzdata.top/f2/ae/f2ae3ebb778742ea9ce977befcfd1e10_1080_2400.jpg"
      },
      {
      "type": "bake",
      "title": "烘焙",
      "image": "https://pic.mzdata.top/f2/ae/f2ae3ebb778742ea9ce977befcfd1e10_1080_2400.jpg"
      }
      ]
      }
  }
}