<script>
export default {
    props: {
        index: {
            type: Number,
            default: () => 0,
        },
        customColumn: {
            type: Object,
            default: () => ({}),
        },
        tableColumn: {
            type: Object,
            default: () => ({}),
        },
        row: {
            type: Object,
            default: () => ({}),
        },
        store: {
            type: Object,
            default: () => ({}),
        },
    },
    computed: {
        isExpand() {
            return this.store.states.expandRows.indexOf(this.row) > -1;
        },
    },
    data() {
        return {
            moreVisible: false,
        };
    },
    render(h) {
        const renderActionsCell = (oCustomColumn, oRow, $index) => {
            const aActionList = oCustomColumn.actions.map((oAction) => {
                if (
                    oAction.show &&
                    !oAction.show(oCustomColumn, oRow, $index)
                ) {
                    return null;
                }

                if (!oAction.children) {
                    return renderButton(oAction, oCustomColumn, oRow, $index);
                }

                return renderPopoverButton(
                    oAction,
                    oCustomColumn,
                    oRow,
                    $index,
                );
            });

            return <span>{aActionList}</span>;
        };
        const renderButton = (oAction, oCustomColumn, oRow, $index) => {
            // 获取tooltip相关数据
            const oTooltip = oAction.tooltip || {};
            // content及其他props
            const { getContent, ...props } = oTooltip;
            const sTooltipContent = getContent
                ? getContent(oCustomColumn, oRow, $index)
                : '';
            // 是否disabled
            const isDisabled =
                oAction.disabled &&
                oAction.disabled(oCustomColumn, oRow, $index);
            // disabled的button支持tooltip需要外面包裹一层
            return (
                <el-tooltip
                    // {props: {}}
                    {...props}
                    content={sTooltipContent}
                    disabled={!sTooltipContent}
                >
                    <span>
                        <el-button
                            type="text"
                            key={oAction.label}
                            clstag={oAction.clstag}
                            disabled={isDisabled}
                            on-click={() =>
                                oAction.callback(oCustomColumn, oRow, $index)
                            }
                        >
                            {oAction.label || oRow[oCustomColumn.prop]}
                        </el-button>
                    </span>
                </el-tooltip>
            );
        };
        const renderPopoverButton = (oAction, oCustomColumn, oRow, $index) => {
            return (
                <el-popover
                    ref="actionPopover"
                    placement="bottom"
                    trigger="hover"
                    popper-class="table-action-popover"
                    on-show={() => (this.moreVisible = true)}
                    on-hide={() => (this.moreVisible = false)}
                >
                    <ul class="table-action-list">
                        {oAction.children.map((oMoreAction) => {
                            // 不显示按钮
                            if (oMoreAction.show && !oMoreAction.show(oRow)) {
                                return null;
                            }
                            // 获取tooltip相关数据
                            const oTooltip = oMoreAction.tooltip || {};
                            // content及其他props
                            const { getContent, ...props } = oTooltip;
                            const sTooltipContent = getContent
                                ? getContent(oRow)
                                : '';
                            // 是否disabled
                            const isDisabled =
                                oMoreAction.disabled &&
                                oMoreAction.disabled(
                                    oMoreAction,
                                    oCustomColumn,
                                    oRow,
                                    $index,
                                );
                            return (
                                <el-tooltip
                                    className="item"
                                    effect="dark"
                                    // {props: {}}
                                    {...props}
                                    content={sTooltipContent}
                                    disabled={!sTooltipContent}
                                >
                                    <li
                                        class={{
                                            'el-select-dropdown__item': true,
                                            'is-disabled': isDisabled,
                                        }}
                                        clstag={oMoreAction.clstag}
                                        on-click={() =>
                                            !isDisabled &&
                                            handleDropDownClick(
                                                oMoreAction,
                                                oCustomColumn,
                                                oRow,
                                                $index,
                                            )
                                        }
                                    >
                                        {oMoreAction.label}
                                    </li>
                                </el-tooltip>
                            );
                        })}
                    </ul>
                    <el-button slot="reference" type="text" key={oAction.label}>
                        {oAction.label}
                        <i
                            class={{
                                'action-icon': true,
                                'el-icon-arrow-up': this.moreVisible,
                                'el-icon-arrow-down': !this.moreVisible,
                            }}
                        />
                    </el-button>
                </el-popover>
            );
        };
        const handleDropDownClick = (oAction, oCustomColumn, oRow, $index) => {
            this.$refs.actionPopover.doClose();
            oAction.callback(oCustomColumn, oRow, $index);
        };
        const renderFilterCell = (oCustomColumn, oRow) => {
            // 获取 filter
            const filter = oCustomColumn.filter;

            return (
                <span>
                    {this.$filters[filter.name](
                        oRow[oCustomColumn.prop],
                        filter.arguments,
                    )}
                </span>
            );
        };
        const renderExpand = (oCustomColumn, oRow, $index) => {
            if (
                oCustomColumn.show &&
                !oCustomColumn.show(oCustomColumn, oRow, $index)
            ) {
                return null;
            }
            const { open = '', close = '' } = oCustomColumn.expandLabel;
            const isDisabled =
                oCustomColumn.disabled &&
                oCustomColumn.disabled(oCustomColumn, oRow, $index);
            const handleExpandClick = (e) => {
                e.stopPropagation();
                this.store.toggleRowExpansion(oRow);
            };

            return (
                <el-button
                    type="text"
                    size="small"
                    disabled={isDisabled}
                    on-click={handleExpandClick}
                >
                    {!this.isExpand ? open : close}
                    <i
                        class={[
                            'action-icon',
                            this.isExpand
                                ? 'el-icon-arrow-up'
                                : 'el-icon-arrow-down',
                        ]}
                    />
                </el-button>
            );
        };
        // 不同类型对应不同渲染结果
        // 展开行
        if (this.customColumn.expand) {
            return renderExpand(this.customColumn, this.row, this.index);
        }
        // 操作类
        if (this.customColumn.actions) {
            return renderActionsCell(this.customColumn, this.row, this.index);
        }
        // 自定义
        if (this.customColumn.renderCell) {
            return this.customColumn.renderCell(h, {
                customColumn: this.customColumn,
                row: this.row,
                $index: this.index,
            });
        }
        // 过滤器
        if (this.customColumn.filter) {
            return renderFilterCell(this.customColumn, this.row);
        }
        // 默认
        return <span>{this.row[this.customColumn.prop]}</span>;
    },
};
</script>
