<template>
  <div class="main-nav" :class="{ 'main-nav-fold': isCollapse }">
    <ul class="menu-list">
      <li
        :class="[
          'menu-list__item',
          { disabled: item.status != 1, 'menu-list__item--active': item.id === currentModuleId },
        ]"
        v-for="item in modules"
        :key="item.key"
        @click="handleClick(item)"
      >
        <MzIcon class="module-icon" :type="item.moduleIcon" :style="{ fontSize: '32px' }" />
        <span style="white-space: pre-wrap">{{ item.moduleName }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  props: {},
  components: {},
  data() {
    return {
    };
  },
  computed: {
    ...mapState({
      modules: (state) => state.menu.modules,
      currentMenu: (state) => state.menu.currentMenu,
      isCollapse: (state) => state.menu.isCollapse,
    }),
    currentModuleId() {
      return this.currentMenu && this.currentMenu.moduleId ? this.currentMenu.moduleId : null;
    },
    
  },

  methods: {
    handleClick(item) {
      this.$emit('change', item);
    },
  },
};
</script>
<style lang="scss" scoped>
.main-nav {
  position: fixed;
  top: 64px;
  left: 0;
  z-index: 999;
  width: 200px;
  height: 100vh;
  background: #fff;
  


}
.menu-list {
  width: 200px;
  height: 100%;
  padding: 16px 20px 16px 12px;

  li {
    list-style: none;
  }
  &__item {
    padding: 8px 0;
    font-size: 13px;
    font-family: PingFangSC-Regular, PingFang SC;
    color: #6b7785;
    line-height: 22px;
    cursor: pointer;
    text-align: center;
    border-radius: 6px;

    &--active,
    &:hover {
      //color: #333333;
      //background: #e6eaff;
      background: #ffffff !important;
      color: #2d4df2;
      font-weight: bold;
    }

    .module-icon {
      font-size: 32px;
      display: block;
      margin-bottom: 4px;
    }
    .menu-icon {
      margin-right: 8px;
      width: 18px;
      height: 18px;
    }

    &.disabled {
      color: rgba(0, 0, 0, 0.25);
      cursor: not-allowed;
      .module-icon {
        color: rgba(0, 0, 0, 0.25);
      }
    }
  }
}

.main-nav-fold {
  width: 88px;
  .menu-list {
    width: 88px;
  }
}
</style>
