<template>
  <el-row class="mb10">
    <el-col :span="24">
      <span class="label">{{ label }}</span>
      <el-button
        :disabled="disabledall"
        :style="linefeed ? 'margin: 0 10px 10px 0' : ''"
        :type="AllChecked"
        v-if="type === 'multiple'"
        :size="size"
        @click="btnAllCheck"
        >全选</el-button
      >
      <el-button
        :type="item.metype"
        v-for="(item, index) in dataList"
        :disabled="item.disabled"
        :key="item[propData.value]"
        :size="size"
        @click="handleBtnItemCheck(index)"
        :style="linefeed ? 'margin: 0 10px 10px 0' : ''"
        >{{ item[propData.label] }}</el-button
      >
    </el-col>
  </el-row>
</template>

<script>
export default {
  name: "btnCheck",
  data() {
    return {
      dataList: []
    };
  },
  props: {
    // 前面的label
    label: {
      type: String,
      default: ""
    },
    // 单选 多选还是 multiple
    type: {
      type: String,
      default: "single"
    },
    // 传过来的数据List
    data: {
      type: Array,
      required: true,
      default: () => []
    },
    // 按钮大小
    size: {
      type: String,
      default: "mini"
    },
    // 传递过来的数据字段: label 显示的对应字段，  value 你所需要的值对应的字段
    propData: {
      type: Object,
      default: () => {
        return { label: "name", value: "code" };
      }
    },
    // 默认选中的按钮的值
    defaultChecked: {
      type: Array,
      default: () => []
    },
    // 禁用按钮
    disabled: {
      type: Array,
      default: () => []
    },
    linefeed: {
      type: Boolean
    },
    disabledall: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    btnAllCheck() {
      if (this.type === "multiple") {
        if (this.dataList.every(item => item.metype === "primary")) {
          this.dataList.map(item => (item.metype = ""));
        } else {
          this.dataList.map(item => (item.metype = "primary"));
        }
      }
    },
    handleBtnItemCheck(index) {
      if (this.type === "single") {
        this.dataList.map((item, i) => {
          if (index !== i) {
            this.$set(item, "metype", "");
          } else {
            this.$set(item, "metype", "primary");
          }
        });
      } else {
        if (this.dataList[index].metype === "primary") {
          this.$set(this.dataList[index], "metype", "");
        } else {
          this.$set(this.dataList[index], "metype", "primary");
        }
      }
    },
    init() {
      let str = this.propData.value || "code";
      let arr = [];
      this.data.map(item => {
        let obj = {};
        if (this.defaultChecked.includes(item[str])) {
          obj.metype = "primary";
        } else {
          obj.metype = "";
        }
        if (this.disabled.includes(item[str])) {
          obj.disabled = true;
        } else {
          obj.disabled = false;
        }
        arr.push({ ...item, ...obj });
      });
      this.dataList = arr;
    }
  },
  mounted() {
    this.init();
  },
  watch: {
    propData() {
      this.init();
    },
    data() {
      this.init();
    },
    checkValue() {
      this.$emit("checkchanged", this.checkValue);
    },
    defaultChecked() {
      let str = this.propData.value || "code";
      this.dataList.map((item, i) => {
        if (this.defaultChecked.includes(item[str])) {
          this.$set(this.dataList[i], "metype", "primary");
        } else {
          this.$set(this.dataList[i], "metype", "");
        }
      });
    },
    disabled() {
      this.dataList.map((item, i) => {
        let str = this.propData.value || "code";
        if (this.disabled.includes(item[str])) {
          this.$set(this.dataList[i], "disabled", true);
        } else {
          this.$set(this.dataList[i], "disabled", false);
        }
      });
    }
  },
  computed: {
    checkValue() {
      let arr =
        this.dataList
          .filter(item => item.metype === "primary")
          .map(item => item[this.propData.value]) || [];
      return arr;
    },
    AllChecked() {
      let str = "";
      let len = this.dataList.length;
      let filterDataLen = this.dataList.filter(
        item => item.metype === "primary"
      ).length;
      if (len === filterDataLen) {
        str = "primary";
      } else {
        str = "";
      }
      return str;
    }
  }
};
</script>

<style scoped>
.el-button--mini {
  padding: 7px 10px;
}
</style>
