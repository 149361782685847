import Request from "./request";

let BIV2_Url = "https://dev.api.wakanda.mzdata.top";
let BIReport_Url = "https://bi.mzdata.top";
// let BIReport_Url = "http://localhost:3000/";
// let BIV2_Url = "";
if (location.origin.includes("://www.mzdata.top")) {
  BIV2_Url = "https://api.wakanda.mzdata.top";
}

// 根路径
const baseURLs = [{
    key: "dataone-production",
    baseURL: "", //兼容之前文件
  },
  {
    key: "data_zuul",
    baseURL: "",
  },
  {
    key: "api/groupon",
    baseURL: "",
  },
  {
    key: "data_order",
    baseURL: "",
  },
  {
    key: "/coupon/api/",
    baseURL: "",
  },
  {
    key: "/api/bridge",
    baseURL: "",
  },
  {
    key: "/intelligent-ordering",
    baseURL: "",
  },
  {
    key: "/admin",
    baseURL: "",
  },
  {
    key: "/dynamic-promotion",
    baseURL: "",
  },
  {
    key: "/inspect-provider",
    baseURL: "",
  },
  {
    key: "/ci-52md/api",
    baseURL: "",
  },
  {
    key: "/pos",
    baseURL: "https://api.mzdata.top",
  },
  {
    key: "/gi-ordering-strategy",
    baseURL: "",
  },
  {
    key: "/server/auth/mzdata",
    baseURL: "",
  },
  {
    key: "/dataone/",
    baseURL: "",
  },
  {
    key: "/dataone/v1",
    baseURL: "https://www.pre.mzdata.top/api",
  },
  {
    key: "/gi-config",
    baseURL: "",
  },
  {
    key: "/gi-report",
    baseURL: "",
  },
  {
    key: "/api/user/",
    baseURL: "",
  },
  {
    key: "/ordering-web",
    baseURL: "",
  },
  {
    key: "/store-inspect",
    baseURL: "",
  },
  {
    key: "/data-platform",
    baseURL: "",
  },
  {
    key: "/om-auth",
    baseURL: "",
  },
  {
    key: "/store-web",
    baseURL: "",
  },
  {
    key: "/ci-cdp-web",
    baseURL: "",
  },
  {
    key: "/api/cdp",
    baseURL: "",
  },
  {
    key: "/wakanda/v3/",
    baseURL: BIV2_Url,
  },
  {
    key: "/api/wx-open-platform",
    baseURL: "",
  },
  // {
  //   key: "/api/v1",
  //   baseURL: BIReport_Url,
  // },
];
const baseURL = "/dataone-api/";
let instance = new Request(baseURL, baseURLs);
export default instance;