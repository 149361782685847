/*
 * 策略管理--路由配置
 */

//策略管理
const eventManagement = () => import('@/pages/eventManagement/list/index.vue');
//策略详情
const eventDetail = () => import('@/pages/eventManagement/detail/index.vue');

export default [
  // 策略管理
  {
    path: '/eventManagement/list',
    name: 'eventManagement',
    component: eventManagement,
  },
  // 策略详情
  {
    path: '/eventManagement/detail',
    name: 'eventDetail',
    component: eventDetail,
  },
];
