// 课程管理
const courseManage = () =>
  import(/* webpackChunkName: "courseManage" */ '@/pages/mzSchool/courseManage/index.vue');
// 课程分类
const courseCategory = () =>
  import(/* webpackChunkName: "courseManage" */ '@/pages/mzSchool/courseManage/Classify.vue');
// 课程列表
const courseList = () =>
  import(/* webpackChunkName: "courseManage" */ '@/pages/mzSchool/courseManage/Course.vue');
// 课程配置
const courseConfig = () =>
  import(/* webpackChunkName: "courseManage" */ '@/pages/mzSchool/courseManage/CourseConfig.vue');

// 课程管理新增/编辑页面
const courseDataHandle = () =>
  import(
    /* webpackChunkName: "courseDataHandle" */ '@/pages/mzSchool/courseManage/CourseDataHandle.vue'
  );
//题库列表
const questionBankList = () =>
  import(/* webpackChunkName: "questionBankList" */ '@/pages/mzSchool/questionBank/List.vue');

// 题库分类
const questionBankCategory = () =>
  import(
    /* webpackChunkName: "questionBankCategory" */ '@/pages/mzSchool/questionBank/Category.vue'
  );

// 题库布局页
const questionBankLayout = () =>
  import(/* webpackChunkName: "questionBankLayout"*/ '@/pages/mzSchool/questionBank/index.vue');

// 题库数据操作页面
const questionBankDataHandle = () =>
  import(
    /* webpackChunkName: "questionBankDataHandle" */ '@/pages/mzSchool/questionBank/components/QuestionDataHandle.vue'
  );

// 试卷布局页
const testPaperLayout = () =>
  import(/* webpackChunkName: "testPaperLayout"*/ '@/pages/mzSchool/testPaper/index.vue');

// 试卷管理
const testPaperCenter = () =>
  import(/* webpackChunkName: "testPaperCenter"*/ '@/pages/mzSchool/testPaper/List.vue');

// 试卷编辑和新增
const testPaperDataHandle = () =>
  import(/* webpackChunkName: "testPaperDataHandle"*/ '@/pages/mzSchool/testPaper/DataHandle.vue');

// 考试中心列表页
const examCentreList = () =>
  import(/* webpackChunkName: "examCentre"*/ '@/pages/mzSchool/examCentre/List.vue');

// 考试中心数据操作页面
const examDataHandle = () =>
  import(/* webpackChunkName: "examDataHandle"*/ '@/pages/mzSchool/examCentre/examDataHandle.vue');

// 考试记录
const examRecords = () =>
  import(/* webpackChunkName: "examRecords"*/ '@/pages/mzSchool/examCentre/ExamRecords.vue');

// 学习计划页面
const unifiedManagementList = () =>
  import(/*webpackChunkName:"unifiedManagement"*/ '@/pages/mzSchool/unifiedManagement/List.vue');

// 学习计划新增
const unifiedManagementDataHandle = () =>
  import(
    /*webpackChunkName:"unifiedManagementDataHandle"*/ '@/pages/mzSchool/unifiedManagement/DataHandle.vue'
  );

// 职级晋升列表页
const learnMapList = () =>
  import(/*webpackChunkName:"learnMapList"*/ '@/pages/mzSchool/learnMap/List.vue');

// 职级晋升编辑页
const learnMapDataHandle = () =>
  import(/*webpackChunkName:"learnMapDataHandle"*/ '@/pages/mzSchool/learnMap/DataHandle.vue');

// 职级晋升阶段
const learnCheckPeople = () =>
  import(/*webpackChunkName:"learnCheckPeople"*/ '@/pages/mzSchool/learnMap/CheckPeople.vue');

// 学习记录页面
const learnMapRecords = () =>
  import(/*webpackChunkName:"learnMapRecords"*/ '@/pages/mzSchool/learnMap/LearnMapRecords.vue');

// 培训统计-列表
const courseStatistics = () =>
  import(/*webpackChunkName:"courseStatistics"*/ '@/pages/mzSchool/statistics/index.vue');

// 培训统计-模板
const courseStatisticsBoard = () =>
  import(/*webpackChunkName:"courseStatisticsBoard"*/ '@/pages/mzSchool/statistics/Board.vue');

// 培训证书操作页
const certificateDataHandle = () =>
  import(
    /*webpackChunkName:"certificateDataHandle"*/ '@/pages/mzSchool/certificate/DataHandle.vue'
  );
// 培训证书列表页
const certificateList = () =>
  import(/*webpackChunkName:"certificateList"*/ '@/pages/mzSchool/certificate/List.vue');

// 证书发放记录
const certificateRecord = () =>
  import(/*webpackChunkName:"certificateRecord"*/ '@/pages/mzSchool/certificate/Records.vue');

// 审批规则操作页
const approvalRuleDataHandle = () =>
  import(
    /*webpackChunkName:"approvalRuleDataHandle"*/ '@/pages/mzSchool/approvalRule/DataHandle.vue'
  );

// 审批规则列表页
const approvalRuleList = () =>
  import(/*webpackChunkName:"approvalRuleList"*/ '@/pages/mzSchool/approvalRule/List.vue');

export default [
  {
    path: '/mzSchool/courseManage/list',
    name: 'course',
    component: courseManage,
    children: [
      //课程分类页面
      {
        path: '/mzSchool/courseManage/courseCategory',
        name: 'courseCategory',
        component: courseCategory,
      },
      // 课程列表页面
      {
        path: '/mzSchool/courseManage/courseList',
        name: 'courseCategory',
        component: courseList,
      },
      // 课程设置页面
      {
        path: '/mzSchool/courseManage/courseConfig',
        name: 'courseConfig',
        component: courseConfig,
      },
    ],
  },
  // 课程内容操作页面
  {
    path: '/mzSchool/courseManage/courseDataHandle',
    name: 'dataHandle',
    component: courseDataHandle,
  },
  // 题库管理
  {
    path: '/mzSchool/questionBank/layout',
    name: 'questionBankLayout',
    component: questionBankLayout,
    children: [
      {
        path: '/mzSchool/questionBank/list',
        name: 'questionBankList',
        component: questionBankList,
      },
      {
        path: '/mzSchool/questionBank/category',
        name: 'questionBankCategory',
        component: questionBankCategory,
      },
    ],
  },
  {
    path: '/mzSchool/questionBank/dataHandle',
    name: 'dataHandle',
    component: questionBankDataHandle,
  },
  // 试卷管理
  {
    path: '/mzSchool/testPaper/layout',
    name: 'testPaper',
    component: testPaperLayout,
    children: [
      {
        path: '/mzSchool/testPaper/center',
        name: 'testPaperCenter',
        component: testPaperCenter,
      },
    ],
  },
  // 试卷管理新增编辑
  {
    path: '/mzSchool/testPaper/dataHandle',
    name: 'testPaperDataHandle',
    component: testPaperDataHandle,
  },
  // 考试中心列表页
  {
    path: '/mzSchool/examCentre/examCentreList',
    name: 'examCentreList',
    component: examCentreList,
  },
  // 考试数据操作页面
  {
    path: '/mzSchool/examCentre/examDataHandle',
    name: 'examDataHandle',
    component: examDataHandle,
  },
  {
    path: '/mzSchool/examCentre/examRecords',
    name: 'examRecords',
    component: examRecords,
  },
  // 学习计划页面
  {
    path: '/mzSchool/unifiedManagement/list',
    name: 'unifiedManagement',
    component: unifiedManagementList,
  },
  // 学考一体新增编辑页面
  {
    path: '/mzSchool/unifiedManagement/dataHandle',
    name: 'unifiedManagementDataHandle',
    component: unifiedManagementDataHandle,
  },
  // 职级晋升列表页面
  {
    path: '/mzSchool/learnMap/List',
    name: 'learnMapList',
    component: learnMapList,
  },
  // 职级晋升编辑页面
  {
    path: '/mzSchool/learnMap/dataHandle',
    name: 'learnMapDataHandle',
    component: learnMapDataHandle,
  },
  // 职级晋升阶段选择页面
  {
    path: '/mzSchool/learnMap/CheckPeople',
    name: 'learnCheckPeople',
    component: learnCheckPeople,
  },
  {
    path: '/mzSchool/learnMap/learnMapRecords',
    name: 'learnMapRecords',
    component: learnMapRecords,
  },
  // 培训统计页面-list
  {
    path: '/mzSchool/statistics/index',
    name: 'courseStatistics',
    component: courseStatistics,
  },
  //  培训统计页面
  {
    path: '/mzSchool/statistics/board/:code',
    name: 'courseStatisticsBoard',
    component: courseStatisticsBoard,
  },
  // 证书编辑保存页面
  {
    path: '/mzSchool/certificate/dataHandle',
    name: 'certificateDataHandle',
    component: certificateDataHandle,
  },
  // 证书列表页面
  {
    path: '/mzSchool/certificate/list',
    name: 'certificateList',
    component: certificateList,
  },
  // 证书列表页面
  {
    path: '/mzSchool/certificate/record',
    name: 'certificateRecord',
    component: certificateRecord,
  },
  // 审批规则操作页面
  {
    path: '/mzSchool/approvalRule/dataHandle',
    name: 'approvalRuleDataHandle',
    component: approvalRuleDataHandle,
  },
  // 审批规则列表页面
  {
    path: '/mzSchool/approvalRule/list',
    name: 'approvalRuleList',
    component: approvalRuleList,
  },
];
