<template>
  <div>
    <div class="app-body-side" v-show="treetype">
      <div>
        <el-tabs v-model="activeNametabs" @tab-click="handleTabClick">
          <el-tab-pane label="门店树" name="first" v-if="store">
            <storeTreetwo
              class="store-tree"
              :isDefaultAllChecked="isDefaultAllChecked_xb"
              ref="storeTree"
              @check-change="storeTreeCheckChanged"
              :isDefaultAllChecked_dan_duo="isDefaultAllChecked_dan_duotype"
              :isStoreAnalysis="isStoreAnalysis"
            ></storeTreetwo>
          </el-tab-pane>
          <template v-if="coupon_type">
            <el-tab-pane label="优惠劵树" name="coupon">
              <commodityTree
                :store_type="true"
                class="store-tree"
                :isDefaultAllChecked="false"
                ref="commodityTree"
                :coupon_type="true"
                :productType="schedulingtype"
                @check-change="commodityTreeCheckChanged"
                :typesofcouponsType="typesofcoupons"
              ></commodityTree>
            </el-tab-pane>
          </template>
          <template v-if="consumeBehvior">
            <el-tab-pane label="策略树" name="consumeBehvior"></el-tab-pane>
            <commodityTree
              :store_type="true"
              class="store-tree"
              :isDefaultAllChecked="false"
              ref="commodityTree"
              :consumeBehvior="true"
              @changeSelectStrategy="handleSelectStrategy"
            ></commodityTree>
          </template>

          <el-tab-pane label="岗位树" name="coupon" v-if="scheduling">
            <commodityTree
              :store_type="true"
              class="store-tree"
              :isDefaultAllChecked="false"
              ref="commodityTree"
              :scheduling="true"
              :schedulingType="schedulingtype"
              @check-change="commodityTreeCheckChanged"
            ></commodityTree>
          </el-tab-pane>

          <el-tab-pane label="菜品树" name="coupon" v-if="product">
            <commodityTree
              :store_type="true"
              class="store-tree"
              :isDefaultAllChecked="false"
              ref="commodityTree"
              :product="true"
              :productType="schedulingtype"
              @check-change="commodityTreeCheckChanged"
            ></commodityTree>
          </el-tab-pane>
          <el-tab-pane label="人群树" name="coupon" v-if="crowd">
            <commodityTree
              :store_type="true"
              class="store-tree"
              :isDefaultAllChecked="false"
              ref="commodityTree"
              :crowd="true"
              :productType="false"
              @check-change="commodityTreeCheckChanged"
            ></commodityTree>
          </el-tab-pane>

          <el-tab-pane label="标签树" name="coupon" v-if="labelTree">
            <commodityTree
              :store_type="true"
              class="store-tree"
              :isDefaultAllChecked="false"
              ref="commodityTree"
              :labelTree="true"
              :productType="false"
              @check-change="commodityTreeCheckChanged"
            ></commodityTree>
          </el-tab-pane>

          <el-tab-pane label="品类树" name="coupon" v-if="category">
            <commodityTree
              :store_type="true"
              class="store-tree"
              :isDefaultAllChecked="categoryDefaultAllChecked"
              ref="commodityTree"
              :category="true"
              @check-change="commodityTreeCheckChanged"
            ></commodityTree>
          </el-tab-pane>

          <el-tab-pane label="分类树" name="coupon" v-if="classification">
            <commodityTree
              class="store-tree"
              ref="commodityTree"
              :classifications="true"
              @check-change="commodityTreeCheckChanged"
            ></commodityTree>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import storeTreetwo from "./storeTree_two";
import commodityTree from "./commodityTree_two";
import debounce from "lodash/debounce";

export default {
  props: {
    // 是否是订货分析
    isStoreAnalysis: {
      type: Boolean,
      default: false,
    },
    //默认选项
    activeName: {
      type: String,
      default: "first",
    },
    //显示优惠劵选项
    coupon_type: {
      type: Boolean,
      default: false,
    },
    //显示岗位选项
    scheduling: {
      type: Boolean,
      default: false,
    },
    //显示分类树
    classification: {
      type: Boolean,
      default: false,
    },
    //显示菜品选项
    product: {
      type: Boolean,
      default: false,
    },
    //岗位菜品分析 (单选  多选) 选项
    schedulingtype: {
      type: Boolean,
      default: false,
    },
    isDefaultAllChecked_xb: {
      type: Boolean,
      default: false,
    },
    isDefaultAllChecked_dan_duotype: {
      type: Boolean,
      default: true,
    },
    // 人群
    crowd: {
      type: Boolean,
      default: false,
    },
    category: {
      type: Boolean,
      default: false,
    },
    // 标签树
    labelTree: {
      type: Boolean,
      default: false,
    },
    // 是否显示门店 默认显示
    store: {
      type: Boolean,
      default: true,
    },
    consumeBehvior: {
      type: Boolean,
      default: false,
    },
    /*********************************************券 满减  兑换类型  区分***************************************************************/
    typesofcoupons: {
      type: Boolean,
    },
    //控制是否显示 收起门店树
    WhetherTree: {
      type: Boolean,
      default: false,
    },
    categoryDefaultAllChecked: {
      type: Boolean,
      default: false,
    }
  },
  watch: {
    activeName() {
      this.activeNametabs = this.activeName;
    },
    "$store.state.tenant.tree_all_type"() {
      if (this.$store.state.tenant.tree_all_type == 0) {
        this.treetype = false;
      } else {
        this.treetype = true;
      }
    },
  },
  data() {
    return {
      dataValue: {
        node_code_list: [],
        labelName: [],
        couponIds: [],
      },
      treetype: false,
      // treetype: true,
      activeNametabs: "first",
    };
  },
  components: {
    storeTreetwo,
    commodityTree,
  },
  methods: {
    storeTreeCheckChanged({ storeCodes, tagList, currentTab }) {
      this.$set(this.dataValue, 'node_code_list', storeCodes)
      this.$set(this.dataValue, 'labelName', tagList)
      this.$set(this.dataValue, 'currentTab', currentTab)
      this.reday_data();
    },
    commodityTreeCheckChanged({
      _data,
      nodeCodes,
      checked_level,
      couponIds,
      couponIds_level,
      postsData,
      product,
      crowd,
      labeltree,
      classificationtree,
      totalLevel
    }) {
      this.$set(this.dataValue, "nodeCodes", nodeCodes);
      this.$set(this.dataValue, "checked_level", checked_level);
      this.$set(this.dataValue, "couponIds", couponIds);
      this.$set(this.dataValue, "product", product);
      this.$set(this.dataValue, "couponIds_level", couponIds_level);
      this.$set(this.dataValue, "classificationtree", classificationtree);
      this.$set(this.dataValue, "_data", _data);
      this.$set(this.dataValue, "totalLevel", totalLevel);

      this.dataValue.postsData = postsData;
      this.dataValue.crowd = crowd;
      this.dataValue.labeltree = labeltree;
      this.reday_data();
    },
    // 消费行为分析
    handleSelectStrategy(val, data) {
      this.$emit('changeSelectStrategy', val, data)
    },
    handleTabClick(tab, event) {
      console.log(tab, event);
      this.$emit('changeActiveName', tab.name)

    }
  },
  mounted() {
    this.$store.commit("WhetherTree_updata", this.WhetherTree);
    // console.log(this.props)
  },
  created() {
    if (this.activeName === "coupon") {
      this.activeNametabs = this.activeName;
    }
    this.reday_data = debounce(() => {
      this.$emit("alltree", this.dataValue);
    }, 300);
  },
};
</script>

<style lang="scss" scoped>
  .app-body-side {
    z-index: 10
  }
</style>
