<template>
  <div>
    <el-input
      prefix-icon="el-icon-search"
      class="filter"
      placeholder="输入关键字过滤"
      :clearable="true"
      v-model="filterText"
      @input="search_tree"
      :disabled="activeName1 == 'label'"
    ></el-input>
    <div class="tabContainer">
      <ul class="tree-tab">
        <li
          v-for="(item, index1) in treeTabList"
          :class="{ 'tree-tab-item': activeName1 == item.code }"
          @click="treeTab(item.code)"
          :key="index1"
        >
          {{ item.name }}
        </li>
      </ul>

      <div class="tree-tab-list-botton"></div>

      <ul class="tree-tab-list">
        <li
          v-for="(item, index2) in btnList"
          :class="{ 'tree-tab--list-item': tabValue_label == item.code }"
          @click="treeTab_label(item.code)"
          :key="index2"
        >
          {{ item.label }}
        </li>
      </ul>
      <!-- v-loading="loading" -->
      <el-tree
        v-if="activeName1 == 'store'"
        class="store-inner-tree"
        :data="data1"
        
        ref="tree1"
        @check-change="handleCheckChange"
        show-checkbox
        node-key="node_code"
        empty-text="暂无内容"
        :indent="15"
        :props="defaultProps"
        :check-on-click-node="true"
        :expand-on-click-node="false"
        :filter-node-method="filterNode"
        default-expand-all
      >
        <span class="el-tree-node" slot-scope="{ node }">
          <span>{{ node.label }}</span>
        </span>
      </el-tree>

      <div v-if="activeName1 == 'label'">
        <el-row :gutter="10">
          <el-col
            :span="24"
            v-for="(item, index) in dataList"
            :key="item.labelType"
          >
            <el-tree
              v-show="tabValue_label === item.labelType"
              class="store-inner-tree"
              :ref="'tagTree' + index"
              @check="test('tagTree' + index)"
              :data="item.labels"
              show-checkbox
              node-key="label"
              empty-text="暂无内容"
              :indent="15"
              :props="defaultProps1"
              :check-on-click-node="true"
              :expand-on-click-node="false"
              @node-click="test('tagTree' + index)"
              default-expand-all
            >
              <span class="el-tree-node" slot-scope="{ node }">
                <span>{{ node.label }}</span>
              </span>
            </el-tree>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import API from "../libs/API";
import debounce from "lodash/debounce";
import { handleFormateData } from "@/util/index";
// import { copyFileSync } from "fs";
import axios from "axios";

export default {
  props: {
    isStoreAnalysis: {
      type: Boolean,
      default: false
    },
    //false 多选 默认选中所有   true 单选 默认补选中所有
    isDefaultAllChecked: {
      type: Boolean,
      default: false
    },
    //切换清空
    isDefaultAllChecked_dan_duo: {
      type: Boolean,
      default: true
    },
    disabledTag: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      treeTabList: [
        // {
        //   name: "全部门店",
        //   code: "store"
        // },
        // {
        //   name: "门店标签",
        //   code: "label"
        // }
      ],
      refList: [],
      tabValue: "",
      tabValue_label: "",
      btnList: [],
      data1: [],
      activeName1: "store",
      filterText: "",
      defaultProps: {
        children: "children",
        label: "node_name"
      },
      defaultProps1: {
        children: "children",
        label: "label"
      },
      dataList: [],
      loading: false,
      tagList: [],
      onetag: ""
    };
  },
  watch: {
    isDefaultAllChecked() {
      //切换单选或者多选 不晴空树   这样会导致页面请求   页面请求是根据 门店空 就不请求
      if (this.isDefaultAllChecked == false) {
        this.$refs.tree1.setCheckedKeys([]);
        this.checkChanged();
      } else {
        this.$refs.tree1.setCheckedKeys([]);
        this.checkChanged();
      }
    }
  },
  methods: {
    treeTab(code) {
      this.activeName1 = code;
      this.tagList = [];
      if (code == "label") {
        this.filterText = "";
      }
    },
    treeTab_label(code) {
      this.tabValue_label = code;
      let url = ''
      if(!this.isStoreAnalysis) {
        url = "/store/getStoreTree?label=" + this.tabValue_label + "&condition=" + this.filterText
      } else {
        url = "/store/getStoreTree?label=" + this.tabValue_label + "&condition=" + this.filterText + "&isStoreAnalysis=1" 
      }
      API.get(url)
        .then(response => {
          let data = JSON.parse(response.data.data);
          console.log('data6666',data)
          this.addIconForData(data[1]);
          this.data1 = data[1];
          if (this.data1 && this.data2 && this.data3) {
            this.$emit("load");
          }

          this.$nextTick(() => {
            // 默认全部选中
            if (this.activeName1 != "label") {
              if (this.isDefaultAllChecked == false) {
                //加这 一层是为了 区分 不是单选的时候默认选中所有
                if (this.isDefaultAllChecked_dan_duo) {
                  let result = [];
                  handleFormateData(data[1], result);
                  // let arr = result.map(item => item.node_code);
                  this.$refs.tree1.setCheckedNodes(data[1]);
                }
              }
            }
            this.checkChanged();
          });
        })
        .catch(error => {
          this.$message.error(error);
        })
        .then(() => {
          // this.loading = false;
        });
    },
    retract() {},
    test(str) {
      this.$nextTick(() => {
        let index = this.refList.indexOf(str);
        this.tagList = this.$refs[str][0].getCheckedKeys(true);

        if (index > -1) {
          for (let i = 0; i < this.refList.length; i++) {
            if (i !== index) {
              this.$refs[this.refList[i]][0].setCheckedKeys([]);
            }
          }
        }

        this.checkChanged();
      });
    },
    handleBtnClick(btnObj) {
      this.btnList.map(item => {
        if (item.code !== btnObj.code) {
          item.activeFlag = false;
        }
      });
      btnObj.activeFlag = true;
      this.tabValue = btnObj.code;
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.node_name.indexOf(value) !== -1;
    },
    addIconForData(data) {
      data.forEach(item => {
        if (item.children && item.children.length > 0) {
          item.icon = "iconfont do-icon-folder";
          this.addIconForData(item.children);
        } else {
          item.icon = "iconfont do-icon-shop";
        }
      });
    },
    getStoreTree() {
      axios.get("/dataone-production/base/allStores").then(response => {
        // this.loading = false;
        if (response.data.status == 1) {
          let obj = response.data.data.all.map((item, index) => {
            let itemData = {};
            itemData.label = item.labelType;
            this.refList[index] = "tagTree" + index;
            if (index === 0) {
              itemData.activeFlag = true;
            } else {
              itemData.activeFlag = false;
            }
            itemData.code = item.labelType;
            return itemData;
          });

          this.btnList = obj;
          this.tabValue = obj[0].label;
          this.tabValue_label = obj[0].code;

          this.dataList = response.data.data.all;
          //最近三个月
          this.$store.commit("NEW_LIST", response.data.data.new);

          let url = ''
          if(!this.isStoreAnalysis) {
            url = "/store/getStoreTree?label=" + this.tabValue_label + "&condition=" + this.filterText
          } else {
            url = "/store/getStoreTree?label=" + this.tabValue_label + "&condition=" + this.filterText + "&isStoreAnalysis=1" 
          }

          API.get(url)
            .then(response => {
              let data = JSON.parse(response.data.data);
              this.addIconForData(data[1]);
              this.data1 = data[1];
              if (this.data1 && this.data2 && this.data3) {
                this.$emit("load");
              }

              this.$nextTick(() => {
                // 默认全部选中

                if (this.isDefaultAllChecked == false) {
                  //加这 一层是为了 区分 不是单选的时候默认选中所有
                  if (this.isDefaultAllChecked_dan_duo) {
                    let result = [];
                    handleFormateData(data[1], result);
                    // let arr = result.map(item => item.node_code);
                    this.$refs.tree1.setCheckedNodes(data[1]);
                  }
                }
              });
            })
            .catch(error => {
              this.$message.error(error);
            })
            .then(() => {
              // this.loading = false;
            });
        }
      });
    }
  },
  mounted() {
    this.getStoreTree();
  },
  created() {
    this.handleCheckChange = debounce((data, checked) => {
      if (this.isDefaultAllChecked) {
        if (checked) {
          let arr = [data.node_code];
          this.$refs.tree1.setCheckedKeys(arr);
        }

        this.checkChanged();
      } else {
        this.checkChanged();
      }
    }, 300);

    // 使用debounce防止check-change被频繁触发
    this.checkChanged = debounce(() => {
      let tagList = this.tagList;
      let storeCodes =
        this.$refs.tree1 == undefined
          ? []
          : this.$refs.tree1.getCheckedKeys(true);
      let currentTab = this.activeName1;
      this.$emit("check-change", { storeCodes, tagList, currentTab });
    }, 300);

    // 搜索功能
    this.search_tree = debounce(() => {
      this.treeTab_label(this.tabValue_label);
    }, 300);
  }
};
</script>
<style scoped lang="scss">
.tree-tab {
  list-style: none;
  display: flex;
  align-items: center;
  // border: 1px solid #eaeaea;
  border-radius: 2px;
  overflow: hidden;
  margin: 0 15px;

  li {
    flex: 1;
    height: 31px;
    line-height: 31px;
    text-align: center;
    color: #999999;
    font-size: 14px;
  }
}

.tree-tab-item {
  background: #1682e6;
  color: #fff !important;
}

.tree-tab-list-botton {
  height: 1px;
  margin: 16px 0 13px;
  background: #e4e4e4;
}

.tree-tab-list {
  list-style: none;
  overflow: hidden;
  margin: 0 15px 0px;

  li {
    float: left;
    padding: 0 11px;
    height: 29px;
    line-height: 29px;
    color: #999999;
    font-size: 12px;
    font-weight: 400;
    border: 1px solid #eaeaea;
    margin: 0 5px 10px 0;
    border-radius: 2px;
     cursor: pointer;
  }
}

.tree-tab--list-item {
  border: 1px solid #1682e6 !important;
  color: #1682e6 !important;
}

.el-button--text.active {
  color: #1682e6;
}

.el-button--text.deActive {
  color: lightgray;
}

.app-body-side {
  position: relative;
}

.collapseBtn {
  width: 10px;
  height: 20px;
  position: fixed;
  top: 50%;
  right: 0;
  margin-top: -10px;
  background-color: red;
}

.store-inner-tree {
  height: calc(100% - 40px);
  overflow-y: scroll;
  margin: 0 15px;
}

.store-inner-tree >>> .el-tree-node__content {
  height: 22px;
}

.store-inner-tree .el-tree-node {
  font-size: 12px;
}

.store-inner-tree .el-tree-node .iconfont {
  font-size: 12px;
}

.store-inner-tree >>> .el-tree-node.is-current {
  background: transparent;
}

.store-inner-tree >>> .el-tree-node:focus > .el-tree-node__content {
  background: transparent;
}

.el-tree-node__content > .el-tree-node__expand-icon {
  color: #262626;
}
</style>
