/**
 * pos - 基础信息
 */
const brand = () => import('@/pages/trade/basicInfo/brand.vue'); // 品牌管理
const channel = () => import('@/pages/trade/basicInfo/channel.vue'); // 品牌管理
const store = () => import('@/pages/trade/basicInfo/store.vue'); // 品牌管理
const tag = () => import('@/pages/trade/basicInfo/tag.vue'); // 品牌管理
const partition = () => import('@/pages/trade/basicInfo/partition.vue'); // 品牌管理

export default [
    {
        path: '/trade/basicInfo/brand',
        name: 'brand',
        component: brand
    },
    {
        path: '/trade/basicInfo/channel',
        name: 'channel',
        component: channel
    },
    {
        path: '/trade/basicInfo/store',
        name: 'store',
        component: store
    }, {
        path: '/trade/basicInfo/tag',
        name: 'tag',
        component: tag
    }, {
        path: '/trade/basicInfo/partition',
        name: 'partition',
        component: partition
    }
]