import { commonRequest, getMenu, getUserInfo } from '@/api/autho';
import { redirectToSSOLogin } from '@/util';
import { getToken } from '@/util/cookie';
import Vue, { getCurrentInstance, ref } from 'vue';
import Router from 'vue-router';
import store from '../store';
import routes from './routes';

Vue.use(Router);
const router = new Router({
  base: '/insight360/',
  routes,
  mode: 'history',
});

//判断登录返回 地址
let hosts = location.host;

let isFirstLoad = true;

//默认跳转到订货日历的租户
const toOrderTenants = [15, 43];

// 获取当前租户VIP过期时间及状态
const getVipExpiredInfo = () => {
  // getVipExpired({ storeId: "" }).then((res) => {
  //   if (res.data.code == 0) {
  //     store.commit('SET_VIP_INFO', res.data.data || {});
  //   } else {
  //     Message.error(res.data.message);
  //   }
  // });
  let tenantInfo = store.state?.tenant?.tenant || {};
  commonRequest({
    apitype: 'dbAuthAddUrl',
    apipath: '/index.php/WxSaasvip/getVipExpiredByTenant',
    argsObj: {
      tenant_id: tenantInfo?.tenantId ?? '',
      // tenant_id: '',
      store_id: '',
    },
  }).then(resData => {
    if (resData.data.code == 0) {
      let { minVipExpired, status, expiredDays } = resData.data.data || {};
      store.commit('SET_VIP_INFO', { vipExpired: minVipExpired, status, expiredDays } || {});
    } else {
      Message.error(resData.data.message);
    }
  })
};

const getMenus = () => {
  return getMenu().then((res) => {
    if (res.data.code === 0) {
      let { menus, module: modules } = res.data.data;
      menus = menus || {};
      for (let modulekey in menus) {
        let menuList = menus[modulekey];
        if (menuList && menuList.length) {
          for (let menuIndex = 0; menuIndex < menuList.length; menuIndex++) {
            let subMenuList = menuList?.[menuIndex]?.children ?? [];
            subMenuList.map((i) => (i.isSecondMenuFlag = true));
          }
        }
      }
      store.commit('setMenus', menus);
      store.commit('setModules', modules);
      return res.data.data;
    } else {
      return false;
    }
  });
};

const getUser = () => {
  return getUserInfo().then((res) => {
    if (!res.data.code) {
      store.commit('SET_STORE_INFO', res.data.data.store);
      store.commit('SET_TENANT_INFO', res.data.data.tenant);
      store.commit('SET_USERINFO_INFO', res.data.data.userInfo || {});
      store.commit('SET_ROLE_INFO', res.data.data.roles || {});
      getVipExpiredInfo()
      return res.data.data;
    } else {
      this.$message.error(res.data.message);
      return false;
    }
  });
};

const getTree = () => {
  const treeListData = store.state.BIV2.treeListData;
  // console.log('treeListData===>', treeListData, store);
  store.dispatch('BIV2/getListData');
};

//查找菜单
const findMenuInfo = (key, value, not, source, childrenKey = 'children') => {
  let reslute = {};
  const handleFind = (menuItem, modules) => {
    menuItem.find((item) => {
      if (Object.keys(reslute).length) return;
      if (typeof item[key] === 'string') {
        item[key] = item[key].trim();
      }
      let flag = not ? item[key] != value : item[key] == value;
      if (flag) {
        //判断isAuthed属性的时候需要判断targetUrl
        if (!item.targetUrl || item.targetUrl == '') {
          item[childrenKey] && handleFind(item[childrenKey], modules);
        } else {
          reslute = item;
        }
      } else if (item[childrenKey]) {
        handleFind(item[childrenKey], modules);
      }
    });
  };

  for (let modules in source) {
    if (Object.keys(reslute).length) return reslute;
    let menuItem = source[modules];
    handleFind(menuItem, modules);
  }
  return reslute;
};

router.beforeEach((to, from, next) => {
  store.commit('WhetherTree_updata', false);
  store.commit('TREE_ALL', 0);
  // store.commit("TREE_ALL", 230)

  let hasToken = getToken();
  /*
  const loadCommonData = () => {
    return new Promise((resolve, reject) => {
      if (
        store.getters.payTypeList.length > 0 &&
        store.getters.payStatusList.length > 0 &&
        store.getters.orderTypeList.length > 0 &&
        store.getters.orderStatusList.length > 0 &&
        store.getters.coreIndicatorList.length > 0 &&
        store.getters.businessTypeList.length > 0
      ) {
        resolve();
      } else {
        // 拉去到数据之后在进行跳转
        let params = {
          codeList: [
            "pay_status",
            "order_status",
            "business_type",
            "pay_type",
            "source_type",
          ],
        };
        axios
          .all([
            getDictList(params),
            getStoreMeasureList({
              type: "customer",
            }),
          ])
          .then((res) => {
            if (res[0].data.status == 1) {
              const list1 = JSON.parse(res[0].data.data["pay_status"].details);
              store.dispatch("setPayStatusList", list1);
              const list2 = JSON.parse(
                res[0].data.data["order_status"].details
              );
              store.dispatch("setOrderStatusList", list2);
              const list3 = JSON.parse(
                res[0].data.data["business_type"].details
              );
              store.dispatch("setBusinessTypeList", list3);
              const list4 = JSON.parse(res[0].data.data["pay_type"].details);
              store.dispatch("setPayTypeList", list4);
              const list5 = JSON.parse(res[0].data.data["source_type"].details);
              store.dispatch("setOrderTypeList", list5);
            }

            if (res[1].data.status === 1) {
              const list = res[1].data.data;
              store.dispatch("setCoreIndicatorList", list);
            }
            resolve();
          });
      }
    });
  };*/
  // console.log(hasToken, to, "hasToken");
  const BIV3Arr = ['yjxm'];
  const homePage = '/patrolTask/homePage'; //首页
  if (hasToken) {
    if (isFirstLoad) {
      // getVipExpiredInfo()
      Promise.all([getMenus(), getUser(), /*loadCommonData(), */ getTree()])
        .then(([menuData, userData]) => {
          if (menuData && userData) {
            isFirstLoad = false;
            menuData.menus = menuData.menus || {};
            menuData.module = menuData.module || [];
            if (to.fullPath === '/overview') {
              //登录进入判断有没有权限，没有自动匹配到有权限的菜单
              let menu = findMenuInfo('targetUrl', to.fullPath, false, menuData.menus);

              // //暂时处理：如果是邻几的进来默认进入订货日历
              // if (toOrderTenants.indexOf(store.state.tenant.tenant.tenantId) > -1) {
              //   next('/storeSmart/orderCalendar');
              // } else if (BIV3Arr.includes(store.state.tenant.tenant.code)) {
              //   // 如果是遇见小面，则进入智能分析v2 -- 经营看板
              //   next('/businessAnalysis/businessBoard');

              // -----2023-0515 - 更改所有租户进入系统,跳转到首页(如果有权限的话)
              let menuHome = findMenuInfo('targetUrl', homePage, false, menuData.menus);
              //查看是否有首页所在的模块
              let moduleIndex = menuData.module.findIndex((i) => i.id === menuHome.moduleId);
              console.log('hasToken===>', menu, menuHome, moduleIndex);
              console.log('process.env', process.env);
              console.log('window.location.hostname', window.location.hostname);

              if (menuHome.isAuthed == 1 && moduleIndex > -1) {
                next(menuHome.targetUrl);
              } else if (menu.isAuthed == 1) {
                next(menu.targetUrl);
              } else {
                menu = findMenuInfo('isAuthed', 1, false, menuData.menus);
                next(menu.targetUrl);
              }
            } else {
              next();
            }
          } else {
            redirectToSSOLogin();
          }
        })
        .catch((err) => {
          redirectToSSOLogin();
        });
    } else {
      next();
    }
  } else {
    redirectToSSOLogin();
  }
});

router.onError((error) => {
  const pattern = /Loading chunk (.*)+ failed/g;
  const isChunkLoadFailed = error.message.match(pattern);
  const targetPath = router.history.pending.fullPath;
  if (isChunkLoadFailed) {
    router.replace(targetPath);
  }
});

// 导出useRouter
export function useRouter() {
  const vm = getCurrentInstance().proxy;
  if (!vm) throw new Error('useRouter must be called in setup function');
  const routerRef = ref(vm.$router);
  return {
    routerRef,
  };
}
// 导出useRoute
export function useRoute() {
  const vm = getCurrentInstance().proxy;
  if (!vm) throw new Error('useRoute must be called in setup function');
  const routeRef = ref(vm.$route);
  return {
    routeRef,
  };
}

export default router;
