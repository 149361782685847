const userManage = () =>
  import(/* webpackChunkName: "autho" */ '../../pages/autho/userManage/index.vue');

//旧版
const oldUserManage = () =>
  import(/* webpackChunkName: "autho" */ '../../pages/autho/userManage/old-userManage.vue');

const roleManage = () =>
  import(/* webpackChunkName: "autho" */ '../../pages/autho/roleManage/index.vue');
export default [
  {
    path: '/autho/userManage',
    name: 'userManage',
    component: userManage,
  },
  {
    path: '/autho/oldUserManage',
    name: 'oldUserManage',
    component: oldUserManage,
  },
  {
    path: '/autho/roleManage',
    name: 'roleManage',
    component: roleManage,
  },
];
