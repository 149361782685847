export default [
  {
    path: "storeAnalysis",
    meta: { title: "订货分析" },
    component: () => import("@/pages/storeAnalysis/index.vue"), //带树结构
    children: [
      {
        path: "index",
        meta: { title: "订货分析", checkAll: true },
        component: () => import("@/pages/storeAnalysis/order/index.vue")
      },
      {
        path: "goal",
        meta: { title: "销售目标分析" },
        component: () => import("@/pages/storeAnalysis/goal/index.vue")
      }
    ]
  }
];
