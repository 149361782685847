const storeManage = () => import(/* webpackChunkName:"wxappOrder" */ '../../../pages/wxapp/store-tiago/storeManage.vue');
const tableManage = () => import(/* webpackChunkName:"wxappOrder" */ '../../../pages/wxapp/store-tiago/tableManage.vue');
const categoryManage = () => import(/* webpackChunkName:"wxappOrder" */ '../../../pages/wxapp/category');

const orderManage = () => import(/* webpackChunkName:"wxappOrder" */ '../../../pages/wxapp/orderManage');
const orderManageOperation = () => import(/* webpackChunkName:"wxappOrder" */ '../../../pages/wxapp/orderManage/operation.vue');

export default [
	{
		path: '/wxapp/order/storeManage',
		name: 'storeManage',
		component: storeManage
  },
  {
		path: '/wxapp/order/tableManage',
		name: 'tableManage',
		component: tableManage
  },
  {
		path: '/wxapp/categoryManage',
		name: 'categoryManage',
		component: categoryManage
	},
	
	{
		path: '/wxapp/orderManage',
		name: 'orderManage',
		component: orderManage
	},
	{
		path: '/wxapp/orderManageOperation/:uid',
		name: 'orderManageOperation',
		component: orderManageOperation
	},
]