import request from '@/libs/API_common.js'
import { httpFilter } from '../../../constanst/index'
import { storeGroupListMock, pureStoreListMock } from '../../../mock/index'

const baseUrl = `/wakanda/v3/tree`

// 门店树 --- 标签
export const storeTag = (params) =>
  request({
    url: `${baseUrl}/tags/store`,
    method: 'GET',
    params,
  })

export const storeTree = (params) =>
  request({
    url: `${baseUrl}/store`,
    method: 'GET',
    params,
  })

export const goodsTag = (params) =>
  request({
    url: `${baseUrl}/tags/goods`,
    method: 'GET',
    params,
  })

export const goodsTree = (params) =>
  request({
    url: `${baseUrl}/goods`,
    method: 'GET',
    params,
  })

// 门店树管理/门店列表
export const storeManageList = (params) =>
  request({
    url: `${baseUrl}/manage/store/search`,
    method: 'POST',
    params,
  })

// 门店详情
export const storeDetil = (params) =>
  request({
    url: `${baseUrl}/manage/store/detail`,
    method: 'POST',
    params,
  })

export const editStore = (params) =>
  request({
    url: `${baseUrl}/manage/store/modify`,
    method: 'POST',
    data: params,
  })

// 门店树管理/标签列表
export const tagList = (params) => {
  console.log('params--', params)
  return request({
    url: `${baseUrl}/manage/tag/searchV2`,
    method: 'POST',
    params,
  })
}

// 标签列表 --- 筛选状态
export const tagListAvailable = (params) =>
  request({
    url: `${baseUrl}/manage/tag/list`,
    method: 'POST',
    params,
  })

// 门店树管理/标签管理 -- 新增标签
export const addTag = (params) =>
  request({
    url: `${baseUrl}/manage/tags/store/add`,
    method: 'POST',
    data: params,
  })

// 门店树管理/标签管理 -- 删除标签
export const delTag = (params) =>
  request({
    url: `${baseUrl}/manage/tags/store/delete`,
    method: 'POST',
    params,
  })

// 门店树管理/标签管理 -- 更新标签标签状态
export const changeTagStatus = (params) =>
  request({
    url: `${baseUrl}/manage/tags/store/available`,
    method: 'POST',
    data: params,
  })

export const editTag = (params) =>
  request({
    url: `${baseUrl}/manage/tags/store/modify`,
    method: 'POST',
    data: params,
  })

// 商品树管理/商品管理 -- 获取列表数据
export const getGoodsList = (params) =>
  request({
    url: `/wakanda/v3/goods-category/goods/name`,
    method: 'GET',
    params,
  })

// 商品树管理/商品管理 -- 修改商品
export const editGoods = (params) =>
  request({
    url: `/wakanda/v3/goods-category/goods/modify`,
    method: 'GET',
    params,
  })

// 商品树管理/品类管理 -- 获取列表数据
export const getCateList = (params) =>
  request({
    url: `/wakanda/v3/goods-category/category/list`,
    method: 'GET',
    params,
  })

// 商品树管理/品类管理 -- 新增
export const addCate = (params) =>
  request({
    url: `/wakanda/v3/goods-manage/category/create`,
    method: 'POST',
    data: params,
  })

// 商品树管理/品类管理 -- 详情
export const editCate = (params) =>
  request({
    url: `/wakanda/v3/goods-manage/category/modify`,
    method: 'GET',
    params,
  })

// 商品树管理/品类管理 -- 详情
export const cateDetail = (params) =>
  request({
    url: `/wakanda/v3/goods-manage/category/details`,
    method: 'GET',
    params,
  })

// 门店分组对应门店列表
const storeGroupList = (params) =>
  request({
    url: `/wakanda/v3/tree/manage/tag/searchV2?key=${params.key}`,
    method: 'POST',
    // noLoading: true,
    data: params,
  })

export const getStoreGroupList = (params) =>
  httpFilter(params, storeGroupList, storeGroupListMock)

// 获取除某个tag外所有门店信息
const pureStoreList = (params) =>
  request({
    url: `/wakanda/v3/tree/manage/tag/exclude`,
    method: 'POST',
    // noLoading: true,
    data: params,
  })

export const getPureStoreList = (params) =>
  httpFilter(params, pureStoreList, pureStoreListMock)

// 添加&更新 门店分组
export const modifyStoreGroup = (params) =>
  request({
    url: `/wakanda/v3/tree/manage/tags/store/addV2`,
    method: 'POST',
    // noLoading: true,
    data: params,
  })
// 更新&门店标签
export const updataStoreTags = (params) =>
  request({
    url: `/wakanda/v3/tree/manage/tags/store/modifyV2`,
    method: 'POST',
    // noLoading: true,
    data: params,
  })
