/**
 *外卖相关
 */
 const takeOut = () => import("@/pages/trade/takeOut"); // 订单搜索
 const goodsMap = () => import("@/pages/trade/takeOut/pages/goodsMap"); // 订单搜索

 export default [
    {
      path: "/trade/takeOut",
      name: "/trade/takeOut",
      component: takeOut,
    },
    {
      path: "/trade/takeOut/goodsMap",
      name: "/trade/takeOut/goodsMap",
      component: goodsMap,
    }
 ];
 