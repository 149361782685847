export default [
  {
    path: "storeAI",
    meta: { title: "智能订货" },
    component: () => import("@/pages/storeAi/index.vue"), //带树结构
    children: [
      {
        path: "orderManage/decision",
        meta: { title: "订货单下发" },
        component: () => import("@/pages/storeAi/order/index.vue")
      },
      {
        path: "orderManage/show",
        meta: { title: "选品查看" },
        component: () => import("@/pages/storeAi/order/store.vue")
      }
    ]
  },
  {
    path: "orderConfig",
    meta: { title: "订货配置" },
    component: () => import("@/pages/storeAi/categoryTree.vue"), //品类树
    children: [
      {
        path: "goodsManage",
        meta: { title: "商品管理" },
        component: () => import("@/pages/storeAi/orderConfig/index.vue")
      }
    ]
  },
  {
    path: "storeType",
    meta: { title: "门店类型" },
    component: () => import("@/pages/storeAi/categoryTree.vue"), //品类树
    children: [
      {
        path: "index",
        meta: { title: "门店类型" },
        component: () => import("@/pages/storeAi/storeType/index.vue")
      }
    ]
  },
  {
    path: "storeGoods",
    meta: { title: "门店选品" },
    component: () => import("@/pages/storeAi/noTree.vue"), //无树
    children: [
      {
        path: "index",
        meta: { title: "门店选品" },
        component: () => import("@/pages/storeAi/storeGoods/index.vue")
      },
      {
        path: "goalManage",
        meta: { title: "销售目标管理" },
        component: () => import("@/pages/storeAi/goalManage/index.vue")
      },
      {
        path: "taskManage",
        meta: { title: "督导任务管理" },
        component: () => import("@/pages/storeAi/taskManage/index.vue")
      },
      {
        path: "taskDetail",
        meta: { title: "任务详情" },
        component: () => import("@/pages/storeAi/taskManage/detail.vue")
      },
      {
        path: "taskBoard",
        meta: { title: "任务看板" },
        component: () => import("@/pages/storeAi/taskManage/board.vue")
      },
      {
        path: "taskBoardDetail",
        meta: { title: "任务看版详情" },
        component: () => import("@/pages/storeAi/taskManage/boardDetail.vue")
      },
    ]
  },

  
  //智能生产模块
  {
    path: "product/selectGoods",
    meta: { title: "门店选品" },
    component: () => import("@/pages/storeAi/product/selectGoods/index.vue")
  }
    
];
