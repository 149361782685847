/*
 * 智能巡店--路由配置
 */

// 营运工作台
const superWorkBench = () => import('@/pages/patrolTask/superWorkBench/index.vue');
// 签到list页面
const signListPage = () => import('@/pages/patrolTask/superWorkBench/sign/signList.vue');
// 门店任务详细list
// const taskListPage = () => import('@/pages/patrolTask/superWorkBench/storeTask/taskList.vue');
// 工单审批list
const storeRectificationPage = () =>
  import('@/pages/patrolTask/superWorkBench/todo/storeRectification.vue');
// 我的待办-任务审批
const storeApprovalPage = () => import('@/pages/patrolTask/superWorkBench/todo/storeTask.vue');
// 督导下的门店巡店记录
const inspectStoreListPage = () =>
  import('@/pages/patrolTask/superWorkBench/inspectStatistics/inspectList.vue');

//智能巡店--门店信息
const patrolBaseStore = () => import('@/pages/profile/storeInfo/list.vue');

//系统设置-门店标签
const patrolBaseStoreTag = () => import('@/pages/profile/storeInfo/Tag/index.vue');

//智能巡店--门店分组
const patrolSalesStore = () => import('@/pages/patrolTask/storeGroup/list.vue');
//智能巡店--营运文档
const operationManual = () => import('@/pages/patrolTask/operationManual/index.vue');

//智能巡店--sop检查项配置
const patrolSopTaskConfig = () => import('@/pages/patrolTask/sopConfig/list.vue');

//智能巡店--sop检查项配置详情页
const patrolSopTaskEdit = () => import('@/pages/patrolTask/sopConfig/edit.vue');

//智能巡店--sop检查表列表
const patrolSopTableList = () => import('@/pages/patrolTask/sopTable/list.vue');
//智能巡店--sop检查表详情
const patrolSopTableDetail = () => import('@/pages/patrolTask/sopTable/edit.vue');

//智能巡店--巡检项配置
const patrolTaskConfig = () => import('@/pages/patrolTask/taskConfig/list.vue');

//公告管理-列表页
const noticeManageList = () => import('@/pages/patrolTask/noticeManage/list.vue');
//公告管理-详情页
const noticeManageDetail = () => import('@/pages/patrolTask/noticeManage/detail.vue');

//巡店任务
const offlinePatrol = () => import('@/pages/patrolTask/offlinePatrol/list.vue');

// 新建巡店任务
const newPatrolTask = () => import('@/pages/patrolTask/offlinePatrol/newPatrolTask/index.vue');
// 编辑巡店任务
const editPatrolTask = () => import('@/pages/patrolTask/offlinePatrol/newPatrolTask/edit.vue');
// 巡店任务-详情
const taskDetail = () => import('@/pages/patrolTask/offlinePatrol/taskDetail/index.vue');

//门店任务
const storeTask = () => import('@/pages/patrolTask/storeTask/storeList.vue');
// 门店任务概览
const storeTaskHome = () => import('@/pages/patrolTask/storeTask/overView/index.vue');
// 门店任务 - 新建/编辑
const newStoreTask = () => import('@/pages/patrolTask/storeTask/newStoreTask/index.vue');
// 门店任务-详情
const storeTaskDetail = () => import('@/pages/patrolTask/storeTask/storeTaskDetail/index.vue');
// 门店任务-详情-详情
const storeTaskDetailList = () =>
  import('@/pages/patrolTask/storeTask/storeTaskDetail/storeTaskDetailList.vue');
// 门店-任务执行详情
const taskExecuteDetail = () => import('@/pages/patrolTask/storeTask/taskExecuteDetail/index.vue');
// 门店任务-数据上报列表
const goodsReportDetail = () => import('@/pages/patrolTask/storeTask/goodsReportDetail/index.vue');
// 门店任务-数据上报详情
const goodsReportEdit = () => import('@/pages/patrolTask/storeTask/goodsReportDetail/edit.vue');
// 门店任务-kpi上报列表
const kpiReportDetail = () => import('@/pages/patrolTask/storeTask/kpiReportDetail/index.vue');
// 门店任务-数据上报详情
const kpiReportEdit = () => import('@/pages/patrolTask/storeTask/kpiReportDetail/edit.vue');

//门店通知
const storeNotice = () => import('@/pages/patrolTask/offlinePatrol/notice/list.vue');
//门店通知编辑
const storeNoticeEdit = () => import('@/pages/patrolTask/offlinePatrol/notice/edit.vue');
//门店通知详情
const storeNoticeDetail = () => import('@/pages/patrolTask/offlinePatrol/notice/detail.vue');
// 门店通知记录
//门店通知详情
const storeNoticeRecord = () => import('@/pages/patrolTask/offlinePatrol/noticeRecord/list.vue');

// 区域报表
const regionalReport = () => import('@/pages/patrolTask/regionalReport/index.vue');

//巡店报表 - BItab展示
const biReportTabList = () => import('@/pages/patrolTask/storeReport/biReportTabList.vue');
//巡店报表 - 内嵌BI
const storeBi = () => import('@/pages/patrolTask/storeReport/storeBi.vue');
//巡店报表 - 门店报表
const storeReport = () => import('@/pages/patrolTask/storeReport/index.vue');
//门店任务-门店任务记录
const storeTaskListReport = () => import('@/pages/patrolTask/storeReport/taskList.vue');
// 门店任务-数据上报明细列表
const storeDataReport = () => import('@/pages/patrolTask/storeDataReport/index.vue');
// 巡店记录报表
const recordReport = () => import('@/pages/patrolTask/recordReport/index.vue');
// 巡店详情报表
const detailReport = () => import('@/pages/patrolTask/detailReport/index.vue');
// 签到记录表
const attendRecord = () => import('@/pages/patrolTask/attendRecord/index.vue');
// 稽核不合格项统计表
const auditUnqualifiedRecord = () => import('@/pages/patrolTask/auditUnqualifiedRecord/index.vue');

// 工单记录表
const workOrderRecord = () => import('@/pages/patrolTask/workOrderRecord/index.vue');
// 工单记录-概览
const workOrderOverView = () => import('@/pages/patrolTask/workOrderRecord/overView/index.vue');
// 工单管理-工单报表
const workOrderReport = () => import('@/pages/patrolTask/workOrderRecord/workOrderReport.vue');

// 巡店异常报表
const inspectExceptionReport = () => import('@/pages/patrolTask/report/inspectException.vue');
// 签到异常报表
const signExceptionReport = () => import('@/pages/patrolTask/report/signException.vue');

// 首页
const home = () => import('@/pages/patrolTask/home/index.vue');

//组织架构
const org = () => import('@/pages/patrolTask/org/index.vue');

// 绩效
const storeTarget = () => import('@/pages/patrolTask/achievement/storeTarget/index.vue');

//  门店订货额目标
const orderAmount = () => import('@/pages/patrolTask/achievement/orderAmount/index.vue');
//绩效管理-指标配置
const targetConfig = () => import('@/pages/patrolTask/achievement/targetConfig/index.vue');
//绩效管理-目标制定
const goalSetting = () => import('@/pages/patrolTask/achievement/goalSetting/index.vue');
//绩效管理-达成情况
const reach = () => import('@/pages/patrolTask/achievement/reach/index.vue');
const reachDetail = () => import('@/pages/patrolTask/achievement/reach/detail.vue');
//经营数据看板配置-列表页
const businessDataBoardSet = () =>
  import('@/pages/patrolTask/achievement/businessDataBoardSet/list.vue');
//经营数据看板配置-编辑页
const businessDataBoardSetEdit = () =>
  import('@/pages/patrolTask/achievement/businessDataBoardSet/edit.vue');

// 问题反馈记录
const feedbackList = () => import('@/pages/patrolTask/feedback/recordList/index.vue');
// 问题反馈-概览
const feedbackOverView = () => import('@/pages/patrolTask/feedback/overView/index.vue');

// 问题反馈---FAQ配置
const FAQConfig = () => import('@/pages/patrolTask/feedback/FAQConfig/index.vue');

// 问题反馈---FAQ配置--详情页
const FAQConfigDetail = () => import('@/pages/patrolTask/feedback/FAQConfig/ConfigDetail.vue');

// 人员管理
const employeeMng = () => import('@/pages/patrolTask/employeeMng/index.vue');

// 下钻页面
const drillDown = () => import('@/pages/patrolTask/homePage/DrillDown.vue');

// 首页
const homePage = () => import('@/pages/patrolTask/homePage/index.vue');

// 首页- 督导统计/稽核统计 的下钻页面
const inspectStatistics = () => import('@/pages/patrolTask/homePage/inspect/inspectStatistics.vue');
// 首页- 门店任务统计 的下钻页面
const storeTaskStatistics = () =>
  import('@/pages/patrolTask/homePage/storeTask/storeTaskStatistics.vue');

//指标管理
const metricManagement = () => import('@/pages/patrolTask/metricManagement/index.vue');
// 值班管理
const onDutyMng = () => import('@/pages/patrolTask/onDutyMng/index.vue');

export default [
  {
    path: '/patrolTask/homePage',
    name: 'homePage',
    component: homePage,
  },
  {
    path: '/patrolTask/superWorkBench',
    name: 'superWorkBench',
    component: superWorkBench,
  },
  {
    path: '/patrolTask/superWorkBench/signList',
    name: 'signListPage',
    component: signListPage,
  },
  // 营运工作台/门店任务
  // {
  //   path: '/patrolTask/superWorkBench/taskList',
  //   name: 'taskListPage',
  //   component: taskListPage,
  // },
  {
    path: '/patrolTask/superWorkBench/workOrder',
    name: 'storeRectificationPage',
    component: storeRectificationPage,
  },
  {
    path: '/patrolTask/superWorkBench/approvalTask',
    name: 'storeApprovalPage',
    component: storeApprovalPage,
  },
  {
    path: '/patrolTask/superWorkBench/inspectList',
    name: 'inspectStoreListPage',
    component: inspectStoreListPage,
  },
  {
    path: '/patrolTask/baseStore',
    name: 'patrolBaseStore',
    component: patrolBaseStore,
  },
  {
    path: '/patrolTask/baseStoreTag',
    name: 'patrolBaseStoreTag',
    component: patrolBaseStoreTag,
  },
  {
    path: '/patrolTask/salesManage/store',
    name: 'patrolSalesStore',
    component: patrolSalesStore,
  },
  {
    path: '/patrolTask/operationManual',
    name: 'operationManual',
    component: operationManual,
  },
  {
    path: '/patrolTask/sopList',
    name: 'patrolSopTaskConfig',
    component: patrolSopTaskConfig,
  },
  {
    path: '/patrolTask/sopDetail',
    name: 'patrolSopTaskEdit',
    component: patrolSopTaskEdit,
  },
  {
    path: '/patrolTask/sopTableList',
    name: 'patrolSopTableList',
    component: patrolSopTableList,
  },
  {
    path: '/patrolTask/sopTableDetail',
    name: 'patrolSopTableDetail',
    component: patrolSopTableDetail,
  },
  {
    path: '/patrolTask/list',
    name: 'patrolTaskConfig',
    component: patrolTaskConfig,
  },
  {
    path: '/noticeManage/list',
    name: 'noticeManageList',
    component: noticeManageList,
  },
  {
    path: '/noticeManage/edit',
    name: 'noticeManageDetail',
    component: noticeManageDetail,
  },
  {
    //巡店任务
    path: '/patrolTask/offlinePatrol',
    name: 'offlinePatrol',
    component: offlinePatrol,
  },
  {
    //巡店任务 - 新建
    path: '/patrolTask/patrolEdit',
    name: 'newPatrolTask',
    component: newPatrolTask,
  },
  {
    //巡店任务 - 编辑
    path: '/patrolTask/editPatrolTask',
    name: 'editPatrolTask',
    component: editPatrolTask,
  },
  {
    //巡店任务 - 详情
    path: '/patrolTask/taskDetail',
    name: 'taskDetail',
    component: taskDetail,
  },
  {
    //门店任务
    path: '/patrolTask/storeTask',
    name: 'storeTask',
    component: storeTask,
  },
  {
    path: '/patrolTask/storeTaskHome',
    name: 'storeTaskHome',
    component: storeTaskHome,
  },
  {
    //门店任务 - 新建/编辑
    path: '/patrolTask/storeEdit',
    name: 'newStoreTask',
    component: newStoreTask,
  },
  {
    //门店任务 - 详情
    path: '/patrolTask/storeTaskDetail',
    name: 'storeTaskDetail',
    component: storeTaskDetail,
  },
  // {
  //   //门店任务 - 详情 - 详情
  //   path: 'patrolTask/storeTaskDetailList',
  //   name: 'storeTaskDetailList',
  //   component: storeTaskDetailList,
  // },
  {
    //数据上报明细
    path: '/patrolTask/storeDataReport',
    name: 'storeDataReport',
    component: storeDataReport,
  },
  {
    path: '/patrolTask/storeNotice',
    name: 'storeNotice',
    component: storeNotice,
  },
  {
    path: '/patrolTask/storeNoticeEdit',
    name: 'storeNoticeEdit',
    component: storeNoticeEdit,
  },
  {
    path: '/patrolTask/storeNoticeDetail',
    name: 'storeNoticeDetail',
    component: storeNoticeDetail,
  },
  {
    path: '/patrolTask/regionalReport',
    name: 'regionalReport',
    component: regionalReport,
  },
  {
    path: '/patrolTask/biReportTabList',
    name: 'biReportTabList',
    component: biReportTabList,
  },
  {
    path: '/patrolTask/storeBi',
    name: 'storeBi',
    component: storeBi,
  },
  {
    path: '/patrolTask/storeReport',
    name: 'storeReport',
    component: storeReport,
  },
  {
    path: '/patrolTask/storeTaskListReport',
    name: 'storeTaskListReport',
    component: storeTaskListReport,
  },
  {
    path: '/patrolTask/recordReport',
    name: 'recordReport',
    component: recordReport,
  },
  {
    path: '/patrolTask/detailReport',
    name: 'detailReport',
    component: detailReport,
  },
  {
    path: '/patrolTask/workOrderRecord',
    name: 'workOrderRecord',
    component: workOrderRecord,
  },
  {
    path: '/patrolTask/workOrderHome',
    name: 'workOrderHome',
    component: workOrderOverView,
  },
  {
    path: '/patrolTask/workOrderReport',
    name: 'workOrderReport',
    component: workOrderReport,
  },
  {
    path: '/patrolTask/inspectExceptionReport',
    name: 'inspectExceptionReport',
    component: inspectExceptionReport,
  },
  {
    path: '/patrolTask/signExceptionReport',
    name: 'signExceptionReport',
    component: signExceptionReport,
  },
  {
    path: '/patrolTask/attendRecord',
    name: 'attendRecord',
    component: attendRecord,
  },
  {
    path: '/patrolTask/auditUnqualifiedRecord',
    name: 'auditUnqualifiedRecord',
    component: auditUnqualifiedRecord,
  },
  {
    path: '/patrolTask/home',
    name: 'home',
    component: home,
  },
  {
    path: '/patrolTask/org',
    name: 'org',
    component: org,
  },
  {
    path: '/patrolTask/storeTarget',
    name: 'storeTarget',
    component: storeTarget,
  },
  {
    path: '/patrolTask/targetConfig',
    name: 'targetConfig',
    component: targetConfig,
  },
  {
    path: '/patrolTask/goalSetting',
    name: 'goalSetting',
    component: goalSetting,
  },
  {
    path: '/patrolTask/reach',
    name: 'reach',
    component: reach,
  },
  {
    path: '/patrolTask/reachDetail',
    name: 'reachDetail',
    component: reachDetail,
  },
  {
    path: '/patrolTask/businessDataBoardSet',
    name: 'businessDataBoardSet',
    component: businessDataBoardSet,
  },
  {
    path: '/patrolTask/businessDataBoardSetEdit',
    name: 'businessDataBoardSetEdit',
    component: businessDataBoardSetEdit,
  },
  {
    path: '/patrolTask/feedbackHome',
    name: 'feedbackHome',
    component: feedbackOverView,
  },
  {
    path: '/patrolTask/feedbackList',
    name: 'feedbackList',
    component: feedbackList,
  },
  // FAQ配置
  {
    path: '/patrolTask/FAQConfig',
    name: 'FAQConfig',
    component: FAQConfig,
  },
  {
    path: '/patrolTask/FAQConfigDetail/:id/:isEdit',
    name: 'FAQConfigDetail',
    component: FAQConfigDetail,
  },
  {
    path: '/patrolTask/orderAmount',
    name: 'orderAmount',
    component: orderAmount,
  },
  {
    path: '/patrolTask/employeeMng',
    name: 'employeeMng',
    component: employeeMng,
  },
  {
    // /:类型/:firstLevelId/:二级id/:三级id
    path: '/patrolTask/drillDown/:type/:firstLevelId?/:secondLevelId?/:thirdLevelId?',
    name: 'drillDown',
    component: drillDown,
  },
  {
    path: 'patrolTask/inspectStatistics',
    name: 'inspectStatistics',
    component: inspectStatistics,
  },
  {
    path: 'patrolTask/storeTaskStatistics',
    name: 'storeTaskStatistics',
    component: storeTaskStatistics,
  },
  {
    path: '/patrolTask/taskExecuteDetail',
    name: 'taskExecuteDetail',
    component: taskExecuteDetail,
  },
  {
    path: '/patrolTask/goodsReportDetail',
    name: 'goodsReportDetail',
    component: goodsReportDetail,
  },
  {
    path: '/patrolTask/goodsReportEdit',
    name: 'goodsReportEdit',
    component: goodsReportEdit,
  },
  {
    path: '/patrolTask/kpiReportDetail',
    name: 'kpiReportDetail',
    component: kpiReportDetail,
  },
  {
    path: '/patrolTask/kpiReportEdit',
    name: 'kpiReportEdit',
    component: kpiReportEdit,
  },
  {
    path: '/patrolTask/storeNoticeRecord',
    name: 'storeNoticeRecord',
    component: storeNoticeRecord,
  },
  // 指标管理
  {
    path: '/patrolTask/metricManagement',
    name: 'metricManagement',
    component: metricManagement,
  },
  // 值班管理
  {
    path: '/patrolTask/onDutyMng',
    name: 'onDutyMng',
    component: onDutyMng,
  },
];
