//最多6条
export const setRecentMenuList = (data) => {
    let recentMenuList  = getRecentMenuList()
    // 去重
    const resIndex = recentMenuList.findIndex(item => item.id === data.id)
    if(resIndex !== -1) {
        recentMenuList.splice(resIndex, 1)
    }
    if(data.constructor === Object){
        recentMenuList.push(data)
    }else if(Array.isArray(data)){
        recentMenuList = recentMenuList.concat(data)
    }else{
        new Error('最近访问菜单列表设置参数有误 期望数据或对象')
    }
    let length = recentMenuList.length
    if(length > 6){
        recentMenuList = recentMenuList.slice(length-6, length)
    }
    localStorage.setItem("recentMenuList", JSON.stringify(recentMenuList))
}

export const getRecentMenuList = () => {
    let recentMenuList = localStorage.getItem("recentMenuList") || '[]'
    return JSON.parse(recentMenuList)
}

export const setCurrentMenuInStorage = (data) => {
    sessionStorage.setItem("currentMenu", JSON.stringify(data))
}

export const getCurrentMenuInStorage = () => {
    let currentMenu = sessionStorage.getItem("currentMenu") || '{}'
    return JSON.parse(currentMenu)
}

