<template>
  <a-drawer
    placement="right"
    :closable="true"
    :maskClosable="true"
    :visible="visible"
    :zIndex="99999"
    title="配置中心"
    class="setting-container"
    @close="onClose"
  >
    <a-menu class="list">
      <a-menu-item
        v-for="(item, index) in manageCenterArr"
        :key="index"
        :class="{
          'list-disabled': item.status == 0,
          active: $route.path == item.path,
        }"
      >
        <router-link :to="item.path">
          <img :src="item.icon" alt="img" />
          {{ item.name }}
        </router-link>
      </a-menu-item>
    </a-menu>
  </a-drawer>
</template>

<script>
import { managecenter } from '@/api/userList'

export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      manageCenterArr: [],
      originStorePath: '',
      originGoodsPath: '',
    }
  },
  created() {
    this.managecenter()
  },
  methods: {
    async managecenter() {
      const res = await managecenter()
      this.manageCenterArr = res.data.data.filter(
        (item) => item.name !== '树标签管理' && item.name !== '优惠券树维护'
      )
      const storeBackUpItem = this.manageCenterArr.find(
        (item) => item.name === '门店树维护'
      )
      const goodsackUpItem = this.manageCenterArr.find(
        (item) => item.name === '商品树维护'
      )
      storeBackUpItem && (this.originStorePath = storeBackUpItem.path)
      goodsackUpItem && (this.originGoodsPath = goodsackUpItem.path)
      this.handleBIv3(this.$route)
    },

    onClose(e) {
      this.$emit('update:visible', false)
    },
    toPage(item) {
      console.log('item===>', item)
      this.$router.push(item.path)
    },
    // 如果是BIV3，则使用单独的门店树管理
    handleBIv3(val) {
      // console.log('route===>', val)
      const storeItem = this.manageCenterArr.find(
        (item) => item.name === '门店树维护'
      )
      const goodsItem = this.manageCenterArr.find(
        (item) => item.name === '商品树维护'
      )
      const userItem = this.manageCenterArr.find(
        (item) => item.name === '用户管理'
      )

      if (val.meta.isBIV3) {
        storeItem && this.$set(storeItem, 'path', '/BIV3/storeManagement')
        goodsItem && this.$set(goodsItem, 'path', '/BIV3/goodsManagement')
        userItem && this.$set(userItem, 'path', '/BIV3/autho/userManage')
      } else {
        storeItem && this.$set(storeItem, 'path', this.originStorePath)
        goodsItem && this.$set(goodsItem, 'path', this.originGoodsPath)
      }
    },
  },
  watch: {
    $route: {
      handler(val) {
        console.log('v3===>', val)
        this.handleBIv3(val)
      },
      deep: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.setting-container {
  top: 48px;

  .head {
    margin-bottom: 10px;

    .title {
      font-weight: 500;
      font-size: 15px;
    }
  }
}
.list {
  border-right: none;
  &-disabled {
    opacity: 0.25;
    cursor: not-allowed;
    background: none !important;
  }

  .ant-menu-item {
    padding: 0;
    img {
      width: 48px;
      height: 48px;
      margin-right: 8px;
    }
    a {
      color: #87898c;
    }
  }

  .active {
    background-color: #fff !important;
    a {
      color: #1969f1;
    }
  }
}
</style>
