/**
 * 店铺装修
 */
const shopColor = () => import("@/pages/trade/decoration/shopColor.vue"); // 店铺颜色
const imgConfig = () => import("@/pages/trade/decoration/imgConfig/index.vue"); // 图片配置
const iconConfig = () => import("@/pages/trade/decoration/iconConfig.vue"); // icon配置
const interfaceConfiguration = () => import("@/pages/trade/decoration/interfaceConfiguration/index.vue"); // 店铺装修
const appletConfiguration = () => import("@/pages/trade/decoration/appletConfiguration/index.vue"); //小程序配置 - scwang90

const iconConfigXsy = () => import("@/pages/trade/decoration/iconConfigXsy.vue"); // 西少爷专用icon配置

export default [
  {
    path: "/trade/decoration/shopColor",
    name: "shopColor",
    component: shopColor,
  },
  {
    path: "/trade/decoration/imgConfig",
    name: "imgConfig",
    component: imgConfig,
  },
  {
    path: "/trade/decoration/iconConfig",
    name: "iconConfig",
    component: iconConfig,
  },
  {
    path: "/trade/decoration/interfaceConfiguration",
    name: "/trade/decoration/interfaceConfiguration",
    component: interfaceConfiguration,
  },

  //小程序配置 - scwang90
  {
    path: "/trade/decoration/appletConfiguration",
    name: "/trade/decoration/appletConfiguration",
    component: appletConfiguration,
  },


  //西少爷专用
  {
    path: "/trade/decoration/iconConfigXsy",
    name: "iconConfigXsy",
    component: iconConfigXsy,
  },
];
