import Request from '@/libs/request';
import { unpack } from '@/util/index';

const request = new Request('/api/flow');
const staffRequest = new Request('/om-auth/api');

// 获取工作流列表
export const getWorkflowList = (params) => {
  return request.get('/template/list', { params }).then(unpack);
};
// 获取工作流详情
export const getWorkflowDetail = (params) => {
  return request.get('/template/detail', { params }).then(unpack);
};
// 保存或编辑工作流
export const saveWorkflow = (params) => {
  return request.post('/template/saveOrUpdate', params).then(unpack);
};
// 发布工作流
export const publishWorkflow = (params) => {
  return request.post('/template/publish', params).then(unpack);
};
// 停用工作流
export const stopWorkflow = (params) => {
  return request.post('/template/stop', params).then(unpack);
};

// 保存表单结构或者编辑
export const saveFormResult = (params) => {
  return request.post('/form/saveOrUpdate', params).then(unpack);
};

// 查询表单结构或者编辑
export const getFormResult = (params) => {
  return request.get('/form/detail', { params }).then(unpack);
};

// 保存单节点
export const saveNode = (params) => {
  return request.post('/node/save', params).then(unpack);
};
// 编辑单节点
export const editNode = (params) => {
  return request.post('/node/update', params).then(unpack);
};

// 节点详情 /node/detail
export const getNodeDetail = (params) => {
  return request.get('/node/detail', { params }).then(unpack);
};

// 删除单节点
export const deleteNode = (params) => {
  return request.post('/node/delete', params).then(unpack);
};

// 查询资料包列表
export const getMaterialList = (params) => {
  return request.post('/node/queryPathPreFields', params).then(unpack);
};

// 查询所有可驳回节点
export const getQueryRefuseNodes = (params) => {
  return request.get('/node/queryRefuseNodes', { params }).then(unpack);
};

// 查看所有节点列表 /node/queryPathPreNodes
export const getQueryPathPreNodes = (params) => {
  return request.get('/node/queryPathPreNodes', { params }).then(unpack);
};

// 查询待办列表 /handle/todoList
export const getTodoList = (params) => {
  return request.get('/handle/todoList', { params }).then(unpack);
};
// 查询待办列表 -聚合
export const getTodoListMerge = (params) => {
  return request.post('/handle/todoAggre', params).then(unpack);
};

// 创建项目 /instance/create
export const createProject = (params) => {
  return request.post('/instance/create', params).then(unpack);
};
// 项目列表/instance/list
export const getProjectList = (params) => {
  return request.get('/instance/list', { params }).then(unpack);
};

// 计算人数
export const countStaff = (params) => {
  return request.post('/template/countStaff', params).then(unpack);
};

// 待办详情/handle/todoDetail => /handle/captionGroup(增加分组)
export const getTodoDetail = (params) => {
  return request.get('/handle/captionGroup', { params }).then(unpack);
};

// 待办通过 /approval/approvalPass
export const approvalPass = (params) => {
  return request.post('/approval/approvalPass', params).then(unpack);
};

// 获取分类列表 /category/list
export const getCategoryList = (params) => {
  return request.get('/category/list', { params, noLoading: true }).then(unpack);
};
// 保存分类/category/saveOrUpdate
export const saveCategory = (params) => {
  return request.post('/category/saveOrUpdate', { ...params }, { noLoading: true }).then(unpack);
};
// 删除分类 /category/delete
export const deleteCategory = (params) => {
  return request.post('/category/delete', params).then(unpack);
};
// 分类排序
export const sortCategory = (params) => {
  return request.post('/category/sort', params).then(unpack);
};

// 获得转接人列表 /approval/getUserInfoList
export const getUserInfoList = (params) => {
  return request.get('/approval/getUserInfoList', { params }).then(unpack);
};

// 驳回功能 /approval/refuse
export const approvalRefuse = (params) => {
  return request.post('/approval/refuse', params).then(unpack);
};

// 转接功能 /approval/transfer
export const approvalTransfer = (params) => {
  return request.post('/approval/transfer', params).then(unpack);
};

// 处理记录列表 /handle/recordList
export const getRecordList = (params) => {
  return request.get('/instance/logs', { params }).then(unpack);
};

// 上传文件 /common/upload
export const uploadFile = (params) => {
  return request.post('/common/upload', params).then(unpack);
};

// 暂存，保存，重复提交 /approval/repeatedSubmit
export const repeatedSubmit = (params) => {
  return request.post('/approval/repeatedSubmit', params).then(unpack);
};

// 任务表单提交并完成 /approval/finishedSubmit
export const finishedSubmit = (params) => {
  return request.post('/approval/finishedSubmit', params).then(unpack);
};

// 删除任务模板 /template/delete
export const deleteTemplate = (params) => {
  return request.post('/template/delete', params).then(unpack);
};

// 根据角色或职位分页查询用户列表 /om-auth/api/auth/org/user/getByRoleOrPost
export const getStaffListByRoleOrPost = (params) => {
  return staffRequest.post('/auth/org/user/getByRoleOrPost', params);
};

// 查找组件的名称列表 /node/queryPathPreFieldsName
export const queryPathPreFieldsName = (params) => {
  return request.post('/node/queryPathPreFieldsName', params).then(unpack);
};

// 分支节点判断符枚举接口 /condition/queryJudgment
export const queryJudgment = (params) => {
  return request.get('/condition/queryJudgment', { params }).then(unpack);
};
// 获取分类统计列表 /instance/categories
export const getCategoryCountList = (params) => {
  return request.get('/instance/categories', { params }).then(unpack);
};

// 获取任务完成情况 /instance/nodes
export const getTaskCompleteList = (params) => {
  return request.get('/instance/nodes', { params }).then(unpack);
};

// 实例详情 /instance/detail
export const getInstanceDetail = (params) => {
  return request.get('/instance/detail', { params }).then(unpack);
};

// 实例统计 /instance/statistic
export const getInstanceStatistic = (params) => {
  return request.get('/instance/statistic', { params }).then(unpack);
};

// 获取资料包列表 /form/queryFormDataForInfoPackage
export const getQueryFormDataForInfoPackage = (params) => {
  return request.post('/form/queryFormDataForInfoPackage', params).then(unpack);
};

// 获取上传人员列表
export const getUploadStaffList = (params) => {
  return request.post('/form/queryFormDataCommitUser', params).then(unpack);
};

// 获取资料包详情上传节点 /node/queryForInfoPackage
export const getQueryForInfoPackage = (params) => {
  return request.post('/node/queryForInfoPackage', params).then(unpack);
};

// 审批记录列表 /handle/approvalLogs
export const getApprovalRecordList = (params) => {
  return request.get('/handle/approvalLogs', { params }).then(unpack);
};

/**发起子流程 */
// 获取子流程列表
export const getLaunchableList = (params) => {
  return request.get('/instance/sub/launchableList', { params }).then(unpack);
};

// 发起子流程-获取表单
export const getLaunchForm = (params) => {
  return request.get('/instance/sub/getLaunchForm', { params }).then(unpack);
};
// 发起子流程-保存表单
export const createSubInstance = (params) => {
  return request.post('/instance/sub/create', params).then(unpack);
};

// 获取发起人列表
export const getCreatorList = (params) => {
  return request.get('/instance/creators', { params }).then(unpack);
};

/** 通知查询功能模块 */
// 通知内容查询 /notify/query
export const getNotifyQuery = (params) => {
  return request.get('/notify/query', { params }).then(unpack);
};
// 通知内容删除 /notify/delete
export const getNotifyDelete = (params) => {
  return request.post('/notify/delete', params).then(unpack);
};
// 通知内容保存 /notify/saveOrUpdate
export const getNotifySaveOrUpdate = (params) => {
  return request.post('/notify/saveOrUpdate', params).then(unpack);
};
// 通知内容的表单字段获取 /notify/getFieldList
export const getNotifyGetFieldList = (params) => {
  return request.post('/notify/getFieldList', params).then(unpack);
};

// 获取文件预览地址
export const getPreviewUrlReq = (params) => {
  return request.get('/common/getOfficePreviewURLRequest', { params }).then(unpack);
};

/** 开发者设置功能模块 */
// 获取开发者 /developer/detail
export const getDeveloperDetail = (params) => {
  return request.get('/developer/detail', { params }).then(unpack);
};
// 开发者设置保存 /developer/saveOrUpdate
export const getDeveloperSaveOrUpdate = (params) => {
  return request.post('/developer/saveOrUpdate', params).then(unpack);
};
// 开发者设置删除 /developer/delete
export const getDeveloperDelete = (params) => {
  return request.get('/developer/del', { params }).then(unpack);
};
// 查询主流程的所有审批任务节点 snapshotId必传
export const queryAllNodes = (params) => {
  return request.get('/node/queryAllNodes', { params }).then(unpack);
};

// 查询历史表单的详情数据
export const getHistoryDetailForm = (params) => {
  return request.post('/form/historyDetail', params).then(unpack);
};
// 保存公共字段
export const savePublicForm = (params) => {
  return request.post('/form/publicFormSave', params).then(unpack);
};
// 获取公共字段
export const getPublicForm = (params) => {
  return request.post('/form/obtainPublic', params).then(unpack);
};
// 获取节点列表
export const getNodeList = (params) => {
  return request.post('/instance/adminList', params).then(unpack);
};
// 修改节点审批人/处理人
export const changeAssignedUser = (params) => {
  return request.post('/instance/reSetAssignUser', params).then(unpack);
};

// 获取项目模板配置-项目监控
export const getInstanceTemplate = (params) => {
  return request.get('/instanceMonitor/getInstanceTemplate', { params }).then(unpack);
};
// 获取项目流程详情-项目监控
export const getInstanceTemplateInfo = (params) => {
  return request.get('/instanceMonitor/formDetail', { params }).then(unpack);
};
// 保存项目流程详情-项目监控
export const saveInstanceTemplateInfo = (params) => {
  return request.post('/instanceMonitor/formSave', params).then(unpack);
};

export const getStoreBuildTodoCount = (params) => {
  return request.get('/handle/todoNum', { params }).then(unpack);
};

// 资料包打包下载
export const downloadAllFilesData = (params) => {
  return request.post('/form/downloadDataPackage', params, { responseType: 'blob' });
};

// 获取项目阶段list
export const getCurrentStageList = (params) => {
  return request.get('/instance/obtainCurrentStage', { params }).then(unpack);
};
// 报表列表 /dataManagement/reportList
export const getReportList = (params) => {
  return request.post('/dataManagement/reportList', params).then(unpack);
};
// 报表配置详情 /dataManagement/reportConfig
export const getReportConfigDetail = (params) => {
  return request.get('/dataManagement/reportConfig', { params }).then(unpack);
};
// 报表配置默认字段 /dataManagement/defaultField
export const getReportConfigDefaultField = (params) => {
  return request.get('/dataManagement/defaultField', { params }).then(unpack);
};
// 报表配置流程字段 /dataManagement/flowField
export const getReportConfigFlowField = (params) => {
  return request.post('/dataManagement/flowField', params).then(unpack);
};
// 报表详情 /dataManagement/reportDetail
export const getReportDetail = (params) => {
  return request.post('/dataManagement/reportDetail', params).then(unpack);
};
// 报表配置保存 /dataManagement/reportSaveOrUpdate
export const saveReportConfig = (params) => {
  return request.post('/dataManagement/reportSaveOrUpdate', params).then(unpack);
};
// 删除报表 /dataManagement/reportDel
export const delReport = (params) => {
  return request.get('/dataManagement/reportDel', { params }).then(unpack);
};
// 导出报表 /dataManagement/export
export const getReportExport = (params) => {
  return request.get('/dataManagement/export', { params: params, responseType: 'blob' });
};

// 项目列表导出
export const exportProjectList = (params) => {
  return request.get('/instance/export', { params: params, responseType: 'blob' });
};

// 开店概览——light
// 阶段统计配置参数
export const getStageSummaryConfig = (params) => {
  return request.post('/board/stage/config', { params }).then(unpack);
};
// 阶段统计
export const getStageSummary = (params) => {
  return request.post('/board/stage/summary', params).then(unpack);
};

// 已开业门店统计
export const getStoreStatusSummary = (params) => {
  return request.post('/board/storeStatus/summary', params).then(unpack);
};

// 门店排名
export const getStoreRank = (params) => {
  return request.post('/board/storeRank', params).then(unpack);
};

// 查询开店目标
export const getStoreGoal = (params) => {
  return request.get('/target/list', { params }).then(unpack);
};
// 查询开店目标
export const saveStoreGoal = (params) => {
  return request.post('/target/save', params).then(unpack);
};

// 修改预计结束时间
export const editFinishTime = (params) => {
  return request.get('/instance/updatePlanFinishTime', { params }).then(unpack);
};

// 开店目标完成情况
export const getTargetYearProcess = (params) => {
  return request.get('/target/year/process', { params }).then(unpack);
};

// 门店统计
export const getBoardStoreSummary = (params) => {
  return request.get('/board/storeSummary', { params }).then(unpack);
};

// 阶段列表
export const getBoardCategoryList = (params) => {
  return request.get('/board/category/list', { params }).then(unpack);
};

// 看版阶段门店排名
export const getBoardCategoryStoreRank = (params) => {
  return request.post('/board/category/storeRank', params).then(unpack);
};

// 逾期预警
export const getBoardOverdueStatistics = (params) => {
  return request.post('/board/overdue/statistics', params).then(unpack);
};

// 暂存
export const draftSave = (params) => {
  return request.post('/approval/draft/save', params).then(unpack);
};

// 获取省市区数据
export const getAreaData = () => {
  return request.get('/form/queryArea').then(unpack);
};

// 获取任务交接页面人员列表
export const getTaskTransferData = (params) => {
  return request.get('/handover/list', { params }).then(unpack);
};

// 获取人员对应的任务列表
export const getTaskListOfStaff = (params) => {
  return request.get('/handover/taskList', { params }).then(unpack);
};

// 任务交接
export const postTaskTransfer = (params) => {
  return request.post('/handover/taskHandover', params).then(unpack);
};

// 获取可更改暂停状态节点列表
export const getInstancePauseList = (params) => {
  return request.get('/instance/pausedList', { params }).then(unpack);
};

// 暂停/恢复节点
export const postInstanceUpdateStatus = (params) => {
  return request.post('/instance/updateStatus', params).then(unpack);
};
