/* eslint-disable no-self-assign */
import moment from "moment";

//根据粒度转换时间
export const timer_arr = (item, meStr) => {

  if (isNaN(item) && !isNaN(Date.parse(item))) {
    let time = "";
    if (meStr === "day") {
      time = moment(item).format("YYYY/MM/DD");
    } else if (meStr === "week") {
      let end = new Date(item);
      end.setTime(end.getTime() + 3600 * 1000 * 24 * 6);
      time = moment(item).format("YYYY") + "/第" + moment(item).week() + "周" + '(' + moment(item).format("MM/DD") + '-' + moment(end).format("MM/DD") + ')';
    } else if (meStr === "month") {
      time = moment(item).format("YYYY/MM");
    } else if (meStr === "hour") {
      time = moment(item).format("YYYY/MM/DD HH:mm");
    } else if (meStr === "quarter") {
      time =
        moment(item).format("YYYY") + "/第" + moment(item).quarter() + "季";
    } else if (meStr === "year") {
      time = moment(item).format("YYYY") + '年';
    } else {
      time = item;
    }

    return time;
  } else {
    return item;
  }
};

// 判断数组是否是多维
export const ifArrVal = (arr) => { //多维数组判断是否存在某值

  for (var i = 0; i < arr.length; i++) {
    if (arr[i] instanceof Array) { //判断是否为多维数组
      return true;
    } else {
      return false;
    }
  }
}

//转化当点击门店标签(groups)数据格式 情况下返回的数据格式  转成普通模式下图表
export const reday_chart = arr => {
  let dataarr = [];

  for (let index = 0; index < arr.groups.length; index++) {
    for (let ind = 0; ind < arr.groups[index].series.length; ind++) {
      arr.groups[index].series[ind].name = arr.groups[index].series[ind].name;

      dataarr.push(arr.groups[index].series[ind]);
    }
  }

  return {
    series: dataarr,
    xValues: arr.xValues
  };
};

// 转换(groups)数据格式 表格的数据格式转换  时间竖排
export const ready_table = (arr, meStr) => {
  let tableDatas = [];
  let tableData = {};

  let {
    firstHeaderName
  } = arr;
  let tableHead = firstHeaderName;


  if (arr.series.length == 0) {
    return {
      tableDatas: [],
      tableHead: []
    };
  };

  arr.series.map(item => {
    tableHead.push(item.name);
  });

  for (let index = 0; index < tableHead.length; index++) {
    tableData[tableHead[index]] = "";
  }

  for (let index = 0; index < arr.series.length; index++) {
    if (index == 0) {
      for (let ind = 0; ind < arr.series[index].data.length; ind++) {
        let data_obj = {
          ...tableData
        };

        if (arr.xValues != undefined && arr.xValues.length != 0) {
          data_obj["时间"] = timer_arr(arr.xValues[ind], meStr);

          data_obj["商品名称"] = arr.xValues[ind];

          data_obj["门店标签"] = arr.xValues[ind];

          data_obj["门店"] = arr.xValues[ind];

          data_obj["优惠券名称"] = arr.xValues[ind];

          data_obj["菜品"] = arr.xValues[ind];
        }


        if (arr.series[index].formatter == "{value}%") {
          data_obj[arr.series[index].name] = arr.series[index].data[ind]
          //+ "%";
        } else {
          if (arr.series[index].name == "时间") {
            data_obj[arr.series[index].name] = timer_arr(arr.series[index].data[ind], meStr);;
          } else {
            data_obj[arr.series[index].name] = arr.series[index].data[ind];
          }

        }

        tableDatas.push(data_obj);
      }
    } else {
      for (let ind = 0; ind < arr.series[index].data.length; ind++) {
        if (arr.series[index].formatter == "{value}%") {
          tableDatas[ind][arr.series[index].name] =
            arr.series[index].data[ind]
          //+ "%";
        } else {
          if (arr.series[index].name == "时间") {
            tableDatas[ind][arr.series[index].name] = timer_arr(arr.series[index].data[ind], meStr);
          } else {
            tableDatas[ind][arr.series[index].name] = arr.series[index].data[ind];
          }
        }
      }
    }
  }

  return {
    tableDatas: tableDatas,
    tableHead: tableHead
  };
};

// 转换(groups)数据格式 表格的数据格式转换  时间横排

export const ready_table_netsales = (arr, meStr) => {
  let tableDatas = [];
  let tableData = {};
  let timeitemarr = arr.xValues.map(item => timer_arr(item, meStr));

  let {
    firstHeaderName
  } = arr;
  let tableHead = firstHeaderName;

  if (arr.series.length == 0) {
    return {
      tableDatas: [],
      tableHead: []
    };
  }

  arr.xValues.map(item => {
    tableHead.push(timer_arr(item, meStr));
  });

  for (let index = 0; index < tableHead.length; index++) {
    tableData[tableHead[index]] = "";
  }


  //判断是否是二维数组  判断是否是多个占位  时间横排  情况 xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx


  if (ifArrVal(arr.series)) {


    for (let ins = 0; ins < arr.series.length; ins++) {


      if (ins == 0) {

        for (let ind = 0; ind < arr.series[ins][0].data.length; ind++) {
          let data_obj = {
            ...arr.series[ins]
          };

          data_obj[arr.series[ins][0].name] = arr.series[ins][0].data[ind];
          tableDatas.push(data_obj)
        }

      } else {


        for (let ind = 0; ind < arr.series[ins][0].data.length; ind++) {


          tableDatas[ind][arr.series[ins][0].name] = arr.series[ins][0].data[ind];

        }


      }

    }


  } else {
    for (let index = 0; index < arr.series.length; index++) {
      let data_obj = {
        ...tableData
      };
      data_obj["门店"] = arr.series[index].name;
      data_obj["门店名"] = arr.series[index].storeName;
      data_obj["门店标签"] = arr.series[index].name;
      data_obj["品类"] = arr.series[index].name;
      data_obj["商品"] = arr.series[index].name;
      data_obj["商品名称"] = arr.series[index].name;
      data_obj["菜品"] = arr.series[index].name;
      data_obj["时间"] = arr.series[index].name;
      data_obj["订单类型"] = arr.series[index].sourceType;
      data_obj["分类"] = arr.series[index].categoryName

      for (let ind = 0; ind < arr.series[index].data.length; ind++) {
        if (arr.series[index].formatter == "{value}%") {
          data_obj[timeitemarr[ind]] = arr.series[index].data[ind]
          //  + "%";
        } else {
          data_obj[timeitemarr[ind]] = arr.series[index].data[ind];
        }
      }

      tableDatas.push(data_obj);
    }

  }


  return {
    tableDatas: tableDatas,
    tableHead: tableHead
  };
};


export const ready_table_package = (arr,meStr)=>{
  const {firstHeaderName} = arr
  let tableHead = [firstHeaderName[0],firstHeaderName[1],'非套餐(单点)','套餐','合计']
  let tableDatas = []
  // arr.xValues.map((val,ind)=>{
  //   let packageObj = {}
  //   arr.groups.map((item,index)=>{
  //     packageObj['时间'] = val
  //     packageObj['商品']=item.name
  //     // packageObj['时间']=item.name
  //     packageObj['非套餐(单点)']=item.series[0].data[index]
  //     packageObj['套餐']=item.series[1].data[index]
  //     packageObj['合计']=item.series[2].data[index]
  //     // console.log(item.series[2].data)
  //   })
  //   tableDatas.push(packageObj)

  // })

  arr.groups.map((item,index)=>{
    let ooo = [...new Set(arr.xValues)]
    ooo.map((val,ind)=>{
    let packageObj = {}
      packageObj['商品']=item.name
      packageObj['分类']=item.categoryName
      packageObj['时间']=val.split('00:00:00')[0]
      packageObj['非套餐(单点)']=item.series[0].data[ind] ?item.series[0].data[ind]:'0'
      packageObj['套餐']=item.series[1].data[ind] ? item.series[1].data[ind]:'0'
      packageObj['合计']=item.series[2].data[ind]

      tableDatas.push(packageObj)

    })
  })
  // let tableDatas =[]
  return {
    tableDatas,
    tableHead
  }
}


// 返回的(groups) 多门店格式转换
export const ready_table_leng = (arr, meStr) => {
  let tableDatas = [];
  let tableHead = [];

  if (arr.groups.length == 0) {
    return {
      tableDatas: [],
      tableHead: []
    };
  }

  for (let index = 0; index < arr.groups.length; index++) {
    let datalist = ready_table_two(
      arr.groups[index],
      arr.xValues,
      arr.groups[index].name,
      meStr,
      arr
    );

    tableDatas.push(datalist.tableDatas);
    tableHead.push(datalist.tableHead);
  }

  tableHead = [].concat.apply([], tableHead);

  return {
    tableDatas: [].concat.apply([], tableDatas),
    tableHead: Array.from(new Set([...tableHead]))
  };
};

// 返回的(groups) 多门店格式转换 竖排
export const ready_table_two = (arr, time, name, meStr, allarr) => {
  let tableDatas = [];
  let tableData = {};
  let {
    firstHeaderName
  } = allarr;
  let tableHead = firstHeaderName;

  if (arr.series.length == 0) return;

  arr.series.map(item => {
    tableHead.push(item.name);
  });

  for (let index = 0; index < tableHead.length; index++) {
    tableData[tableHead[index]] = "";
    tableData[`门店`] = "";
    tableData[`门店标签`] = "";
    tableData[`品类`] = "";
    tableData[`商品`] = "";
    tableData[`商品名称`] = "";
    tableData[`门店名称`] = "";
    tableData[`菜品`] = "";
    tableData[`分类`] = "";
    tableData[`门店名`] = "";
    tableData[`订单类型`] = "";
  }

  for (let index = 0; index < arr.series.length; index++) {
    if (index == 0) {
      for (let ind = 0; ind < arr.series[index].data.length; ind++) {
        let data_obj = {
          ...tableData
        };
        data_obj["时间"] = timer_arr(time[ind], meStr);

        if (arr.series[index].formatter == "{value}%") {
          data_obj[arr.series[index].name] = arr.series[index].data[ind]
          //+ "%";
        } else {
          data_obj[arr.series[index].name] = arr.series[index].data[ind];
        }

        data_obj[`门店`] = name;
        data_obj["门店标签"] = name;
        data_obj[`品类`] = name;
        data_obj[`商品`] = name;
        data_obj[`商品名称`] = name;
        data_obj[`门店名称`] = timer_arr(time[ind], meStr);
        data_obj[`菜品`] = name;
        data_obj[`分类`] = arr.categoryName;
        //如果有门店名则赋值：单品分析--综合报表没有此列
        if(arr.series[index].hasOwnProperty("storeName")){
          data_obj[`门店名`] = arr.series[index].storeName;
          data_obj[`订单类型`] = arr.series[index].sourceType;
        }else{
          data_obj[`门店名`] = "";
          data_obj[`订单类型`] = "";
        }
        tableDatas.push(data_obj);
      }
    } else {
      for (let ind = 0; ind < arr.series[index].data.length; ind++) {
        if (arr.series[index].formatter == "{value}%") {
          tableDatas[ind][arr.series[index].name] =
            arr.series[index].data[ind]
          //+ "%";
        } else {
          tableDatas[ind][arr.series[index].name] = arr.series[index].data[ind];
        }
      }
    }
  }

  return {
    tableDatas: tableDatas,
    tableHead: tableHead
  };
};

// 返回的(groups) 多门店格式转换 横排
export const ready_table_leng_netsales = (arr, meStr) => {
  let tableDatas = [];
  let tableHead = [];

  if (arr.groups.length == 0) return;

  for (let index = 0; index < arr.groups.length; index++) {
    let datalist = ready_table_two_netsales(
      arr.groups[index],
      arr.xValues,
      arr.groups[index].name,
      meStr
    );

    tableDatas.push(datalist.tableDatas);
    tableHead.push(datalist.tableHead);
  }

  tableHead = [].concat.apply([], tableHead);

  return {
    tableDatas: [].concat.apply([], tableDatas),
    tableHead: Array.from(new Set([...tableHead]))
  };
};

// 返回的(groups) 多门店格式转换 横排 处理逻辑
export const ready_table_two_netsales = (arr, time, name, meStr) => {
  let tableDatas = [];
  let tableData = {};

  let {
    firstHeaderName
  } = arr;
  let tableHead = firstHeaderName;

  arr.series.map(item => {
    tableHead.push(item.name);
  });

  for (let index = 0; index < tableHead.length; index++) {
    tableData[tableHead[index]] = "";
    tableData[`门店`] = "";
    tableData[`品类`] = "";
    tableData[`商品`] = "";
    tableData[`门店标签`] = "";
  }

  for (let index = 0; index < arr.series.length; index++) {
    if (index == 0) {
      for (let ind = 0; ind < arr.series[index].data.length; ind++) {
        let data_obj = {
          ...tableData
        };
        data_obj["时间"] = timer_arr(time[ind], meStr);

        if (arr.series[index].formatter == "{value}%") {
          data_obj[arr.series[index].name] = arr.series[index].data[ind]
          //+ "%";
        } else {
          data_obj[arr.series[index].name] = arr.series[index].data[ind];
        }

        data_obj[`门店`] = name;
        data_obj[`品类`] = name;
        data_obj[`商品`] = name;
        data_obj[`门店标签`] = name;
        //如果有门店名则赋值
        if(arr.series[index].hasOwnProperty("storeName")){
          data_obj[`门店名`] = arr.series[index].storeName;
        }else{
          data_obj[`门店名`] = "";
        }
        tableDatas.push(data_obj);
      }
    } else {
      for (let ind = 0; ind < arr.series[index].data.length; ind++) {
        if (arr.series[index].formatter == "{value}%") {
          tableDatas[ind][arr.series[index].name] =
            arr.series[index].data[ind]
          //+ "%";
        } else {
          tableDatas[ind][arr.series[index].name] = arr.series[index].data[ind];
        }
      }
    }
  }

  return {
    tableDatas: tableDatas,
    tableHead: tableHead
  };
};

//堆叠柱状图处理
export const reday_chart_stack = ({
  groups,
  series,
  xValues
}) => {
  if (groups != undefined) {
    let dataarr = [];

    for (let index = 0; index < groups.length; index++) {
      for (let ind = 0; ind < groups[index].series.length; ind++) {
        groups[index].series[ind].type = "bar";
        groups[index].series[ind].stack = "品类";

        dataarr.push(groups[index].series[ind]);
      }
    }

    return {
      series: dataarr,
      xValues: xValues
    };
  } else {
    let dataarr = [];

    for (let index = 0; index < series.length; index++) {
      series[index].type = "bar";
      series[index].stack = "品类";

      dataarr.push(series[index]);
    }

    return {
      series: dataarr,
      xValues: xValues
    };
  }
};