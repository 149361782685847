const posOrder = () => import(/* webpackChunkName: "pos" */ '@/pages/pos/order/index.vue');
const goodsManage = () => import(/* webpackChunkName: "pos" */ '@/pages/pos/goodsManage/index.vue');
const stepPricing = () => import(/* webpackChunkName:  "pos" */ '@/pages/pos/stepPricing/index.vue');
const stepPricingAdd = () => import(/* webpackChunkName:  "pos" */ '@/pages/pos/stepPricing/add.vue');

// 快银Pos
const PosAdvertise = () => import(/* webpackChunkName: "pos" */ '../../pages/pos/advertise.vue');

export default [
	{
		path: '/posOrder/order',
		name: 'posOrder',
		component: posOrder
	},
	{
		path: '/posOrder/goodsManage',
		name: 'goodsManage',
		component: goodsManage
	},
	{
		path: '/posOrder/stepPricing',
		name: 'stepPricing',
		component: stepPricing
	},
	{
		path: '/posOrder/stepPricing/add',
		name: 'stepPricingAdd',
		component: stepPricingAdd
	},
	{
		path: '/posOrder/stepPricing/add/:uid',
    name: 'stepPricingEdit',
     component: stepPricingAdd
	},
	{
		path: '/posOrder/advertise',
    name: 'PosAdvertise',
    component: PosAdvertise
	}
]
