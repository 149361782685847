import { render, staticRenderFns } from "./WDialog.vue?vue&type=template&id=6958739d&scoped=true&"
import script from "./WDialog.vue?vue&type=script&lang=js&"
export * from "./WDialog.vue?vue&type=script&lang=js&"
import style0 from "./WDialog.vue?vue&type=style&index=0&id=6958739d&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6958739d",
  null
  
)

export default component.exports