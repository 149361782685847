<template>
  <div class="down-load-modal">
    <div><a-spin :tip="tipWords" size="large" /></div>
  </div>
</template>
<script>
export default {
  props: {
    tipWords: {
      type: String,
      default: '数据下载中',
    },
  },
}
</script>

<style lang="scss" scoped>
.down-load-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // font-size: 12px;
  // color: #d3d3d5;
  // margin: 10px 0 15px;
  position: fixed;
  top: 0;
  width: 100wh;
  width: 100vw;
  // background: rgba(0, 0, 0, 0.15);
  height: 100vh;
  z-index: 99999999;
  top: 0;
}
</style>
