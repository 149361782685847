<template>
  <div style="padding: 30px 10px 0" class="">
    <el-table
      :data="tableData"
      border
      align="left"
      :show-summary="summary"
      :empty-text="tips"
      :span-method="objectSpanMethod"
      :cell-style="switchColor"
      :summary-method="getSummaries"
      :height="heights"
    >
      <el-table-column type="index" label="序号" fixed="left" v-if="serialNumber"></el-table-column>
      <el-table-column
        v-for="(item, index) in tableHead"
        :key="index"
        :prop="item"
        :label="item"
        sortable
        :width="widths_fun(item)"
        :fixed="index == 0 && fixeds"
      >
        <template slot-scope="scope">
          <div>
            <p v-if="whetherZero" style="margin: 4px 0; padding: 0">
              <span>{{ scope.row[item] }} </span>
            </p>

            <p v-else style="margin: 4px 0; padding: 0">
              <span >
                <!-- v-if="scope.row[item] != 0" -->
                {{ scope.row[tableHead[index]]}}
                <span v-if="unit && !Array.isArray(unit)">{{unit}}</span>
                <span v-else-if="unit && Array.isArray(unit) && unit.indexOf(item) === -1">{{unit[0]}}</span>
                <span v-if="isbfb">{{ leftflex(tableHead[index]) }}</span>
              </span>
            </p>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <!-- scope.row[item] != 0 && scope.row[item] != '0%' -->
    <!-- :sortable="sort(tableData[index][item])"   :fixed="leftflex(item)"   :sort-method="(a, b) => sortMethod(a, b, tableData[index][item], item)" -->

    <div style="text-align:right; margin: 20px 0;">
      <el-pagination
        :page-size="pagesize"
        layout="prev, pager, next"
        :total="totaldata"
        v-if="pages"
        @current-change="fun_page"
        :current-page="currentpage"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import checkBtn from "./checkBtn/index";

export default {
  props: {
    //是否显示序号
    serialNumber: {
      type: Boolean,
      default: true,
    },
    //是否 商品固定
    fixeds: {
      type: Boolean,
      default: false,
    },
    //表格的高度
    heights: {
      type: Number,
    },
    //数字和百分比切换
    isbfb: {
      type: Boolean,
      default: true,
    },
    //总体数据
    tableData: {
      type: Array,
      default: () => [],
    },
    //表头
    tableHead: {
      type: Array,
      default: () => [],
    },
    //对应表头的字段前缀
    tabitem: {
      type: String,
    },
    // 没数据提示文案
    tips: {
      type: String,
    },
    //颜色列表
    colorList: {
      type: Array,
      default: () => [],
    },
    //哪行有颜色  数据集合??????????????????????????
    rowclassname: {
      type: Object,
      default: () => {},
    },
    // 合并表格
    getSpanArr_type: {
      type: Boolean,
      default: false,
    },
    //是否设置宽度
    widths: {
      type: Boolean,
      default: false,
    },
    //表头宽度最大值
    widthsMax: {
      type: Number,
      default: 158,
    },
    //表头宽度最小值
    widthsMin: {
      type: Number,
      default: 95,
    },
    //分页
    pages: {
      type: Boolean,
      default: false,
    },
    //分页数据
    totaldata: {
      type: Number,
      default: 0,
    },
    //分页函数
    fun_page: {
      type: Function,
    },
    // 绑定当前页数   因为重新渲染可能使组件渲染 分页还原
    currentpage: {
      type: Number,
    },
    //设置条数大小
    pagesize: {
      type: Number,
      default: 20,
    },
    whetherZero: {
      type: Boolean,
      default: false,
    },
    totalRow: { //可传入['haha','heihei'] 表示 'haha','heihei' 列不计算,无值默认为true全部计算
      type: null,
      default: false,
    },
    unit: {
      type: null,
      default: false,
    },
    summary: {
      type: Boolean,
      default: true,
    }
  },
  data() {
    return {};
  },
  components: {
    checkBtn,
  },
  watch: {
    getSpanArr_type: {
      handler() {
        if (this.getSpanArr_type) {
          this.getSpanArr(this.tableData);
        }
      },
      immediate: true,
    },
    tableData: {
      handler(value) {
        if(!this.totalRow) return
        let totalArr = []
        let _tableHead = JSON.parse(JSON.stringify(this.tableHead)) || []
        if(Array.isArray(this.totalRow)){
          this.totalRow.forEach((item)=>{
            let headIndex = _tableHead.indexOf(item)
            headIndex !== -1 && _tableHead.splice(headIndex, 1)
          })
        }
        value.forEach((item, index)=>{
          _tableHead.forEach(head=>{
            totalArr[index] = (totalArr[index] || 0) + item[head]
          })
          item["合计"] = Math.round(totalArr[index]*100)/100
        })
        this.tableHead[this.tableHead.length - 1] == "合计" ? '' : this.tableHead.push("合计")
      },
      immediate: true,
    },
  },
  methods: {
    getSummaries(param) {
      if (
        this.rowclassname != undefined &&
        this.rowclassname.totalAll != undefined
      ) {
        const { columns, data } = param;
        const sums = [];

        columns.forEach((column, index) => {
          if (index === 0) {
            if (this.rowclassname.totalAll[0] != "") {
              sums[index] = this.rowclassname.totalAll[0];
            } else {
              sums[index] = "合计";
            }
            return;
          } else {
            sums[index] = this.rowclassname.totalAll[index];
          }
        });

        return sums;
      } else {
        const { columns, data } = param;
        const sums = [];
        columns.forEach((column, index) => {
          if (index === 0) {
            sums[index] = "合计";
            return;
          }
          const values = data.map((item) => Number(item[column.property]));
          if (!values.every((value) => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr);
              if (!isNaN(value)) {
                let nums = prev + curr;
                return Math.floor(nums * 100) / 100;
              } else {
                return prev;
              }
            }, 0);
            sums[index];
          } else {
            sums[index];
          }
        });

        return sums;
      }
    },
    widths_fun(item) {
      if (this.widths) {
        if (item == "商品名称" || item == "商品" || item == "品类"||item == "非套餐(单点)") {
          return this.widthsMax;
        } else {
          return this.widthsMin;
        }
      }
    },
    leftflex(arr) {
      switch (arr) {
        case "0时":
          return "%";
          break;
        case "1时":
          return "%";
          break;
        case "2时":
          return "%";
          break;
        case "3时":
          return "%";
          break;
        case "4时":
          return "%";
          break;
        case "5时":
          return "%";
          break;
        case "6时":
          return "%";
          break;
        case "7时":
          return "%";
          break;
        case "8时":
          return "%";
          break;
        case "9时":
          return "%";
          break;
        case "10时":
          return "%";
          break;
        case "11时":
          return "%";
          break;
        case "12时":
          return "%";
          break;
        case "13时":
          return "%";
          break;
        case "14时":
          return "%";
          break;
        case "15时":
          return "%";
          break;
        case "16时":
          return "%";
          break;
        case "17时":
          return "%";
          break;
        case "18时":
          return "%";
          break;
        case "19时":
          return "%";
          break;
        case "20时":
          return "%";
          break;
        case "21时":
          return "%";
          break;
        case "22时":
          return "%";
          break;
        case "23时":
          return "%";
          break;
        case "同比增长率":
          return "%";
          break;
        case "销售额同比增长率":
          return "%";
          break;
        case "销售数量占比":
          return "%";
          break;
        case "销售数量占比":
          return "%";
          break;

        case "累计销量占比":
          return "%";
          break;

        case "销量占比":
          return "%";
          break;

        case "占比":
          return "%";
          break;

        case "新客":
          return "%";
          break;

        case "老客":
          return "%";
          break;
        case "会员":
          return "%";
          break;
        case "非会员":
          return "%";
          break;

        case "首单新客":
          return "%";
          break;
        case "历史下单2次客户":
          return "%";
          break;
        case "历史下单3次客户":
          return "%";
          break;
        case "历史下单4次客户":
          return "%";
          break;
        case "历史下单5次及以上客户":
          return "%";
          break;
        case "全部人群复购率":
          return "%";
          break;

        case "全量会员(每日更新)复购率":
          return "%";
          break;

        case "首单用户(每日更新)复购率":
          return "%";
          break;

        case "成长用户(每日更新)复购率":
          return "%";
          break;

        case "活跃用户(每日更新)复购率":
          return "%";
          break;

        case "沉睡用户(每日更新)复购率":
          return "%";
          break;

        case "流失用户(每日更新)复购率":
          return "%";
          break;

        case "券维度核销率":
          return "%";
          break;

        case "订单维度核销率":
          return "%";
          break;

        case "用户维度核销率":
          return "%";
          break;

        case "累计核销率":
          return "%";
          break;

        case "未用券用户复购率":
          return "%";
          break;

        case "用券用户复购率":
          return "%";
          break;

        case "ROI":
          return "%";
          break;

        case "差评率":
          return "%";
          break;

        case "全部人群复购订单占比":
          return "%";
          break;

        case "首单用户(每日更新)复购订单占比":
          return "%";
          break;

        case "沉睡用户(每日更新)复购订单占比":
          return "%";
          break;

        case "成长用户(每日更新)复购订单占比":
          return "%";
          break;

        case "全量会员(每日更新)复购订单占比":
          return "%";
          break;

        case "活跃用户(每日更新)复购订单占比":
          return "%";
          break;

        case "流失用户(每日更新)复购订单占比":
          return "%";
          break;

        case "订单核销率":
          return "%";
          break;

        case "客户核销率":
          return "%";
          break;

        default:
          break;
      }
    },
    // sortMethod(data1, data2, arr, type) {
    //   if (arr != undefined) {
    //     if (!isNaN(arr)) {
    //       if (data1[type] > data2[type]) {
    //         return -1;
    //       } else {
    //         return 1;
    //       }
    //     }
    //   }
    // },
    sort(arr) {
      if (arr != undefined) {
        if (!isNaN(arr)) {
          return true;
        } else {
          return false;
        }
      }
    },
    range(arr, size) {
      let abarr = Math.abs(arr);

      switch (true) {
        case abarr >= 0 && abarr <= size[0][1]:
          return {
            background: "#3AB559",
          };
          break;
        case abarr >= size[1][0] && abarr <= size[1][1]:
          return {
            background: "#FFDB5C",
          };
          break;
        case abarr >= size[2][0] && abarr <= size[2][1]:
          return {
            background: "#FFA500",
          };
          break;

        case abarr >= size[3][0] && abarr <= size[3][1]:
          return {
            background: "#EF680C",
          };

          break;
        case abarr >= size[4][0] && abarr <= size[4][1]:
          return {
            background: "#F85050",
          };
          break;

        default:
          break;
      }
    },
    switchColor({ row, column, rowIndex, columnIndex }) {
      if (
        this.rowclassname != undefined &&
        this.rowclassname.colorLevelColumns != undefined
      ) {
        let _this = this;
        for (
          let index = 0;
          index < this.rowclassname.colorLevelColumns.length;
          index++
        ) {
          if (column.label == this.rowclassname.colorLevelColumns[index].head) {
            return _this.range(
              parseFloat(row[this.rowclassname.colorLevelColumns[index].head]),
              this.rowclassname.colorLevelColumns[index].colorLevelColumnsSize
            );
          }
        }
      } else {
        return;
      }
    },
    // 合并单元格
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      if (this.getSpanArr_type) {
        if (columnIndex === 1) {
          // 二维数组存储的数据 取出
          const _row = this.spanArr[rowIndex];
          const _col = _row > 0 ? 1 : 0;
          return {
            rowspan: _row,
            colspan: _col,
          };
          //不可以return {rowspan：0， colspan: 0} 会造成数据不渲染， 也可以不写else，eslint过不了的话就返回false
        } else {
          return false;
        }
      }
    },
    //表格单元格合并
    getSpanArr(data) {
      let that = this;
      //页面展示的数据，不一定是全部的数据，所以每次都清空之前存储的 保证遍历的数据是最新的数据。以免造成数据渲染混乱
      that.spanArr = [];
      that.pos = 0;
      //遍历数据
      data.forEach((item, index) => {
        //判断是否是第一项
        if (index === 0) {
          this.spanArr.push(1);
          this.pos = 0;
        } else {
          //不是第一项时，就根据标识去存储

          if (data[index]["门店"] === data[index - 1]["门店"]) {
            // 查找到符合条件的数据时每次要把之前存储的数据+1
            this.spanArr[this.pos] += 1;
            this.spanArr.push(0);
          } else {
            // 没有符合的数据时，要记住当前的index
            this.spanArr.push(1);
            this.pos = index;
          }
        }
      });
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped></style>
