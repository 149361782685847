const state = {
    orderStatusList: [],
    payStatusList: [],
    businessTypeList: [],
    orderTypeList: [],
    payTypeList: [],
    coreIndicatorList: [],
    newlist: [],
    redirectUrl: ''
};
const mutations = {
    // 核心指标
    SET_CORE_INDICATOR_LIST: (state, list) => {
        state.coreIndicatorList = list
    },
    // 订单状态
    SET_ORDER_STATUS_LIST: (state, list) => {
        state.orderStatusList = list
    },
    // 支付状态
    SET_PAY_STATUS_LIST: (state, list) => {
        state.payStatusList = list
    },
    // 业务类型
    SET_BUSINESS_TYPE_LIST: (state, list) => {
        state.businessTypeList = list
    },
    // 订单类型
    SET_ORDER_TYPE_LIST: (state, list) => {
        state.orderTypeList = list
    },
    // 支付类型
    SET_PAY_TYPE_LIST: (state, list) => {
        state.payTypeList = list
    },
    NEW_LIST: (state, list) => {
        state.newlist = list
    },
    REDIRECTURL: (state, list) => {
        state.redirectUrl = list
    },
}
const actions = {
    setCoreIndicatorList({commit}, list) {
        commit('SET_CORE_INDICATOR_LIST',list)
    },
    setOrderStatusList({commit}, list) {
        commit('SET_ORDER_STATUS_LIST',list)
    },
    setPayStatusList({commit}, list) {
        commit('SET_PAY_STATUS_LIST', list)
    },
    setBusinessTypeList({commit}, list) {
        commit('SET_BUSINESS_TYPE_LIST', list)
    },
    setOrderTypeList({commit}, list) {
        commit('SET_ORDER_TYPE_LIST', list)
    },
    setPayTypeList({commit}, list) {
        commit('SET_PAY_TYPE_LIST', list)
    },
    clear({commit}) {
        commit('SET_ORDER_STATUS_LIST', []);
        commit('SET_PAY_STATUS_LIST', []);
        commit('SET_BUSINESS_TYPE_LIST', []);
        commit('SET_ORDER_TYPE_LIST', []);
        commit('SET_PAY_TYPE_LIST', [])
        commit('SET_CORE_INDICATOR_LIST', [])
    },
}
export default {
    state,
    mutations,
    actions
}
