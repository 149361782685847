<template>
    <el-popover
        ref="filterPopover"
        placement="bottom"
        trigger="hover"
        popper-class="table-filter-popper"
        @show="filterVisible = true"
        @hide="filterVisible = false"
    >
        <ul class="table-filter-container">
            <li
                v-for="item in filters"
                :key="item.value"
                class="el-select-dropdown__item"
                :class="{ selected: item.active }"
                @click="handleFilterClick(item)"
            >
                {{ item.label }}
            </li>
        </ul>
        <span
            slot="reference"
            :class="{ 'table-header-cell hightlight': currentActiveFilter }"
        >
            {{ label }}
            <i
                :class="
                    filterVisible ? 'el-icon-arrow-up' : 'el-icon-arrow-down'
                "
            />
        </span>
    </el-popover>
</template>

<script>
export default {
    props: {
        label: {
            type: String,
            default: () => '',
        },
        filters: {
            type: Array,
            default: () => [],
        },
    },
    computed: {
        currentActiveFilter() {
            const oActiveFilter = this.filters.find(
                (oFilter) => oFilter.active,
            );
            return (
                oActiveFilter &&
                oActiveFilter.value !== '' &&
                oActiveFilter.value !== undefined
            );
        },
    },
    data() {
        return {
            filterVisible: false,
        };
    },
    methods: {
        handleFilterClick(oData) {
            this.$refs.filterPopover.doClose();
            this.$emit('filter', oData);
        },
    },
};
</script>

<style lang="scss" scoped>
.table-header-cell.hightlight {
    color: #20a0ff;
}
</style>
