/*
 * 商品智能--路由配置
 */
//动态促销
//促销策略列表页
const dynamicPromotion = () =>
  import("@/pages/commodityIntelligence/dynamicPromotion/strategyList/index");
//促销策略编辑页
const dynamicPromotionEdit = () =>
  import("@/pages/commodityIntelligence/dynamicPromotion/strategyList/detail");
//促销看板列表页
const dynamicPromotionBoard = () =>
  import("@/pages/commodityIntelligence/dynamicPromotion/board/index");
//促销看板详情页
const dynamicPromotionBoardDetail = () =>
  import("@/pages/commodityIntelligence/dynamicPromotion/board/detail");

//商品巡检策略列表页
const inspectStrategyList = () =>
  import("@/pages/commodityIntelligence/inspect/strategyList");
//商品巡检策略详情页
const inspectStrategyEdit = () =>
  import("@/pages/commodityIntelligence/inspect/strategyEdit");
const inspectStrategyEditNOId = () =>
  import("@/pages/commodityIntelligence/inspect/strategyEdit");
//商品巡检任务单列表页
const inspectStrategyTaskList = () =>
  import("@/pages/commodityIntelligence/inspect/taskList");
//商品巡检任务详情页
const inspectStrategyTaskEdit = () =>
  import("@/pages/commodityIntelligence/inspect/taskEdit");
//商品巡检品效配置
const inspectProductConfig = () =>
  import("@/pages/commodityIntelligence/inspect/productConfig");
//商品巡检数据报表列表页
const inspectStoreReportList = () =>
  import("@/pages/commodityIntelligence/inspect/storeReportList");
//52周MD--促销计划管理--列表页
const salesPlanList52 = () =>
  import("@/pages/commodityIntelligence/52md/salesPlan/list");
//52周MD--促销计划管理--详情页
const salesPlanEdit52 = () =>
  import("@/pages/commodityIntelligence/52md/salesPlan/edit");
//52周MD--促销计划管理--审核页
const salesPlanApprove52 = () =>
  import("@/pages/commodityIntelligence/52md/salesPlan/approve");
//52周MD--主题营销计划管理
const pmtThemeManage52 = () =>
  import("@/pages/commodityIntelligence/52md/salesPlan/pmtTheme");
//52周MD--商品管理--列表页
const goodsManageList52 = () =>
  import("@/pages/commodityIntelligence/52md/salesPlan/goodsManage");
//52周MD--采购预估量管理--列表页
const predictPurcList52 = () =>
  import("@/pages/commodityIntelligence/52md/predictPurchase/list");
//52周MD--采购预估量管理--详情页
const predictPurcEdit52 = () =>
  import("@/pages/commodityIntelligence/52md/predictPurchase/edit");

export default [
  //促销策略列表页
  {
    path: "/dynamicPromotion/list",
    name: "dynamicPromotion",
    component: dynamicPromotion,
  },
  //促销策略编辑页
  {
    path: "/dynamicPromotion/edit",
    name: "dynamicPromotionEdit",
    component: dynamicPromotionEdit,
  },
  //促销看板列表页
  {
    path: "/dynamicPromotion/board/list",
    name: "dynamicPromotionBoard",
    component: dynamicPromotionBoard,
  },
  //促销看板详情页
  {
    path: "/dynamicPromotion/board/edit",
    name: "dynamicPromotionBoardDetail",
    component: dynamicPromotionBoardDetail,
  },
  //商品巡检策略列表页
  {
    path: "/inspectStrategyList",
    name: "inspectStrategyList",
    component: inspectStrategyList,
  },
  //商品巡检策略详情页带id
  {
    path: "/inspectStrategyEdit/:id",
    name: "inspectStrategyEdit",
    component: inspectStrategyEdit,
  },
  //商品巡检策略详情页无id
  {
    path: "/inspectStrategyEditNoId",
    name: "inspectStrategyEditNoId",
    component: inspectStrategyEditNOId,
  },
  //商品巡检策略任务单列表页
  {
    path: "/inspectStrategyTaskList",
    name: "inspectStrategyTaskList",
    component: inspectStrategyTaskList,
  },
  //商品巡检策略任务单详情页
  {
    path: "/inspectStrategyTaskEdit/:id",
    name: "inspectStrategyTaskEdit",
    component: inspectStrategyTaskEdit,
  },
  //商品巡检品效配置
  {
    path: "/inspectProductConfig",
    name: "inspectProductConfig",
    component: inspectProductConfig,
  },
  //商品巡检数据报表列表
  {
    path: "/inspectStoreReportList",
    name: "/inspectStoreReportList",
    component: inspectStoreReportList,
  },
  //52周MD--促销计划管理--列表页
  {
    path: "/salesPlanList",
    name: "salesPlanList",
    component: salesPlanList52,
  },
  //52周MD--促销计划管理--详情页
  {
    path: "/salesPlanEdit",
    name: "salesPlanEdit",
    component: salesPlanEdit52,
  },
  //52周MD--促销计划管理--审批页
  {
    path: "/salesPlanApprove",
    name: "salesPlanApprove",
    component: salesPlanApprove52,
  },
  //52周MD--主题营销计划管理
  {
    path: "/pmtThemeManage",
    name: "pmtThemeManage",
    component: pmtThemeManage52,
  },
  //52周MD--商品管理--列表页
  {
    path: "/pmtGoodsManageList",
    name: "pmtGoodsManageList",
    component: goodsManageList52,
  },
  //52周MD--采购预估量管理--列表页
  {
    path: "/predictPurcList",
    name: "predictPurcList",
    component: predictPurcList52,
  },
  //52周MD--采购预估量管理--编辑页
  {
    path: "/predictPurcEdit",
    name: "predictPurcEdit",
    component: predictPurcEdit52,
  },
];
