// httpFilter
export const current_env = process.env.NODE_ENV == 'development' ? 'development' : 'server' // server | development

export const httpFilter = (params, realQueryFn, mockFn) => {
  if (current_env === 'development') {
    return new Promise((resolve, reject) => {
      resolve(mockFn())
    })
  } else {
    return realQueryFn(params)
  }
}
