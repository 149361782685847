<template>
  <common-echarts-line
    v-if="chartData"
    id="chart"
    :chartData="chartData"
  ></common-echarts-line>
</template>

<script>
import commonEchartsLine from "./commonEchartsLine";

export default {
  props: {
    value: {
      type: Object
    }
  },
  watch: {
    value(val) {
      this.updateChartData();
    }
  },
  data() {
    return {
      chartData: null
    };
  },
  components: {
    commonEchartsLine
  },
  methods: {
    updateChartData() {
      if (!this.value) {
        this.chartData = null;
        return;
      }
      const { xAxis, yAxis, lines } = this.value;
      if (!Array.isArray(xAxis)) {
        xAxis = [xAxis];
      }
      if (!Array.isArray(yAxis)) {
        yAxis = [yAxis];
      }

      this.chartData = {
        color: [
          "#1969F1",
          "#E9785D",
          "#2695FF",
          "#FF9F7F",
          "#89E4E7",
          "#EA0057",
          "#32C5E9",
          "#FB7193",
          "#1969F1",
          "#E7BDF3",
          "#96BFFF",
          "#7D71CF",
          "#FFDB5C",
          "#3AB559",
          "#988FD8",
          "#82DA1F",
          "#EF680C",
          "#F85050",
          "#E062AE",
          "#FFA500",
          "#9D96F5",
          "#9EE6B7"
        ],
        grid: {
          left: "15",
          top: "100",
          right: "15",
          bottom: "15",
          containLabel: true
        },
        legend: {
          padding: 50,
          icon: "circle",
          right: "0",
          data: lines.map(item => item.name)
        },
        tooltip: {
          type: "cross",
          backgroundColor: "rgba(255,255,255,1)",
          borderColor: "rgba(3,120,212,.4)",
          borderWidth: 1,
          padding: 20,
          trigger: "axis",
          textStyle: {
            fontFamily: "Verdana, sans-serif",
            fontSize: 14,
            color: "#333333",
            fontWeight: 400
          },
          formatter(params) {
            let htmlStr = "";
            if (params.length > 0) {
              htmlStr += params[0].axisValue + "</br>";
            }
            for (let i = 0; i < params.length; i++) {
              let param = params[i];
              let xName = param.axisValue; //x轴的名称
              let seriesName = param.seriesName; //图例名称
              let value = FORMATNUM(param.value); //y轴值
              let color = param.color; //图例颜色
              // 格式化值
              const formatter = lines[param.seriesIndex].formatter || "{value}";
              if (typeof formatter == "string") {
                value = formatter.replace("{value}", value);
              } else if (typeof formatter == "function") {
                value = formatter(value);
              }

              htmlStr += "<div style='display: flex; align-items: center'>";
              htmlStr += param.marker;
              //圆点后面显示的文本
              htmlStr += seriesName + "：" + "<span style='flex: 1; text-align: right'>" + value + "</span>";
              htmlStr += "</div>";
            }
            return htmlStr;
          }
        },
        xAxis: xAxis.map((item, index) => {
          return {
            type: "category",
            axisLabel: {
              textStyle: {
                color: "#B6B6B6"
              },
              formatter: item.formatter
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: "#E8E8E8"
              }
            },
            axisTick: {
              show: false
            },
            //网格样式
            splitLine: {
              show: false,
              lineStyle: {
                color: "#E8E8E8",
                width: 1,
                type: "solid"
              }
            },
            data: item.data
          };
        }),
        yAxis: yAxis.map((item, index) => {
          return {
            type: "value",
            axisLabel: {
              textStyle: {
                color: "#B6B6B6"
              },
              formatter: item.formatter
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: "#E8E8E8"
              }
            },
            axisTick: {
              show: false
            },
            //网格样式
            splitLine: {
              show: item.hasOwnProperty("showSplitLine")
                ? item.showSplitLine
                : true,
              lineStyle: {
                color: "#E8E8E8",
                width: 1,
                type: "solid"
              }
            },
            min: item.min,
            max: item.max
          };
        }),

        series: lines.map(item => {
          return {
            data: [],
            type: "line",
            name: item.name,
            smooth: true,
            data: item.data,
            yAxisIndex: item.yAxisIndex || 0,
            test: 123
          };
        })
      };
    }
  },
  mounted() {
    this.updateChartData();
  }
};
</script>

<style lang="scss" scoped></style>
