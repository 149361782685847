export default {
  path: "allCustomers",
  meta: { title: "全量顾客" },
  component: () => import("@/pages/allCustomers/index.vue"),
  children: [
    {
      name: "list",
      path: "list",
      meta: { title: "顾客档案" },
      component: () => import("@/pages/allCustomers/list.vue")
    },
    {
      name: "detail",
      path: "detail/:id",
      meta: { title: "顾客档案详情" },
      component: () => import("@/pages/allCustomers/detail.vue")
    }
  ]
};
