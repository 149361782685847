<template>
  <div>
    <ul class="tablist" v-if="titles">
      <li class="tablistItem">{{ titles }}</li>
    </ul>
    <!-- <ul>
      <li></li>
    </ul> -->
    <common-echarts-line
      v-if="chartData"
      :id="id"
      :chartData="chartData"
      :clearType="cleartype"
      :heights="heightS"
    ></common-echarts-line>
  </div>
</template>

<script>
import commonEchartsLine from "./commonEchartsLine";
import moment from "moment";

export default {
  props: {
    titles: {
      type: String
    },
    value: {
      type: Object
    },
    id: {
      type: String
    },
    meStr: {
      type: String
    },
    total: { //是否需要计算合计
      type: Boolean,
      default: false
    },
    //判断是否使用循环 y轴       //true 单挑 y轴   false 多条轴
    y_two: {
      type: Boolean,
      default: true
    },
    // 是否需要转换时间格式
    isTimeTransfer: {
      type: Boolean,
      default: true
    }
  },
  watch: {
    value(val) {
      this.updateChartData();
    }
  },
  data() {
    return {
      chartData: null,
      cleartype: false,
      heightS: "400px"
    };
  },
  components: {
    commonEchartsLine
  },
  methods: {
    timer_arr(arr) {
      if(!this.isTimeTransfer) {
        return arr
      }
      return arr.map(item => {
        if (isNaN(item) && !isNaN(Date.parse(item))) {
          let time = "";

          if (this.meStr === "day") {
            let htmlStr = "";

            if (moment(new Date(item)).day() !== 0) {
              switch (moment(new Date(item)).day()) {
                case 1:
                  htmlStr = "(周一)";
                  break;
                case 2:
                  htmlStr = "(周二)";
                  break;
                case 3:
                  htmlStr = "(周三)";
                  break;
                case 4:
                  htmlStr = "(周四)";
                  break;
                case 5:
                  htmlStr = "(周五)";
                  break;
                case 6:
                  htmlStr = "(周六)";
                  break;

                default:
                  break;
              }
            } else {
              htmlStr = "(周日)";
            }
            time = moment(item).format("YYYY/MM/DD") + htmlStr;
          } else if (this.meStr === "week") {
            let end = new Date(item);
            end.setTime(end.getTime() + 3600 * 1000 * 24 * 6);
            time =
              moment(item).format("YYYY") +
              "/第" +
              moment(item).week() +
              "周" +
              "(" +
              moment(item).format("MM/DD") +
              "-" +
              moment(end).format("MM/DD") +
              ")";
          } else if (this.meStr === "month") {
            time = moment(item).format("YYYY/MM");
          } else if (this.meStr === "hour") {
            time = moment(item).format("YYYY/MM/DD HH:mm");
          } else if (this.meStr === "quarter") {
            time =
              moment(item).format("YYYY") +
              "/第" +
              moment(item).quarter() +
              "季";
          } else if (this.meStr === "year") {
            time = moment(item).format("YYYY");
          } else {
            time = item;
          }

          return time;
        } else {
          return item;
        }
      });
    },
    //计算合计值
    calculatetotal(){
      if(!this.value.series){
        return this.value
      }
      let renderVal = JSON.parse(JSON.stringify(this.value)) 
      let totalArr = []
      this.value.series.forEach((item)=>{
        for(let i = 0; i< item.data.length; i++){
          totalArr[i] = (totalArr[i] || 0) + item.data[i]
        }
      })
      //保留两位小数
      totalArr = totalArr.map(item=>{
        return Math.round(item*100)/100
      })
      let total = {
        data: totalArr,
        formatter: this.value.series[0].formatter,
        name: '合计',
        type: this.value.series[0].type
      }
      renderVal.series.push(total)
      return renderVal
    },
    
    updateChartData() {
      let colordata = [
        "#1969F1",
        "#E9785D",
        "#2695FF",
        "#FF9F7F",
        "#89E4E7",
        "#EA0057",
        "#32C5E9",
        "#FB7193",
        "#1969F1",
        "#E7BDF3",
        "#96BFFF",
        "#7D71CF",
        "#FFDB5C",
        "#3AB559",
        "#988FD8",
        "#82DA1F",
        "#EF680C",
        "#F85050",
        "#E062AE",
        "#FFA500",
        "#9D96F5",
        "#9EE6B7"
      ];
      if (!this.value) {
        this.chartData = null;
        return;
      }
      let renderVal = this.total ? this.calculatetotal() : this.value
      const { series, xValues, groups, xtype, selected } = renderVal;

      /*****************************************添加 图表返回空数组 清除图表**************************************************************/

      if (series != undefined && series.length == 0) {
        this.cleartype = true;
      } else {
        this.cleartype = false;
      }

      /**************************************************************/

      if (series != undefined && series.length > 0) {
        let _this = this;

        let y_arr = [];
        let indexofarr = [];

        if (this.y_two == false) {
          series.map((item, index) => {
            if (indexofarr.indexOf(item.yAxisIndex) == -1) {
              indexofarr.push(item.yAxisIndex);
              let indexposition = "";
              let offsetposition = "";
              if (item.yAxisIndex % 2 == 0) {
                indexposition = "left";
              } else {
                indexposition = "right";
              }

              // switch (index) {
              //   case 0:
              //     offsetposition = 0;
              //     break;
              //   case 1:
              //     offsetposition = 0;
              //     break;
              //   case 2:
              //     offsetposition = 40;
              //     break;
              //   case 3:
              //     offsetposition = 40;
              //     break;
              //   case 4:
              //     offsetposition = 120;
              //     break;
              //   case 5:
              //     offsetposition = 120;
              //     break;

              //   default:
              //     break;
              // }

              y_arr.push({
                type: "value",
                onZeroAxisIndex: item.yAxisIndex,
                position: indexposition,
                offset:
                  item.yAxisIndex == 0 || item.yAxisIndex == 1
                    ? 0
                    : item.yAxisIndex == 2
                    ? item.yAxisIndex * 34
                    : item.yAxisIndex * 22,
                axisLabel: {
                  textStyle: {
                    color: "#B6B6B6"
                  },
                  formatter: item.formatter
                },
                axisLine: {
                  show: true,
                  lineStyle: {
                    color: "#E8E8E8"
                  }
                },
                axisTick: {
                  show: false
                },
                //网格样式
                splitLine: {
                  show: true,
                  // show: index == 0 ? true : false,
                  lineStyle: {
                    color: "#E8E8E8",
                    width: 1,
                    type: "solid"
                  }
                }
                // min: Math.min.apply(null, item.data),
                // max: Math.max.apply(null, item.data)
              });
            }
          });
        }

        let legend = series.map(item => {
          if (item.labelName != undefined) {
            return item.labelName + item.name;
          } else {
            return item.name;
          }
        });

        let legendtop = 100;

        switch (true) {
          case legend.length <= 10:
            legendtop = 100;
            this.heightS = "484px";
            break;
          case legend.length > 10:
            legendtop = 260;
            this.heightS = "600px";
            break;

          default:
            legendtop = 100;
            this.heightS = "484px";
            break;
        }

        this.chartData = {
          color: [
            "#E9785D",
            "#1969F1",
            "#2695FF",
            "#FF9F7F",
            "#89E4E7",
            "#EA0057",
            "#32C5E9",
            "#FB7193",
            "#1969F1",
            "#E7BDF3",
            "#96BFFF",
            "#7D71CF",
            "#FFDB5C",
            "#3AB559",
            "#988FD8",
            "#82DA1F",
            "#EF680C",
            "#F85050",
            "#E062AE",
            "#FFA500",
            "#9D96F5",
            "#9EE6B7"
          ],
          grid: {
            left: "15",
            top: legendtop,
            right: "15",
            bottom: "15",
            containLabel: true
          },
          legend: {
            padding: [0, 50, 0, 50],
            icon: "circle",
            right: "0",
            top: "10",
            data: legend,
            selected: selected == undefined ? {} : selected
          },
          tooltip: {
            type: "cross",
            backgroundColor: "rgba(255,255,255,1)",
            borderColor: "rgba(3,120,212,.4)",
            borderWidth: 1,
            padding: 20,
            trigger: "axis",
            textStyle: {
              fontFamily: "Verdana, sans-serif",
              fontSize: 14,
              color: "#333333",
              fontWeight: 400
            },
            formatter(params) {
              let htmlStr = "";
              if (params.length > 0) {
                htmlStr += params[0].axisValue + "</br>";
              }
              for (let i = 0; i < params.length; i++) {
                let param = params[i];
                let xName = param.axisValue; //x轴的名称
                let seriesName = param.seriesName; //图例名称
                let value = FORMATNUM(param.value); //y轴值
                let color = param.color; //图例颜色
                // 格式化值
                const formatter =
                  series[param.seriesIndex].formatter || "{value}";
                if (typeof formatter == "string") {
                  value = formatter.replace("{value}", value);
                } else if (typeof formatter == "function") {
                  value = formatter(value, params);
                }

                htmlStr += "<div style='display: flex; align-items: center'>";
                htmlStr += param.marker;
                //圆点后面显示的文本
                htmlStr += seriesName + "：" + "<span style='flex: 1; text-align: right'>" + value + "</span>";
                htmlStr += "</div>";
              }
              return htmlStr;
            }
          },
          xAxis: xtype
            ? {
                type: "category",
                data: _this.timer_arr(xValues),
                position: "bottom",
                axisLabel: {
                  interval: 0,
                  rotate: 70,
                  show: true,
                  splitNumber: 15,
                  textStyle: {
                    fontSize: 12,
                    color: "#B6B6B6"
                  }
                },
                axisLine: {
                  show: true,
                  lineStyle: {
                    color: "#E8E8E8"
                  }
                },
                axisTick: {
                  show: false
                }
              }
            : {
                type: "category",
                data: _this.timer_arr(xValues),
                axisLabel: {
                  textStyle: {
                    fontSize: 12,
                    color: "#B6B6B6"
                  }
                },
                axisLine: {
                  show: true,
                  lineStyle: {
                    color: "#E8E8E8"
                  }
                },
                axisTick: {
                  show: false
                }
              },

          yAxis: _this.y_two
            ? [
                {
                  type: "value",
                  axisLabel: {
                    textStyle: {
                      color: "#B6B6B6"
                    },
                    formatter: series[0].formatter
                  },
                  axisLine: {
                    show: true,
                    lineStyle: {
                      color: "#E8E8E8"
                    }
                  },
                  axisTick: {
                    show: false
                  },
                  //网格样式
                  splitLine: {
                    show: true,
                    lineStyle: {
                      color: "#E8E8E8",
                      width: 1,
                      type: "solid"
                    }
                  }
                },
                {
                  type: "value",
                  axisLabel: {
                    textStyle: {
                      color: "#B6B6B6"
                    },
                    formatter: "{value}%"
                  },
                  axisLine: {
                    show: true,
                    lineStyle: {
                      color: "#E8E8E8"
                    }
                  },
                  axisTick: {
                    show: false
                  },
                  //网格样式
                  splitLine: {
                    show: true,
                    lineStyle: {
                      color: "#E8E8E8",
                      width: 1,
                      type: "solid"
                    }
                  }
                }
              ]
            : y_arr,
          series: series.map((item, index) => {
            if (item.labelName != undefined) {
              return {
                type: item.type,
                name: item.labelName + item.name,
                smooth: true,
                stack: item.stack,
                data: item.data,
                yAxisIndex: item.yAxisIndex || 0,
                test: 123,

                barGap: "0%",
                barCategoryGap: "30%"
              };
            } else {
              return {
                type: item.type,
                name: item.name,
                stack: item.stack,
                smooth: true,
                label: item.label,
                data: item.data,
                yAxisIndex: item.yAxisIndex || 0,
                test: 123,

                barGap: "0%",
                barCategoryGap: "30%"
              };
            }
          })
        };
      }

      if (groups != undefined && groups.length > 0) {
        let _this = this;
        let xlegend = groups.map(item => item.name);
        let leftseries = groups[0].series;
        let rightseries = [];

        groups.map((item, index) => {
          if (index != 0) {
            rightseries.push(item.series);
          }
        });
        let allseries = groups.map(item => item.series);
        allseries = [].concat.apply([], allseries);
        rightseries = [].concat.apply([], rightseries);

        //合并数据
        let newseries = [];
        let objarr = {};

        for (var i = 0; i < leftseries.length; i++) {
          for (let index = 0; index < rightseries.length; index++) {
            if (leftseries[i].name == rightseries[index].name) {
              let arrobj = leftseries[i];
              let datas = rightseries[index].data;
              arrobj.data = leftseries[i].data.concat(datas);
            }
          }
        }

        // 对应门店  需要增加叠加的 x轴
        let xtimes = _this.timer_arr(xValues);

        this.chartData = {
          color: [
            "#1969F1",
            "#E9785D",
            "#2695FF",
            "#FF9F7F",
            "#89E4E7",
            "#EA0057",
            "#32C5E9",
            "#FB7193",
            "#1969F1",
            "#E7BDF3",
            "#96BFFF",
            "#7D71CF",
            "#FFDB5C",
            "#3AB559",
            "#988FD8",
            "#82DA1F",
            "#EF680C",
            "#F85050",
            "#E062AE",
            "#FFA500",
            "#9D96F5",
            "#9EE6B7"
          ],
          grid: {
            left: "15",
            top: "100",
            right: "15",
            bottom: "15",
            containLabel: true
          },
          legend: {
            padding: [0, 50, 0, 50],
            icon: "roundRect",
            right: "0",
            data: allseries.map(item => item.name)
          },
          tooltip: {
            type: "cross",
            backgroundColor: "rgba(255,255,255,1)",
            borderColor: "rgba(3,120,212,.4)",
            borderWidth: 1,
            padding: 20,
            trigger: "axis",
            textStyle: {
              fontFamily: "Verdana, sans-serif",
              fontSize: 14,
              color: "#333333",
              fontWeight: 400
            },
            formatter(params) {
              let htmlStr = "";
              if (params.length > 0) {
                htmlStr += params[0].axisValue + "</br>";
              }
              for (let i = 0; i < params.length; i++) {
                let param = params[i];
                let xName = param.axisValue; //x轴的名称
                let seriesName = param.seriesName; //图例名称
                let value = param.value; //y轴值
                let color = param.color; //图例颜色
                // 格式化值
                const formatter =
                  allseries[param.seriesIndex].formatter || "{value}";
                if (typeof formatter == "string") {
                  value = formatter.replace("{value}", value);
                } else if (typeof formatter == "function") {
                  value = formatter(value);
                }

                htmlStr += "<div>";
                htmlStr += param.marker;
                //圆点后面显示的文本
                htmlStr += seriesName + "：" + value;
                htmlStr += "</div>";
              }
              return htmlStr;
            }
          },
          xAxis: [
            {
              type: "category",
              data: xtimes,
              position: "bottom",
              axisLabel: {
                interval: 0,
                rotate: -70,
                show: true,
                splitNumber: 15,
                textStyle: {
                  fontSize: 12,
                  color: "#B6B6B6"
                }
              },
              axisLine: {
                show: true,
                lineStyle: {
                  color: "#E8E8E8"
                }
              },
              axisTick: {
                show: false
              }
            },
            {
              type: "category",
              data: xlegend,
              position: "bottom",
              offset: 40,
              axisLine: {
                show: true,
                lineStyle: {
                  color: "#E8E8E8"
                }
              },
              axisTick: {
                show: false
              },
              axisLabel: {
                interval: 0,
                rotate: 0,
                show: true,
                splitNumber: 15,
                textStyle: {
                  fontSize: 12,
                  color: "#B6B6B6"
                }
              },
              axisTick: {
                length: 80,
                inside: true,
                interval: 0,
                lineStyle: {
                  color: "#45C8E1",
                  fontSize: "14px"
                }
              }
            }
          ],
          yAxis: allseries.map((item, index) => {
            return {
              type: "value",
              axisLabel: {
                textStyle: {
                  color: "#B6B6B6"
                },
                formatter: item.formatter
              },
              axisLine: {
                show: true,
                lineStyle: {
                  color: "#E8E8E8"
                }
              },
              axisTick: {
                show: false
              },
              //网格样式
              splitLine: {
                show: true,
                lineStyle: {
                  color: "#E8E8E8",
                  width: 1,
                  type: "solid"
                }
              }
            };
          }),
          series: leftseries.map((item, index) => {
            return {
              type: item.type,
              name: item.name,
              stack: item.stack,
              smooth: true,
              data: item.data,
              yAxisIndex: item.yAxisIndex || 0,
              test: 123,

              barGap: "0%",
              barCategoryGap: "30%"
            };
          })
        };
      }
    }
  },
  mounted() {
    this.updateChartData();
  }
};
</script>

<style lang="scss" scoped></style>
