/*
 * 门店智能模块中页面路由配置
 */

// 商品管理
const goodsManageCmpt = () => import( /* webpackChunkName: "goodsManageCmpt" */ '../../pages/storeIntelligent/goodsManage');
// 商品配置
const commodityAllocation = () => import( /* webpackChunkName: "goodsManageCmpt" */ '../../pages/storeIntelligent/commodityAllocation.vue');
// 生产计划
const productionPlanCmpt = () => import( /* webpackChunkName: "productionPlanCmpt" */ '../../pages/storeIntelligent/productionPlan.vue');

// 便利店--商品管理
const convenientGoodsManage = () => import( /* webpackChunkName: "convenientGoodsManage" */ '../../pages/storeIntelligent/convenient/goodsManage.vue');


// 策略配置
const policyConfiguration = () =>
	import(
		/* webpackChunkName: "periodAnalysis" */
		"../../pages/storeIntelligent/policyConfiguration.vue"
	);



export default [{
		path: '/storeIntelGoodsManage',
		name: 'storeIntelGoodsManage',
		component: goodsManageCmpt
	},
	{
		path: '/storeIntelConGoodsManage',
		name: 'storeIntelConGoodsManage',
		component: convenientGoodsManage
	},
	{
		path: '/storeIntelProdPlan',
		name: 'storeIntelProdPlan',
		component: productionPlanCmpt
	},
	{
		path: '/commodityAllocation',
		name: 'commodityAllocation',
		component: commodityAllocation
	},
	{
		path: '/policyConfiguration',
		name: 'policyConfiguration',
		component: policyConfiguration
	},

];