// 带训管理列表
const leadTrainingTemplate = () =>
  import(
    /* webpackChunkName: "courseManage" */ '@/pages/leadTraining/leadTrainingTemplate/index.vue'
  );
// 带训管理表单
const leadTrainingTemplateForm = () =>
  import(
    /* webpackChunkName: "courseManage" */ '@/pages/leadTraining/leadTrainingTemplate/DataHandle.vue'
  );

// 带训数据统计
const leadTrainingStatistics = () =>
  import(
    /* webpackChunkName: "courseManage" */ '@/pages/leadTraining/leadTrainingStatistics/index'
  );

// 带训全部数据
const leadTrainingRecordAllData = () =>
  import(/* webpackChunkName: "courseManage" */ '@/pages/leadTraining/leadTrainingRecords/allData');

export default [
  {
    path: '/leadTraining/template/list',
    name: 'leadTrainingTemplate',
    component: leadTrainingTemplate,
  },
  {
    path: '/leadTraining/template/form',
    name: 'leadTrainingTemplateForm',
    component: leadTrainingTemplateForm,
  },
  {
    path: '/leadTraining/statistics/:type',
    name: 'leadTrainingStatistics',
    component: leadTrainingStatistics,
  },
  {
    path: '/leadTraining/record',
    name: 'leadTrainingRecordAllData',
    component: leadTrainingRecordAllData,
  },
];
