<template>
  <div
    :class="className"
    :style="{
      height: heights,
    }"
    ref="echart"
  />
</template>

<script>
import echarts from "echarts";
export default {
  props: {
    id: {
      type: String
    },
    className: {
      type: String,
      default: "chart"
    },
    chartData: {
      type: Object,
      required: true
    },
    heights: {
      type: String,
      default: "484px"
    },
    clearType: {
      type: Boolean
    }
  },
  data() {
    return {
      chart: null,
      worker: null
    };
  },
  watch: {
    chartData: {
      handler(val, oldVal) {
        this.setOptions(val, oldVal);
      },
      deep: true,
      immediate: true

    },
    "$store.state.tenant.tree_all_type": {
      handler(news, old) {
        this.chart.resize();
      }
    },
    clearType() {
      if (this.clearType) {
        this.chart.clear();
      }
    },
    heights() {
      this.$refs.echart.style.height = this.heights;
      this.chart.resize();
    }
  },
  mounted() {
    setTimeout(() => {
      this.initChart();
      window.addEventListener("resize", this.doSize, false);
      this.chart.resize();
    }, 300);
    // this.$nextTick(() => {
    //   this.initChart();
    //   window.addEventListener("resize", this.doSize, false);
    //   this.chart.resize();
    // });
  },
  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    window.removeEventListener("resize", this.doSize, false);
    this.chart.clear();
    this.chart = null;
  },
  methods: {
    initChart() {
      this.chart = echarts.init(this.$el,
      '', {
        width: 'auto',
      }
      )
      this.setOptions(this.chartData);
      this.chart.on("legendselectchanged", obj => {
        if (typeof this.$parent.selectc == "function") {
          this.$parent.selectc(obj.selected);
        }
        if (typeof this.$parent.getLegendSelectedData == "function") {
          this.$parent.getLegendSelectedData(obj.selected);
        }
      });
    },
    setOptions(val, oldVal) {
      // const seriesLengthChanged = oldVal && oldVal.series && val.series.length != oldVal.series.length
      // if (!val.hasOwnProperty('series') || seriesLengthChanged) {
      //     this.chart.clear();
      // }
      
      this.chart && this.chart.setOption(val, true);
    },
    doSize() {
      this.chart.resize();
    }
  }
};
</script>
