/**
 * 运营管理
 */
const banner = () => import('@/pages/trade/operation/banner'); //轮播配置
const goodsPurchaseLimit = () => import('@/pages/trade/operation/goodsPurchaseLimit'); //商品限购
const menuManager = () => import('@/pages/trade/operation/menuManager'); //菜单管理
const priceManager = () => import('@/pages/trade/operation/priceManager'); //价格管理(总部)
const saleStatusManager = () => import('@/pages/trade/operation/saleStatusManager'); //上架管理
const storeSaleManager = () => import('@/pages/trade/operation/store/saleManager'); //门店售卖管理
const weappManager = () => import('@/pages/trade/operation/weapp/index'); //小程序发布管理
const grouponManager = () => import('@/pages/trade/groupon/commodity/index'); //团购管理列表

const goodsRecommend = () => import('@/pages/trade/operation/goodsRecommend'); //小程序发布管理

export default [
    //运营管理
    {
        path: '/trade/operation/menuManager',
        name: 'menuManager',
        component: menuManager
    },
    {
        path: '/trade/operation/banner',
        name: '/trade/operation/banner',
        component: banner
    },
    {
        path: '/trade/operation/grouponManager',
        name: '/trade/operation/grouponManager',
        component: grouponManager
    },
    {
        path: '/trade/operation/goodsPurchaseLimit',
        name: '/trade/operation/goodsPurchaseLimit',
        component: goodsPurchaseLimit
    },
    {
        path: '/trade/operation/priceManager',
        name: 'priceManager',
        component: priceManager
    },
    {
        path: '/trade/operation/saleStatusManager',
        name: 'saleStatusManager',
        component: saleStatusManager,
        meta: {
            isStoreManage: false
        }
    },
    {
        path: '/trade/operation/store/saleStatusManager',
        name: 'saleStatusManager',
        component: saleStatusManager,
        meta: {
            isStoreManage: true
        }
    },
    { //门店售卖管理(废弃)
        path: '/trade/operation/storeSaleManager',
        name: 'storeSaleManager',
        component: storeSaleManager
    },
    { //小程序授权发布管理
        path: '/trade/operation/weappManager',
        name: 'weappManager',
        component: weappManager
    },

    //商品推荐配置
    { 
        path: '/trade/operation/goodsRecommend',
        name: '/trade/operation/goodsRecommend',
        component: goodsRecommend
    },

    
]
