const scanCodeActivity = () => import(/*  webpackChunkName: "memberWeapp" */ '@/pages/memberWeapp/scanCodeActivity/index.vue');
const scanCodeActivityAdd = () => import(/* webpackChunkName: "memberWeapp" */ '@/pages/memberWeapp/scanCodeActivity/add.vue');
const couponRecomment = () => import(/* webpackChunkName: "memberWeapp" */ '@/pages/memberWeapp/couponRecomment/index.vue');
const couponRecommentDetail = () => import(/* webpackChunkName: "memberWeapp" */ '@/pages/memberWeapp/couponRecomment/detail.vue');

const couponTimeLimit = () => import(/*  webpackChunkName: "memberWeapp" */ '@/pages/memberWeapp/couponTimeLimit/index.vue')
const couponTimeLimitDetail = () => import(/*  webpackChunkName: "memberWeapp" */ '@/pages/memberWeapp/couponTimeLimit/detail.vue')

export default [
	{
		path: '/memberWeapp/couponRecomment/index',
		name: 'couponRecomment',
		component: couponRecomment,
	},
	{
		path: '/memberWeapp/couponRecomment/detail/:id',
		name: 'couponRecommentDetail',
		component: couponRecommentDetail
	},
	{
		path: '/memberWeapp/scanCodeActivity',
		name: 'scanCodeActivity',
		component: scanCodeActivity
	},
	{
		path: '/memberWeapp/scanCodeActivity/add',
		name: 'scanCodeActivity',
		component: scanCodeActivityAdd
	},
	{
		path: '/memberWeapp/scanCodeActivity/add/:id',
		name: 'scanCodeActivity',
		component: scanCodeActivityAdd
	},
	// 限时免费领
	{
		path: '/memberWeapp/couponTimeLimit/index',
		name: 'couponTimeLimitIndex',
		component: couponTimeLimit
	},
	{
		path: '/memberWeapp/couponTimeLimit/detail/:id',
		name: 'couponTimeLimitDetail',
		component: couponTimeLimitDetail
	}
]