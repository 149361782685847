/**
 *仓库管理
 */
const supplier = () => import('@/pages/trade/warehouse/supplier.vue'); // 供应商
const stockQuery = () => import('@/pages/trade/warehouse/stockQuery.vue'); // 库存查询
const inOrder = () => import('@/pages/trade/warehouse/inOrder.vue'); // 商品入库
const outOrder = () => import('@/pages/trade/warehouse/outOrder.vue'); // 商品出库
const transferOrder = () => import('@/pages/trade/warehouse/transferOrder.vue'); // 商品调拨
const stocktaking = () => import('@/pages/trade/warehouse/stocktaking.vue'); // 库存盘点

export default [
    {
        path: '/trade/warehouse/supplier',
        name: 'supplier',
        component: supplier
    }, {
        path: '/trade/warehouse/global/stockQuery',
        name: 'globalStockQuery',
        component: stockQuery,
        meta: {
            isStoreManage: false
        }
    }, {
        path: '/trade/warehouse/global/inOrder',
        name: 'globalInOrder',
        component: inOrder,
        meta: {
            isStoreManage: false
        }
    }, {
        path: '/trade/warehouse/global/outOrder',
        name: 'globalOutOrder',
        component: outOrder,
        meta: {
            isStoreManage: false
        }
    }, {
        path: '/trade/warehouse/global/transferOrder',
        name: 'globalTransferOrder',
        component: transferOrder,
        meta: {
            isStoreManage: false
        }
    }, {
        path: '/trade/warehouse/global/stocktaking',
        name: 'globalStocktaking',
        component: stocktaking,
        meta: {
            isStoreManage: false
        }
    },
    /**
     * 门店管理-仓储管理
     */
    {
        path: '/trade/warehouse/store/stockQuery',
        name: 'storeStockQuery',
        component: stockQuery,
        meta: {
            isStoreManage: true
        }
    }, {
        path: '/trade/warehouse/store/inOrder',
        name: 'storeInOrder',
        component: inOrder,
        meta: {
            isStoreManage: true
        }
    }, {
        path: '/trade/warehouse/store/outOrder',
        name: 'storeOutOrder',
        component: outOrder,
        meta: {
            isStoreManage: true
        }
    }, {
        path: '/trade/warehouse/store/transferOrder',
        name: 'storeTransferOrder',
        component: transferOrder,
        meta: {
            isStoreManage: true
        }
    }, {
        path: '/trade/warehouse/store/stocktaking',
        name: 'storeStocktaking',
        component: stocktaking,
        meta: {
            isStoreManage: true
        }
    }

]
