/*
 * 实体管理--路由配置
 */

//实体管理
const entityManagement = () => import('@/pages/entityManagement/list/index.vue');
//实体详情
const entityDetail = () => import('@/pages/entityManagement/detail/index.vue');

//实体管理
const entityDataList = () => import('@/pages/entityData/list/index.vue');
//实体详情
const entityDataDetail = () => import('@/pages/entityData/detail/index.vue');

export default [
  // 实体管理
  {
    path: '/entityManagement/list',
    name: 'entityManagement',
    component: entityManagement,
  },
  // 实体详情
  {
    path: '/entityManagement/detail',
    name: 'entityDetail',
    component: entityDetail,
  },
  // 实体管理
  {
    path: '/entityData/list',
    name: 'entityDataList',
    component: entityDataList,
  },
  // 实体详情
  {
    path: '/entityData/detail',
    name: 'entityDataDetail',
    component: entityDataDetail,
  },
];
