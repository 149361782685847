/**
 *用户隐私协议
 */
 const privacyAgreement = () => import("@/pages/trade/privacyAgreement/index.vue");
 
 export default [
   {
     path: "/trade/privacyAgreement",
     name: "/trade/privacyAgreement",
     component: privacyAgreement,
   }
 ];
 