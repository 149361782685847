/**
 * 会员相关
 */
const equities = () => import("@/pages/trade/member/equities"); // 会员权益

const member = () => import("@/pages/trade/member/member.vue"); // 会员中心
const grade = () => import("@/pages/trade/member/grade"); // 会员等级

const grow = () => import("@/pages/trade/member/grow.vue"); // 成长值

export default [
  {
    path: "/trade/member/equities",
    name: "trade/member/equities",
    component: equities,
  },
  {
    path: "/trade/member/member",
    name: "member",
    component: member,
  },
  {
    path: "/trade/member/grade",
    name: "grade",
    component: grade,
  },
  {
    path: "/trade/member/grow",
    name: "grow",
    component: grow,
  },
];
