export default [
  // 加盟商管理列表页 /Volumes/code/webWork/dataone-frontend/insight360/src/pages/externalPersonnelManagement/allianceBusiness/list/index.vue
  {
    path: '/externalPersonnelManagement/allianceBusiness/list',
    name: 'externalPersonnelManagement-allianceBusiness-list',
    component: () => import('@/pages/externalPersonnelManagement/allianceBusiness/list/index.vue'),
  },
  // 施工方管理
  {
    path: '/externalPersonnelManagement/constructorMng/constructor',
    name: 'constructorMng',
    component: () =>
      import('@/pages/externalPersonnelManagement/constructorMng/constructorList.vue'),
  },
  // 供应商管理
  {
    path: '/externalPersonnelManagement/constructorMng/supplier',
    name: 'supplier',
    component: () => import('@/pages/externalPersonnelManagement/constructorMng/supplierList.vue'),
  },
];
