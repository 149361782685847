import axios from 'axios';

const baseUrl = '/api/auth/v2'

export const userlist = data => axios({
  //避免全局loading...
  url: `${baseUrl}/account/list/`,
  method: 'post',
  data: data,
  headers: {
    'Content-Type': 'application/json',
  }
})


export const authCentersimplelist = data => axios({
  //避免全局loading...
  url: `${baseUrl}/store/simple/list`,
  method: 'get',
  params: data,
  headers: {
    'Content-Type': 'application/json',
  }
})



export const authCenterlist = data => axios({
  //避免全局loading...
  url: `${baseUrl}/manage/label/list`,
  method: 'get',
  params: data,
  headers: {
    'Content-Type': 'application/json',
  }
})

export const categorylist = data => axios({
  //避免全局loading...
  url: `${baseUrl}/manage/goods/category/list`,
  method: 'post',
  data: data,
  headers: {
    'Content-Type': 'application/json',
  }
})

export const couponlist = data => axios({
  //避免全局loading...
  url: `${baseUrl}/manage/coupon/list`,
  method: 'post',
  data: data,
  headers: {
    'Content-Type': 'application/json',
  }
})

export const couponalter = data => axios({
  //避免全局loading...
  url: `${baseUrl}/manage/coupon/alter`,
  method: 'post',
  data: data,
  headers: {
    'Content-Type': 'application/json',
  }
})

// 批量修改商品树信息
export const batchModifyGoods = data => axios({
  url: `${baseUrl}/manage/goods/batch/alter`,
  method: 'post',
  data: data,
  headers: {
    'Content-Type': 'application/json',
  }
})

export const coupondel = data => axios({
  //避免全局loading...
  url: `${baseUrl}/manage/coupon/del`,
  method: 'post',
  data: data,
  headers: {
    'Content-Type': 'application/json',
  }
})


export const treeLabelList = data => axios({
  //避免全局loading...
  url: `${baseUrl}/label/goods/treeLabelList`,
  method: 'post',
  data: data,
  headers: {
    'Content-Type': 'application/json',
  }
})

export const goodstree = data => axios({
  //避免全局loading...
  url: `${baseUrl}/label/goods/tree`,
  method: 'get',
  params: data,
  headers: {
    'Content-Type': 'application/json',
  }
})



export const coupontreeLabelList = data => axios({
  //避免全局loading...
  url: `${baseUrl}/label/coupon/treeLabelList`,
  method: 'post',
  data: data,
  headers: {
    'Content-Type': 'application/json',
  }
})

export const coupontree = data => axios({
  //避免全局loading...
  url: `${baseUrl}/label/coupon/tree`,
  method: 'get',
  params: data,
  headers: {
    'Content-Type': 'application/json',
  }
})



export const goodstree_pinlei = data => axios({
  //避免全局loading...
  url: `${baseUrl}/manage/goods/tree`,
  method: 'get',
  params: data,
  headers: {
    'Content-Type': 'application/json',
  }
})


export const authCenteralter = data => axios({
  //避免全局loading...
  url: `${baseUrl}/manage/label/alter`,
  method: 'post',
  data: data,
  headers: {
    'Content-Type': 'application/json',
  }
})

export const authCenterdel = data => axios({
  //避免全局loading...
  url: `${baseUrl}/manage/label/del`,
  method: 'post',
  params: data,
  headers: {
    'Content-Type': 'application/json',
  }
})


export const managelist = data => axios({
  //避免全局loading...
  url: `${baseUrl}/manage/store/list`,
  method: 'post',
  data: data,
  headers: {
    'Content-Type': 'application/json',
  }
})

export const baselist = data => axios({
  //避免全局loading...
  url: `${baseUrl}/manage/store/base/list`,
  method: 'post',
  data: data,
  headers: {
    'Content-Type': 'application/json',
  }
})

export const basealter = data => axios({
  //避免全局loading...
  url: `${baseUrl}/manage/store/base/alter`,
  method: 'post',
  data: data,
  headers: {
    'Content-Type': 'application/json',
  }
})


export const basedel = data => axios({
  //避免全局loading...
  url: `${baseUrl}/manage/store/del`,
  method: 'post',
  data: data,
  headers: {
    'Content-Type': 'application/json',
  }
})




// 库存监控

export const storeSkulist = data => axios({
  //避免全局loading...
  url: '/dynamic-promotion/storeSku/list',
  method: 'post',
  data: data,
  headers: {
    'Content-Type': 'application/json',
  }
})


export const getCommodityCategoryTree = () => axios({
  //避免全局loading...
  url: '/dataone-api/api/v1/common/category/tree',
  method: 'get',
  headers: {
    'Content-Type': 'application/json',
  }
})






































export const labelchild = data => axios({
  //避免全局loading...
  url: `${baseUrl}/label/store/child`,
  method: 'get',
  params: data,
  headers: {
    'Content-Type': 'application/json',
  }
})


export const geochild = data => axios({
  //避免全局loading...
  url: `${baseUrl}/geo/child/`,
  method: 'post',
  data: data,
  headers: {
    'Content-Type': 'application/json',
  }
})

export const managealter = data => axios({
  //避免全局loading...
  url: `${baseUrl}/manage/store/alter`,
  method: 'post',
  data: data,
  headers: {
    'Content-Type': 'application/json',
  }
})

export const commonlist = data => axios({
  //避免全局loading...
  url: `${baseUrl}/manage/common/list`,
  method: 'post',
  data: data,
  headers: {
    'Content-Type': 'application/json',
  }
})



export const commonalter = data => axios({
  //避免全局loading...
  url: `${baseUrl}/manage/common/alter`,
  method: 'post',
  data: data,
  headers: {
    'Content-Type': 'application/json',
  }
})


export const packagelist = data => axios({
  //避免全局loading...
  url: `${baseUrl}/manage/coupon/package/list`,
  method: 'post',
  data: data,
  headers: {
    'Content-Type': 'application/json',
  }
})



export const goodslist = data => axios({
  //避免全局loading...
  url: `${baseUrl}/manage/goods/base/list`,
  method: 'post',
  data: data,
  headers: {
    'Content-Type': 'application/json',
  }
})

export const goodsalter = data => axios({
  //避免全局loading...
  url: `${baseUrl}/manage/goods/relateLabel`,
  method: 'post',
  data: data,
  headers: {
    'Content-Type': 'application/json',
  }
})

//商品树配置--标签管理--批量修改
export const batchGoodsalter = data => axios({
  //避免全局loading...
  url: `${baseUrl}/manage/goods/batch/relateLabel`,
  method: 'post',
  data: data,
  headers: {
    'Content-Type': 'application/json',
  }
})

export const couponrelateLabel = data => axios({
  //避免全局loading...
  url: `${baseUrl}/manage/coupon/relateLabel`,
  method: 'post',
  data: data,
  headers: {
    'Content-Type': 'application/json',
  }
})


export const managegoodsalter = data => axios({
  //避免全局loading...
  url: `${baseUrl}/manage/goods/alter`,
  method: 'post',
  data: data,
  headers: {
    'Content-Type': 'application/json',
  }
})

export const goodsdel = data => axios({
  //避免全局loading...
  url: `${baseUrl}/manage/goods/del`,
  method: 'post',
  data: data,
  headers: {
    'Content-Type': 'application/json',
  }
})


export const newuser = data => axios({
  //避免全局loading...
  url: `${baseUrl}/account/new/`,
  method: 'post',
  data: data,
  headers: {
    'Content-Type': 'application/json',
  }
})


export const updatas = data => axios({
  //避免全局loading...
  url: `${baseUrl}/account/modify/`,
  method: 'post',
  data: data,
  headers: {
    'Content-Type': 'application/json',
  }
})


export const deleter = data => axios({
  //避免全局loading...
  url: `${baseUrl}/account/del`,
  method: 'post',
  data: data,
  headers: {
    'Content-Type': 'application/json',
  }
})

//指标接口


export const nodelistdata = data => axios({
  //避免全局loading...
  url: `${baseUrl}/quota/navigate/`,
  method: 'post',
  data: data,
  headers: {
    'Content-Type': 'application/json',
  }
})


export const tableList = data => axios({
  //避免全局loading...
  url: `${baseUrl}/quota/list/`,
  method: 'post',
  data: data,
  headers: {
    'Content-Type': 'application/json',
  }
})

export const roleList = data => axios({
  //避免全局loading...
  url: `${baseUrl}/role/list/`,
  method: 'post',
  data: data,
  headers: {
    'Content-Type': 'application/json',
  }
})


export const addrolenew = data => axios({
  //避免全局loading...
  url: `${baseUrl}/role/new/`,
  method: 'post',
  data: data,
  headers: {
    'Content-Type': 'application/json',
  }
})



export const roledel = data => axios({
  //避免全局loading...
  url: `${baseUrl}/role/del/`,
  method: 'post',
  data: data,
  headers: {
    'Content-Type': 'application/json',
  }
})

export const pei_user = data => axios({
  //避免全局loading...
  url: `${baseUrl}/role/user/`,
  method: 'post',
  data: data,
  headers: {
    'Content-Type': 'application/json',
  }
})

export const pei_gou_xuan = data => axios({
  //避免全局loading...
  url: `${baseUrl}/role/addAccount/`,
  method: 'post',
  data: data,
  headers: {
    'Content-Type': 'application/json',
  }
})

export const pei_quxiao_gou = data => axios({
  //避免全局loading...
  url: `${baseUrl}/role/delAccount/`,
  method: 'post',
  data: data,
  headers: {
    'Content-Type': 'application/json',
  }
})

export const xiu_gai = data => axios({
  //避免全局loading...
  url: `${baseUrl}/role/change/`,
  method: 'post',
  data: data,
  headers: {
    'Content-Type': 'application/json',
  }
})


export const tree_list = data => axios({
  //避免全局loading...
  url: `${baseUrl}/menu/roleMenus/list/`,
  method: 'post',
  data: data,
  headers: {
    'Content-Type': 'application/json',
  }
})




export const labelgoodslist = data => axios({
  //避免全局loading...
  url: `${baseUrl}/manage/label/goods/list`,
  method: 'post',
  data: data,
  headers: {
    'Content-Type': 'application/json',
  }
})


export const labelcouponlist = data => axios({
  //避免全局loading...
  url: `${baseUrl}/manage/label/coupon/list`,
  method: 'post',
  data: data,
  headers: {
    'Content-Type': 'application/json',
  }
})


export const tree_sub = data => axios({
  //避免全局loading...
  url: `${baseUrl}/menu/roleMenus/commit/`,
  method: 'post',
  data: data,
  headers: {
    'Content-Type': 'application/json',
  }
})

// 菜单模块列表 
export const module_list = data => axios({
  //避免全局loading...
  url: `${baseUrl}/auth/module/list/`,
  method: 'post',
  data: data,
  headers: {
    'Content-Type': 'application/json',
  }
})

// 菜单模块下菜单
export const menu_list = data => axios({
  //避免全局loading...
  url: `${baseUrl}/auth/menu/v2/list`,
  method: 'post',
  data: data,
  headers: {
    'Content-Type': 'application/json',
  }
})

// 配置中心
export const managecenter = data => axios({
  //避免全局loading...
  url: `${baseUrl}/auth/menu/managecenter`,
  method: 'get',
  headers: {
    'Content-Type': 'application/json',
  }
})

