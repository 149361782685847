const getters = {
    // 订单状态
    orderStatusList: state => state.codeList.orderStatusList,
    // 支付状态
    payStatusList: state => state.codeList.payStatusList,
    // 业务类型
    businessTypeList: state => state.codeList.businessTypeList,
    // 订单类型
    orderTypeList: state => state.codeList.orderTypeList,
    // 支付类型
    payTypeList: state => state.codeList.payTypeList,
    // 核心指标
    coreIndicatorList: state => state.codeList.coreIndicatorList,
}
export default getters