<template>
  <div class="footer">
    <div class="line">
      <img
        class="img"
        src="https://hidataqrcode.oss-cn-hangzhou.aliyuncs.com/956753402996461568.png?Expires=1947495081&OSSAccessKeyId=LTAI5xyIqe4GhOvC&Signature=1tgjJuUBf1QnRFqcy4kg3LR8jEo%3D"
        alt="">
      <span>明之数据</span>
    </div>
    <div>
      <span>帮助千万企业实现数据驱动的商业经营</span>
    </div>
  </div>
</template>

<script>
  export default {
  }
</script>

<style lang="scss" scoped>
.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 12px;
  color: #D3D3D5;
  margin: 10px 0 15px;
  .line {
    margin-bottom: 5px;
  }
  .img {
    width: 15px;
    height: 15px;
    margin-right: 5px;
  }
}
</style>