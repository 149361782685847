/**
 * @see http://yapi.infra.mzdata.top/project/42/interface/api/123
 */
import axios from 'axios';
const baseUrl = '/api/auth/v3';
// 公用接口
export const commonRequest = (data) =>
  axios({
    url: '/apiauth/agentApi/agentAdd',
    method: 'POST',
    data,
  });

// 获取过期时间
export const getVipExpired = (data) =>
  axios({
    url: '/apiauth/mzstore/tenant/getVipExpired',
    method: 'GET',
    params: data,
  });

//获取租户和用户信息
export const getUserInfo = (data) =>
  axios({
    url: `${baseUrl}/user/info`,
    method: 'POST',
    data,
  });

//获取菜单
export const getMenu = (data) =>
  axios({
    url: `${baseUrl}/menu/list`,
    method: 'POST',
    params: data,
  }).then((res) => {
    // if (res.data.code === 0) {
    //   //刚刚接手项目，发现菜单是API加载，暂时无法修改后台菜单数据，先往返回值中筛入一个菜单
    //   let { menus, module: modules } = res.data.data
    //   const module = modules.find(m=>m.moduleName == '业务中台')
    //   if (module) {
    //     const top = menus[module.id].find(m=>m.menuName == '店铺装修')
    //     if (top && !top.children.find(m=>m.targetUrl=='/trade/decoration/appletConfiguration')) {
    //       top.children.push({
    //         id: 9999,
    //         isAuthed: 1,
    //         key: "",
    //         menuIconCode: "",
    //         menuName: "小程序配置",
    //         moduleId: module.id,
    //         parentId: top.id,
    //         redirectUrl: "",
    //         sort: 0,
    //         targetUrl: "/trade/decoration/appletConfiguration",
    //         type: "menu"
    //       })
    //     }
    //   }
    // }
    return res;
  });

//退出登录
export const logout = (data) =>
  axios({
    // url: `${baseUrl}/user/logout`,
    url: '/om-auth/api/auth/logout',
    method: 'GET',
    params: data,
  });

//获取登录用户下的门店权限
// export const getAuthStores = (data) =>
//   axios({
//     url: `${baseUrl}/store/getUserAuthStoreList`,
//     method: "GET",
//     params: data,
//     noLoading: true,
//   });

export const getAuthStores = (data) =>
  axios({
    url: `${baseUrl}/user/store/list`,
    method: 'GET',
    params: data,
    noLoading: true,
  });
