import Request from './request'
// 根路径
const baseURLs = [
  {
    key: 'data_zuul',
    baseURL:  ""
  },
  {
    key: 'api/coupon',
    baseURL: ""
  }
]
const baseURL = '/coupon/api/v1/'
let instance = new Request(baseURL, baseURLs)
export default instance;