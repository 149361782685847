/**
 *订单管理
 */
const search = () => import("@/pages/trade/order/search.vue"); // 订单搜索
const orderDetail = () => import("@/pages/trade/order/orderDetail"); // 订单搜索

export default [
  {
    path: "/trade/order/global/search",
    name: "globalSearch",
    component: search,
    meta: {
      isStoreManage: false,
    },
  },

  {
    path: "/trade/order/store/search",
    name: "storeSearch",
    component: search,
    meta: {
      isStoreManage: true,
    },
  },
  {
    path: "/trade/order/detail",
    name: "orderDetail",
    component: orderDetail,
  },
];
