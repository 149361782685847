export default {
  state: {
    nodeMap: new Map(),
    isEdit: null,
    selectedNode: {},
    selectFormItem: null,
    // 当前节点树所在的流程模板
    design: {
      /**
       * snapshotId 快照id
       * templateId 模板唯一id
       */
    },
    subTemplateList: [], //当前主流程下的子流程list
    mainTemplateInfo: {
      //主流程info
      /**
       * snapshotId 快照id
       * templateId 模板唯一id
       */
    },
    subTemplateBaseInfo: {}, //子流程基础配置info
    activeTabKey: '', //设置当前tab
  },
  mutations: {
    selectedNode(state, val) {
      state.selectedNode = val;
    },
    loadForm(state, val) {
      state.design = val;
    },
    setMainTemplateInfo(state, val) {
      state.mainTemplateInfo = val;
    },
    setSubTemplateList(state, val) {
      state.subTemplateList = val;
    },
    setSubTemplateBaseInfo(state, val) {
      state.subTemplateBaseInfo = val;
    },
    setActiveTabKey(state, val) {
      state.activeTabKey = val;
    },
    setIsEdit(state, val) {
      state.isEdit = val;
    },
  },
  getters: {},
  actions: {},
  modules: {},
};
