// 默认门店分组数据
export const defaultStoreGroupData = {
  tag: '',
  branch: [
    {
      label: '',
      value: '',
    },
  ],
}
