import { render, staticRenderFns } from "./MZDTable.vue?vue&type=template&id=258347ba&scoped=true&"
import script from "./MZDTable.vue?vue&type=script&lang=js&"
export * from "./MZDTable.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "258347ba",
  null
  
)

export default component.exports