<template>
  <div class="personal">
    <div class="account-user">
      <img class="avatar" :src="avatar" alt="" />
      <div>
        <div class="info-item" v-if="this.$store.state.tenant.userInfo.realName">
          <span class="info-label">用户名:</span> {{ $store.state.tenant.userInfo.realName }}
        </div>
        <div class="info-item" v-if="rolesName">
          <span class="info-label">角色:</span>
          <span class="info-label-role two-ellipsis">{{ rolesName }}</span>
        </div>
        <div class="info-item" v-if="this.$store.state.tenant.userInfo.phone">
          <span class="info-label">手机号:</span> {{ $store.state.tenant.userInfo.phone }}
        </div>
        <div class="info-item" v-if="this.$store.state.tenant.userInfo.mail">
          <span class="info-label">邮箱:</span> {{ $store.state.tenant.userInfo.mail }}
        </div>
        <div class="info-item" v-if="this.$store.state.tenant.tenant.code">
          <span class="info-label">品牌编码:</span> {{ $store.state.tenant.tenant.code }}
        </div>
        <div>
          <span style="color: #1969f1" @click="userLogout">注销</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { LOGIN_URL } from '@/config';
import { logout } from '@/api/autho';
import { removeToken } from '@/util/cookie';

export default {
  computed: {
    avatar() {
      if (this.$store.state.tenant.userInfo.avatar) {
        return this.$store.state.tenant.userInfo.avatar;
      }
      if (this.$store.state.tenant.tenant.logo) {
        return this.$store.state.tenant.tenant.logo;
      }
      return 'https://pic.mzdata.top/fe/be/febec8efded3c9151127b4a0161adf63_180_182.png';
    },
    rolesName() {
      return (this.$store.state.tenant.roles || []).map((i) => i.name).join(',');
    },
  },
  methods: {
    onClose() {},
    userLogout() {
      //判断登录返回 地址
      let hosts = location.host;
      this.$confirm('确定退出登录吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.$store.dispatch('clear');
          // this.$store.commit('setCurrentMenu', {})
          localStorage.clear();
          sessionStorage.clear();
          removeToken();

          this.$nextTick(() => {
            //跳转到以前页面
            logout().then((res) => {
              console.log(res.data);
              //let _msgurl = (res.data.data&&res.data.data.url) ? res.data.data.url : LOGIN_URL
              window.location.reload();
            });
          });
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.personal {
  min-width: 350px;
  max-width: 400px;
  background-color: #fff;
  box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05), 0px 6px 16px 0px rgba(0, 0, 0, 0.08),
    0px 3px 6px -4px rgba(0, 0, 0, 0.12) !important;

  .account-user {
    display: flex;
    align-items: center;
    height: 140px;
    background: #fff;
    padding: 0 20px 0 16px;
    .avatar {
      width: 88px;
      height: 88px;
      margin: 0 11px 0 0;
    }
    .info-item {
      display: flex;
      // white-space: nowrap;
      &:last-child {
        margin-bottom: 20px;
      }
    }
    .info-label {
      display: inline-block;
      width: fit-content;
      margin-right: 8px;
      flex-shrink: 0;
    }
  }
}
</style>
