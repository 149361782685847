const businessBoard = () =>
  import (
    /* webpackChunkName: "businessAnalysisBIV2" */
    '@/pages/BI-v2/businessAnalysis/businessBoard/index.vue'
  ) // 经营看板
const trendAnalysis = () =>
  import (
    /* webpackChunkName: "businessAnalysisBIV2" */
    '@/pages/BI-v2/businessAnalysis/trendAnalysis/index.vue'
  ) // 趋势看板
const timePeriodAnalysis = () =>
  import (
    /* webpackChunkName: "businessAnalysisBIV2" */
    '@/pages/BI-v2/businessAnalysis/timePeriodAnalysis/index.vue'
  ) // 时段分析

const contrastiveAnalysis = () =>
  import (
    /* cwebpackChunkName: "storeAnalysisBIV2" */
    '@/pages/BI-v2/storeAnalysis/contrastiveAnalysis/index.vue'
  ) // 对比分析

const CategoryAnalysis = () =>
  import (
    /* webpackChunkName: "goodsAnylysisBIV2" */
    '@/pages/BI-v2/goodsAnylysis/CategoryAnalysis/index.vue'
  ) // 品类分析
const SingleAnalysis = () =>
  import (
    /* webpackChunkName: "goodsAnylysisBIV2" */
    '@/pages/BI-v2/goodsAnylysis/SingleAnalysis/index.vue'
  ) // 单品分析
const ABCAnalysis = () =>
  import (
    /* webpackChunkName: "goodsAnylysisBIV2" */
    '@/pages/BI-v2/goodsAnylysis/ABCAnalysis/index.vue'
  ) // ABC分析
const GoodsTree = () =>
  import (
    /* webpackChunkName: "goodsTreeBIV2" */
    '@/pages/BI-v2/goodsAnylysis/GoodsTree/index.vue'
  ) // 商品清洗
  const supplierMgnList = () =>
  import (
    '@/pages/BI-v2/supplierMgn/list/index.vue'
  ) // 商品清洗
  const PrivateWarn = () =>
  import (
    '@/pages/BI-v2/warning/private/index.vue'
  ) // 私采分析
const GoodsCheck = () =>
  import (
    '@/pages/BI-v2/warning/goodsCheck/index.vue'
  ) // 商品巡检
const DataAssets = () =>
  import (
    /* webpackChunkName: "goodsTreeBIV2" */
    '@/pages/BI-v2/dataAssets/home/index.vue'
  ) // 数据资产
const customerBoard = () =>
  import (
    /* webpackChunkName: "customerAnalysisBIV2" */
    '@/pages/BI-v2/customerAnalysis/customerBoard/index.vue'
  ) // 客户看板
const passengerFlowAnalysis = () =>
  import (
    /* webpackChunkName: "customerAnalysisBIV2" */
    '@/pages/BI-v2/customerAnalysis/passengerFlowAnalysis/index.vue'
  ) // 人流分析
const repurchaseAnalysis = () =>
  import (
    /* webpackChunkName: "customerAnalysisBIV2" */
    '@/pages/BI-v2/customerAnalysis/repurchaseAnalysis/index.vue'
  ) // 复购率分析
const customerLifecycle = () =>
  import (
    /* webpackChunkName: "customerAnalysisBIV2" */
    '@/pages/BI-v2/customerAnalysis/customerLifecycle/index.vue'
  ) // 复购率分析
  // const RFMAnalysis = () =>
  //   import(
  //     /* webpackChunkName: "customerAnalysisBIV2" */ '@/pages/BI-v2/customerAnalysis/RFMAnalysis/index.vue'
  //   ) // RFM分析
const CustomerCascader = () =>
  import (
    /* webpackChunkName: "customerAnalysisBIV2" */
    '@/pages/BI-v2/customerAnalysis/customerCascader/index.vue'
  ) // 客户分层
const StoreManagement = () =>
  import (
    /* webpackChunkName: "settings" */
    '@/pages/BI-v2/settings/storeManage/index.vue'
  ) // 门店管理
const GoodsManagement = () =>
  import (
    /* webpackChunkName: "settings" */
    '@/pages/BI-v2/settings/goodsManage/index.vue'
  ) // 商品管理

const Dataease = () =>
  import ( /* webpackChunkName: "settings" */ '@/pages/BI-v2/dataease/index.vue') // 商品管理

const updateCookie = () =>
  import (
    /* webpackChunkName: "businessAnalysisBIV2" */
    '@/pages/BI-v2/dataease/updateCookie/index.vue'
  ) // 刷缓存
  //明之门店应用配置
const mzmdSet = () =>
  import (
    '@/pages/BI-v2/mzmdSet/index.vue'
  )
  // 用户管理
const userManage = () =>
  import ( /* webpackChunkName: "autho" */ '../../pages/autho/userManage/index.vue')

  // 门店排名-排名配置
const rankConfig = () => import ('@/pages/BI-v2/storeRank/rankConfig/index.vue')

export default [
  // 经营分析 -- 经营看板
  {
    path: '/businessAnalysis/businessBoard',
    name: 'businessBoard',
    meta: {
      title: '经营看板',
      showFooter: true,
      isBIV3: true,
    },
    component: businessBoard,
  },

  // 经营分析--趋势分析
  {
    path: '/businessAnalysis/trendAnalysis',
    name: 'trendAnalysis',
    meta: {
      title: '趋势分析',
      showFooter: true,
      isBIV3: true,
    },
    component: trendAnalysis,
  },

  // 经营分析 --- 时段分析
  {
    path: '/businessAnalysis/timePeriodAnalysis',
    name: 'timePeriodAnalysis',
    meta: {
      title: '时段分析',
      showFooter: true,
      isBIV3: true,
    },
    component: timePeriodAnalysis,
  },

  // 门店分析 --对比分析
  {
    path: '/storeAnalysis/contrastiveAnalysis',
    name: 'contrastiveAnalysis',
    meta: {
      title: '对比分析',
      isBIV3: true,
    },
    component: contrastiveAnalysis,
  },

  // 商品分析 -- 品类分析
  {
    path: '/goodsAnylysis/CategoryAnalysis',
    name: 'CategoryAnalysis',
    meta: {
      title: '品类分析',
      isBIV3: true,
    },
    component: CategoryAnalysis,
  },

  // 商品分析 -- 单品分析
  {
    path: '/goodsAnylysis/SingleAnalysis',
    name: 'SingleAnalysis',
    meta: {
      title: '单品分析',
      isBIV3: true,
    },
    component: SingleAnalysis,
  },

  // 商品分析 -- ABC分析
  {
    path: '/goodsAnylysis/ABCAnalysis',
    name: 'ABCAnalysis',
    meta: {
      title: 'ABC分析',
      isBIV3: true,
    },
    component: ABCAnalysis,
  },

  // 商品分析 -- 商品树维护
  {
    path: '/goodsAnylysis/GoodsTree',
    name: 'GoodsTree',
    meta: {
      title: '商品树维护',
      isBIV3: true,
    },
    component: GoodsTree
  },
  // 供应商开票查询
  {
    path: '/supplierMgn/list',
    name: 'supplierMgnList',
    meta: {
      title: '供应商开票查询',
      isBIV3: true,
    },
    component: supplierMgnList
  },
  //私采分析
  {
    path: '/privateWarn',
    name: 'PrivateWarn',
    meta: {
      title: '私采分析',
      isBIV3: true,
    },
    component: PrivateWarn
  },
  //私采分析
  {
    path: '/goodsCheck',
    name: 'GoodsCheck',
    meta: {
      title: '商品巡检',
      isBIV3: true,
    },
    component: GoodsCheck
  },
  // 数据资产
  {
    path: '/dataAssets/home',
    name: 'DataAssets',
    meta: {
      title: '商品树维护',
      isBIV3: true,
    },
    component: DataAssets
  },
  // 客户看板
  {
    path: '/customerAnalysis/customerBoard',
    name: 'customerBoard',
    component: customerBoard,
    meta: {
      title: '客户看板',
      isBIV3: true,
    },
  },
  {
    path: '/customerAnalysis/passengerFlowAnalysis',
    name: 'passengerFlowAnalysis',
    meta: {
      title: '客流分析',
      showFooter: true,
      isBIV3: true,
    },
    component: passengerFlowAnalysis,
  },
  {
    path: '/customerAnalysis/repurchaseAnalysis',
    name: 'repurchaseAnalysis',
    meta: {
      title: '复购率分析',
      showFooter: true,
      isBIV3: true,
    },
    component: repurchaseAnalysis,
  },
  {
    path: '/customerAnalysis/customerLifecycle',
    name: 'customerLifecycle',
    meta: {
      title: '客户生命周期',
      isBIV3: true,
    },
    component: customerLifecycle,
  },
  {
    path: '/customerAnalysis/RFMAnalysis',
    name: 'RFMAnalysis',
    meta: {
      // title: 'RFM分析',
      title: '客户分层',
      showFooter: true,
      isBIV3: true,
    },
    //  RFMAnalysis,
    component: CustomerCascader,
  },
  // 用户管理
  {
    path: '/BIV3/autho/userManage',
    name: 'userManage',
    meta: {
      title: '用户管理',
      isBIV3: true,
    },
    component: userManage,
  },
  // ---门店树维护
  {
    path: '/BIV3/storeManagement',
    component: StoreManagement,
    meta: {
      title: '门店树维护',
      isBIV3: true,
    },
  },

  {
    path: '/BIV3/goodsManagement',
    component: GoodsManagement,
    meta: {
      title: '商品树维护',
      isBIV3: true,
    },
  },

  {
    path: '/BIV3/dataease',
    component: Dataease,
    meta: {
      title: 'dataease',
      isBIV3: true,
    },
  },
  {
    path: '/BIV3/dataease/updateCookie',
    component: updateCookie,
    meta: {
      title: '刷缓存',
      isBIV3: true,
    },
  },
  {
    path: '/BIV3/mzmdSet',
    component: mzmdSet,
    meta: {
      title: '明之门店应用配置',
      isBIV3: true,
    },
  },
  // 门店排名-排名配置
  {
    path: '/storeRank/rankConfig',
    name: 'rankConfig',
    meta: {
      title: '排名配置',
      isBIV3: true,
    },
    component: rankConfig
  },
]