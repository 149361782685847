/**
 * 商品管理
 */
const goods = () => import('@/pages/trade/goods/goods.vue'); // 商品管理
const goodsForm = () => import('@/pages/trade/goods/component/goodsForm.vue'); // 商品管理Form
const category = () => import('@/pages/trade/goods/category.vue'); // 商品类目
const comboForm = () => import('@/pages/trade/goods/component/comboForm'); // 套餐管理Form

// 物料管理
const material = () => import('@/pages/trade/goods/material'); // 物料管理Form
const formula = () => import('@/pages/trade/goods/formula') // 配方管理
const formulaConfig = () => import('@/pages/trade/goods/formula/config') // 配方管理/物料配置

export default [
    {
        path: '/trade/goods/goods',
        name: 'goods',
        component: goods
    },
    {
        path: '/trade/goods/goods/goodsForm',
        name: 'goodsForm',
        component: goodsForm
    },
    {
        path: '/trade/goods/category',
        name: 'category',
        component: category
    },
    {
        path: '/trade/goods/comboForm',
        name: 'comboForm',
        component: comboForm
    },
    {
        path: '/trade/goods/material',
        name: 'material',
        component: material
    },
    {
        path: '/trade/goods/formula',
        name: 'formula',
        component: formula
    },
    {
        path: '/trade/goods/formula/config',
        name: 'formulaConfig',
        component: formulaConfig
    },
]
