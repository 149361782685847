import qs from 'qs'
import request from '@/libs/API_common.js'

const commonUrl = '/om-auth/api'

/*********         数据统计          **********/
/**
 * 查询线索转化数据
 * @param data
 * @returns {*}
 */
export const queryLeadConversionStatistics = (data) =>
  request({
    url: `${commonUrl}/crm/statistics/leadConversion`,
    method: 'POST',
    noLoading: true,
    data,
  })
/**
 * 获取线索转化导出下载链接
 * @param data
 * @returns {`/om-auth/api/crm/statistics/exportLeadConversion?${string}`}
 */
export const getLeadConversionStatisticsExportUrl = (data) => {
  return `${commonUrl}/crm/statistics/exportLeadConversion?${qs.stringify(data)}`
}

/**
 * 查询点位匹配统计
 * @param data
 * @returns {*}
 */
export const queryLocationMatchStatistics = (data) =>
  request({
    url: `${commonUrl}/crm/statistics/locationMatch`,
    method: 'POST',
    noLoading: true,
    data,
  })
/**
 * 导出点位匹配数据
 * @param data
 * @returns {*}
 */
export const getLocationMatchStatisticsExportUrl = (data) => {
  return `${commonUrl}/crm/statistics/exportLocationMatch?${qs.stringify(data)}`
}

/**
 * 查询拓展开发人员工作记录
 * @param data
 * @returns {*}
 */
export const queryWorkLogStatistics = (data) =>
  request({
    url: `${commonUrl}/crm/statistics/workLog`,
    method: 'POST',
    noLoading: true,
    data,
  })
/**
 * 导出拓展开发人员工作记录
 * @param data
 * @returns {*}
 */
export const getWorkLogStatisticsExportUrl = (data) => {
  return `${commonUrl}/crm/statistics/exportWorkLog?${qs.stringify(data)}`
}

/**
 * 查询合同统计数据
 * @param data
 * @returns {*}
 */
export const queryContractStatistics = (data) =>
  request({
    url: `${commonUrl}/crm/statistics/contract`,
    method: 'POST',
    noLoading: true,
    data,
  })
/**
 * 获取合同统计导出下载链接
 * @param data
 * @returns {`/om-auth/api/crm/statistics/exportContract?${string}`}
 */
export const getContractStatisticsExportUrl = (data) => {
  return `${commonUrl}/crm/statistics/exportContract?${qs.stringify(data)}`
}


/*********         公共接口          **********/
/**
 * 查询指定角色下的用户列表
 * @param params
 * @returns {*}
 */
export const queryCrmStaffListByRole = (params) =>
  request({
    url: `${commonUrl}/crm/common/queryStaffList`,
    method: 'GET',
    noLoading: true,
    params,
  })

/*********         数据字典          **********/
/**
 * 查询字典列表
 * @param params
 * @returns {*}
 */
export const queryDictList = (params) =>
  request({
    url: `${commonUrl}/crm/dict/queryList`,
    method: 'GET',
    noLoading: true,
    params,
  })
/**
 * 批量查询字典列表
 * @param params
 * @returns {*}
 */
export const batchQueryDictList = (params) =>
  request({
    url: `${commonUrl}/crm/dict/batchQueryList`,
    method: 'GET',
    noLoading: true,
    params,
  })
/**
 * 保存字典信息
 * @param data
 * @returns {*}
 */
export const saveDictInfo = (data) =>
  request({
    url: `${commonUrl}/crm/dict/save`,
    method: 'POST',
    noLoading: true,
    data,
  })
/**
 * 删除字典信息
 * @param data
 * @returns {*}
 */
export const deleteDictInfo = (data) =>
  request({
    url: `${commonUrl}/crm/dict/delete`,
    method: 'POST',
    noLoading: true,
    data,
  })

/*********         加盟商管理          **********/
/**
 * 同步 EC 最新的数据
 * @param data
 * @returns {*}
 */
export const syncLastEcCustomerList = (data) =>
  request({
    url: `${commonUrl}/crm/customer/syncEcLast`,
    method: 'POST',
    noLoading: true,
    data,
  })
/**
 * 同步指定客户EC 最新的数据
 * @param data
 * @returns {*}
 */
export const syncCustomerEcLast = (data) =>
  request({
    url: `${commonUrl}/crm/customer/syncEcCustomer?customerId=${data.customerId}`,
    method: 'GET',
    noLoading: true,
    data,
  })
/**
 * 查询加盟商列表
 * @param data
 * @returns {*}
 */
export const queryCustomerList = (data) =>
  request({
    url: `${commonUrl}/crm/customer/queryList`,
    method: 'POST',
    noLoading: true,
    data,
  })

/**
 * 新增加盟商
 * @param data
 * @returns {*}
 */
export const createCustomer = (data) =>
  request({
    url: `${commonUrl}/crm/customer/create`,
    method: 'POST',
    noLoading: true,
    data,
  })

/**
 * 查询加盟商详情
 * @param params
 * @returns {*}
 */
export const getCustomerDetail = (params) =>
  request({
    url: `${commonUrl}/crm/customer/detail`,
    method: 'GET',
    noLoading: true,
    params,
  })

/**
 * 修改客户信息
 * @param data
 * @returns {*}
 */
export const updateCustomerInfo = (data) =>
  request({
    url: `${commonUrl}/crm/customer/save`,
    method: 'POST',
    noLoading: true,
    data,
  })

/**
 * 修改客户标签
 * @param data
 * @returns {*}
 */
export const updateCustomerLabel = (data) =>
  request({
    url: `${commonUrl}/crm/customer/updateLabel`,
    method: 'POST',
    noLoading: true,
    data,
  })

/**
 * 转移客户
 * @param data
 * @returns {*}
 */
export const transferCustomer = (data) =>
  request({
    url: `${commonUrl}/crm/customer/transfer`,
    method: 'POST',
    noLoading: true,
    data,
  })

/**
 * 转为正式加盟商
 * @param data
 * @returns {*}
 */
export const formalizeCustomer = (data) =>
  request({
    url: `${commonUrl}/crm/customer/formalize`,
    method: 'POST',
    noLoading: true,
    data,
  })

/**
 * 加盟商合并
 * @param data
 * @returns {*}
 */
export const mergeCustomer = (data) =>
  request({
    url: `${commonUrl}/crm/customer/merge`,
    method: 'POST',
    noLoading: true,
    data,
  })

/*********         客户联系人          **********/

/**
 * 查询联系人列表
 * @param data
 * @returns {*}
 */
export const queryContactList = (data) =>
  request({
    url: `${commonUrl}/crm/contact/queryList`,
    method: 'POST',
    noLoading: true,
    data,
  })
/**
 * 保存联系人信息
 * @param data
 * @returns {*}
 */
export const saveContactInfo = (data) =>
  request({
    url: `${commonUrl}/crm/contact/save`,
    method: 'POST',
    noLoading: true,
    data,
  })
/**
 * 删除联系人
 * @param data
 * @returns {*}
 */
export const deleteContactInfo = (data) =>
  request({
    url: `${commonUrl}/crm/contact/delete`,
    method: 'POST',
    noLoading: true,
    data,
  })

/*********         客户跟进记录          **********/
/**
 * 查询跟进记录
 * @param data
 * @returns {*}
 */
export const queryFollowList = (data) =>
  request({
    url: `${commonUrl}/crm/follow/queryList`,
    method: 'POST',
    noLoading: true,
    data,
  })

/**
 * 保存跟进记录
 * @param data
 * @returns {*}
 */
export const saveFollowInfo = (data) =>
  request({
    url: `${commonUrl}/crm/follow/save`,
    method: 'POST',
    noLoading: true,
    data,
  })
/**
 * 删除跟进记录
 * @param data
 * @returns {*}
 */
export const deleteFollowInfo = (data) =>
  request({
    url: `${commonUrl}/crm/follow/delete`,
    method: 'POST',
    noLoading: true,
    data,
  })

/*********         通话记录          **********/
/**
 * 查询通话记录
 * @param data
 * @returns {*}
 */
export const queryCallRecordList = (data) =>
  request({
    url: `${commonUrl}/crm/callRecord/queryList`,
    method: 'POST',
    noLoading: true,
    data,
  })

/**
 * 保存通话记录
 * @param data
 * @returns {*}
 */
export const saveCallRecordInfo = (data) =>
  request({
    url: `${commonUrl}/crm/callRecord/save`,
    method: 'POST',
    noLoading: true,
    data,
  })
/**
 * 删除通话记录
 * @param data
 * @returns {*}
 */
export const deleteCallRecordInfo = (data) =>
  request({
    url: `${commonUrl}/crm/callRecord/delete`,
    method: 'POST',
    noLoading: true,
    data,
  })

/*********         派单任务          **********/
/**
 * 查询派单任务列表
 * @param data
 * @returns {*}
 */
export const queryTaskList = (data) =>
  request({
    url: `${commonUrl}/crm/task/queryList`,
    method: 'POST',
    noLoading: true,
    data,
  })

/**
 * 查询派单任务提醒数量
 * @param data
 * @returns {*}
 */
export const queryTaskBadgeCount = (data) =>
  request({
    url: `${commonUrl}/crm/task/queryBadgeCount`,
    method: 'POST',
    noLoading: true,
    data,
  })
/**
 * 查询我的派单任务列表
 * @param data
 * @returns {*}
 */
export const queryMyTaskList = (data) =>
  request({
    url: `${commonUrl}/crm/task/queryMyList`,
    method: 'POST',
    noLoading: true,
    data,
  })
/**
 * 查询任务详情
 * @param params
 * @returns {*}
 */
export const queryTaskDetail = (params) =>
  request({
    url: `${commonUrl}/crm/task/detail`,
    method: 'GET',
    noLoading: true,
    params,
  })

/**
 * 发起派单任务
 * @param data
 * @returns {*}
 */
export const createTaskAssign = (data) =>
  request({
    url: `${commonUrl}/crm/task/create`,
    method: 'POST',
    noLoading: true,
    data,
  })
/**
 * 修改派单任务信息
 * @param data
 * @returns {*}
 */
export const updateTaskInfo = (data) =>
  request({
    url: `${commonUrl}/crm/task/update`,
    method: 'POST',
    noLoading: true,
    data,
  })
/**
 * 填写派单任务反馈
 * @param data
 * @returns {*}
 */
export const submitTaskFeedbackInfo = (data) =>
  request({
    url: `${commonUrl}/crm/task/feedback`,
    method: 'POST',
    noLoading: true,
    data,
  })
/**
 * 派单任务转交
 * @param data
 * @returns {*}
 */
export const transferTaskInfo = (data) =>
  request({
    url: `${commonUrl}/crm/task/transfer`,
    method: 'POST',
    noLoading: true,
    data,
  })
/**
 * 删除任务
 * @param data
 * @returns {*}
 */
export const deleteTaskInfo = (data) =>
  request({
    url: `${commonUrl}/crm/task/delete`,
    method: 'POST',
    noLoading: true,
    data,
  })

/*********         订单信息          **********/
/**
 * 查询订单列表
 * @param data
 * @returns {*}
 */
export const queryOrderList = (data) =>
  request({
    url: `${commonUrl}/crm/order/queryList`,
    method: 'POST',
    noLoading: true,
    data,
  })
/**
 * 查询订单详情
 * @param params
 * @returns {*}
 */
export const queryOrderDetail = (params) =>
  request({
    url: `${commonUrl}/crm/order/detail`,
    method: 'GET',
    noLoading: true,
    params,
  })

/**
 * 保存订单信息（新增/修改）
 * @param data
 * @returns {*}
 */
export const saveOrderInfo = (data) =>
  request({
    url: `${commonUrl}/crm/order/save`,
    method: 'POST',
    noLoading: true,
    data,
  })
/**
 * 删除订单信息
 * @param data
 * @returns {*}
 */
export const deleteOrderInfo = (data) =>
  request({
    url: `${commonUrl}/crm/order/delete`,
    method: 'POST',
    noLoading: true,
    data,
  })

/*********         合同信息          **********/
/**
 * 查询合同信息
 * @param data
 * @returns {*}
 */
export const queryContractList = (data) =>
  request({
    url: `${commonUrl}/crm/contract/queryList`,
    method: 'POST',
    noLoading: true,
    data,
  })
/**
 * 查询合同详情
 * @param params
 * @returns {*}
 */
export const queryContractDetail = (params) =>
  request({
    url: `${commonUrl}/crm/contract/detail`,
    method: 'GET',
    noLoading: true,
    params,
  })

/**
 * 保存合同信息（新增/修改）
 * @param data
 * @returns {*}
 */
export const saveContractInfo = (data) =>
  request({
    url: `${commonUrl}/crm/contract/save`,
    method: 'POST',
    noLoading: true,
    data,
  })

/**
 * 删除合同信息
 * @param data
 * @returns {*}
 */
export const deleteContractInfo = (data) =>
  request({
    url: `${commonUrl}/crm/contract/delete`,
    method: 'POST',
    noLoading: true,
    data,
  })
