<template>
  <div class="mz-data-wrapper">
    <a-spin tip="拼命加载中..." style="width: 100%" :delay="delay" :spinning="spinning">
      <div :class="['data-content', showContent ? 'show' : 'hide']">
        <slot name="default"></slot>
      </div>
    </a-spin>
    <a-empty
      class="mz-data-empty"
      :image="imgUrl"
      :image-style="{
        height: imgHeight + 'px',
      }"
      v-show="showEmpty"
    >
      <span slot="description" class="newBtnText">{{ emptyText }}</span>
    </a-empty>
  </div>
</template>

<script>
export default {
  props: {
    hasData: {
      type: Boolean,
      default: false,
    },
    delay: {
      type: Number,
      default: 0,
    },
    spinning: {
      type: Boolean,
      default: false,
    },
    imgHeight: {
      type: Number,
      default: 80,
    },
    imgUrl: {
      type: String,
      default:
        'https://gw.alipayobjects.com/mdn/miniapp_social/afts/img/A*pevERLJC9v0AAAAAAAAAAABjAQAAAQ/original',
    },
    emptyText: {
      type: String,
      default: '暂无数据',
    },
  },
  data() {
    return {};
  },
  computed: {
    showEmpty() {
      return !this.hasData && !this.spinning;
    },
    showContent() {
      return this.hasData && !this.spinning;
    },
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.mz-data-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  position: relative;

  .newBtnText {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.65);
  }
  .data-content {
    visibility: hidden;
    &.show {
      visibility: visible;
    }
  }
  .ant-btn-primary {
    background-color: #1969f1;
    border-color: #1969f1;
    color: #fff;
    &[disabled='disabled'] {
      color: rgba(0, 0, 0, 0.25);
      background-color: #f5f5f5;
      border-color: #d9d9d9;
    }
  }
}
.mz-data-empty {
  width: 130px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
</style>
