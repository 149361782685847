import basicInfo from "./basicInfo";
import goods from "./goods";
import operation from "./operation";
import order from "./order";
import warehouse from "./warehouse";
import decoration from "./decoration";
import stored from "./stored";
import member from "./member";
import integrate from "./integrate";
import takeOut from "./takeOut";
import privacyAgreement from "./privacyAgreement";
import mall from "./mall";
import groupon from "./groupon";

export default [
  ...basicInfo,
  ...goods,
  ...operation,
  ...order,
  ...warehouse,
  ...decoration,
  ...member,
  ...stored,
  ...integrate,
  ...takeOut,
  ...privacyAgreement,
  ...mall,
  ...groupon,
];
