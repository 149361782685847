/**
 * 积分配置
 */
const integrate = () => import("@/pages/trade/integrate/integrateConfig.vue"); // 积分配置
const pointsMall = () => import("@/pages/trade/integrate/pointsMall"); // 积分商城
const addShop = () => import("@/pages/trade/integrate/pointsMall/addShop"); // 新增商品
const integralOrder = () => import("@/pages/trade/integrate/integralOrder"); // 积分订单
const OrderInfo = () => import("@/pages/trade/integrate/integralOrder/OrderInfo"); // 积分订单信息

export default [
  {
    path: "/trade/integrate/integrateConfig",
    name: "integrate",
    component: integrate,
  },
  {
    path: "/trade/integrate/pointsMall",
    name: "pointsMall",
    component: pointsMall,
  },
  {
    path: "/trade/integrate/addShop",
    name: "addShop",
    component: addShop,
  },
  {
    path: "/trade/integrate/integralOrder",
    name: "integralOrder",
    component: integralOrder,
  },
  {
    path: "/trade/integrate/OrderInfo",
    name: "OrderInfo",
    component: OrderInfo,
  },
];
