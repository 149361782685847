<template>
  <div>
    <div
      v-show="isShow"
      :class="{
        loading: modular_state == false,
        loading2: modular_state == true
      }"
    >
      <!-- <div class="sk-wave" v-if="!modular_state"> -->
      <div class="sk-wave">
        <div class="sk-rect sk-rect1"></div>
        <div class="sk-rect sk-rect2"></div>
        <div class="sk-rect sk-rect3"></div>
        <div class="sk-rect sk-rect4"></div>
        <div class="sk-rect sk-rect5"></div>
      </div>
      <!-- <div v-else>
        <div class="loading-top">
          <img
            v-if="$loading_type_id == '665da8a895e3483baaa70f4e6f9abcc1'"
            src="../../assets/icon/loading1.png"
            alt=""
          />
          <img
            v-if="$loading_type_id == '3561bd6325e3490ba0790fe2a9547457'"
            src="../../assets/icon/loading2.png"
            alt=""
          />
          <img
            v-if="$loading_type_id == '159c14cde38249bfaf93d83c8b5afd68'"
            src="../../assets/icon/loading3.png"
            alt=""
          />
          <img
            v-if="$loading_type_id == '888dac4139c24466b082cad05d429b07'"
            src="../../assets/icon/loading4.png"
            alt=""
          />
        </div>
        <div class="loading-center">
          <img src="../../assets/icon/loading-center.gif" alt="" />
          <p v-if="$loading_type_id == '665da8a895e3483baaa70f4e6f9abcc1'">
            正在打开智能分析
          </p>
          <p v-if="$loading_type_id == '3561bd6325e3490ba0790fe2a9547457'">
            正在打开客户运营
          </p>
          <p v-if="$loading_type_id == '159c14cde38249bfaf93d83c8b5afd68'">
            正在打开门店智能
          </p>
          <p v-if="$loading_type_id == '888dac4139c24466b082cad05d429b07'">
            正在打开商品智能
          </p>
        </div>
        <div class="loading-botton" style="text-align: center;">
          <img src="../../assets/icon/loading-botton.png" alt="" />
        </div>
      </div> -->
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isShow: false,
      modular_state: false
    };
  },
  methods: {
    show() {
      if (this.$loading_type) {
        this.modular_state = true;
      } else {
        this.modular_state = false;
      }
      this.isShow = true;
    },
    hide() {
      this.isShow = false;
    }
  },
  mounted() {}
};
</script>

<style scoped lang="scss">
.sk-wave {
  margin: 40px auto;
  width: 100px;
  height: 60px;
  text-align: center;
  font-size: 10px;
}
.sk-wave .sk-rect {
  background-color: #00aeff;
  height: 100%;
  margin: 0 3px;
  width: 6px;
  display: inline-block;
  -webkit-animation: sk-waveStretchDelay 1.2s infinite ease-in-out;
  animation: sk-waveStretchDelay 1.2s infinite ease-in-out;
}
.sk-wave .sk-rect1 {
  -webkit-animation-delay: -1.2s;
  animation-delay: -1.2s;
}
.sk-wave .sk-rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}
.sk-wave .sk-rect3 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}
.sk-wave .sk-rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}
.sk-wave .sk-rect5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}
@-webkit-keyframes sk-waveStretchDelay {
  0%,
  40%,
  100% {
    -webkit-transform: scaleY(0.4);
    transform: scaleY(0.4);
  }
  20% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
  }
}
@keyframes sk-waveStretchDelay {
  0%,
  40%,
  100% {
    -webkit-transform: scaleY(0.4);
    transform: scaleY(0.4);
  }
  20% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
  }
}

.loading {
  position: fixed;
  // width: 100%;
  // height: 100vh;
  // background: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  padding-top: 40vh;
  z-index: 9999;
}

.loading2 {
  position: fixed;
  width: 100%;
  height: 100vh;
  background: #fff;
  top: 0;
  left: 0;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.loading-center {
  margin: 67px 0 220px 0;
  text-align: center;
  img {
    width: 78px;
    height: 78px;
  }
  p {
    font-size: 18px;
  }
}
.loading-botton {
  img {
    width: 104px;
    height: 24px;
  }
}
.loading-top {
  img {
    width: 131px;
    height: 122px;
  }
}
</style>
