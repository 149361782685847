/*
 * 策略管理--路由配置
 */

//策略管理
const operationReportList = () => import('@/pages/operationReport/list/index.vue');
//策略详情
const operationReportDetail = () => import('@/pages/operationReport/detail/index.vue');

export default [
  // 策略管理
  {
    path: '/operationReport/list',
    name: 'operationReportList',
    component: operationReportList,
  },
  // 策略详情
  {
    path: '/operationReport/detail',
    name: 'operationReportDetail',
    component: operationReportDetail,
  },
];
