export default [
  // 数据导入-评价
  {
    path: '/digitalReview/evaluateData',
    // name: 'evaluateData',
    component: () => import('@/pages/digitalReview/evaluateDataImport/index.vue'),
    children: [
      {
        path: '',
        name: 'evaluateDataIndex',
        redirect: '/digitalReview/evaluateData/import'
      },
      {
        path: 'import',
        name: 'evaluateDataImport',
        component: () => import('@/pages/digitalReview/evaluateDataImport/import.vue'),
      },
      {
        path: 'history',
        name: 'evaluateDataHistory',
        component: () => import('@/pages/digitalReview/evaluateDataImport/history.vue'),
      }
    ]
  },
  // 数据导入-评分
  {
    path: '/digitalReview/scoreDataImport',
    name: 'dataImport',
    component: () => import('@/pages/digitalReview/scoreDataImport/index.vue'),
  },
   // 点评分数设置
   {
    path: '/digitalReview/scoreSetting',
    name: 'dataImport',
    component: () => import('@/pages/digitalReview/scoreSetting/index.vue'),
  },
   // 门店名称设置
   {
    path: '/digitalReview/storeSetting',
    name: 'dataImport',
    component: () => import('@/pages/digitalReview/storeSetting/index.vue'),
  },
];
