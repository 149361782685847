/**
 * 加盟商管理CRM
 */
export default [
  {
    path: '/crm',
    name: 'crmDefaultIndex',
    redirect: '/crm/statistic/lead',
  },
  {
    path: '/crm/statistic',
    name: 'crmStatisticIndex',
    component: () => import(/* crm */ '@/pages/crm/statistic/index'),
    children: [
      {
        path: '',
        name: 'crmStatisticIndex',
        redirect: '/crm/statistic/lead'
      },
      {
        path: 'lead',
        name: 'crmStatisticLeadConversion',
        component: () => import(/* crm */ '@/pages/crm/statistic/lead'),
      },
      {
        path: 'location',
        name: 'crmStatisticLocation',
        component: () => import(/* crm */ '@/pages/crm/statistic/location'),
      },
      {
        path: 'task',
        name: 'crmStatisticTask',
        component: () => import(/* crm */ '@/pages/crm/statistic/task'),
      },
      {
        path: 'contract',
        name: 'crmStatisticContract',
        component: () => import(/* crm */ '@/pages/crm/statistic/contract'),
      },
    ]
  },
  {
    path: '/crm/customer/intention',
    name: 'crmCustomerIntention',
    component: () => import('@/pages/crm/customer/intention.vue'),
  },
  {
    path: '/crm/customer/index',
    name: 'crmCustomerIndex',
    component: () => import('@/pages/crm/customer/index.vue'),
  },
  {
    path: '/crm/customer/detail/:id',
    name: 'crmCustomerDetail',
    component: () => import('@/pages/crm/customer/detail.vue'),
  },
  {
    path: '/crm/task/my',
    name: 'crmTaskMy',
    component: () => import('@/pages/crm/task/my.vue'),
  },
  {
    path: '/crm/task/index',
    name: 'crmTaskIndex',
    component: () => import('@/pages/crm/task/index.vue'),
  },
  {
    path: '/crm/contract/index',
    name: 'crmContractIndex',
    component: () => import('@/pages/crm/contract/index.vue'),
  },
  {
    path: '/crm/dict/index',
    name: 'crmDictIndex',
    component: () => import('@/pages/crm/dict/index.vue'),
  },
  {
    path: '/location/index',
    name: 'locationIndex',
    component: () => import('@/pages/crm/location/index.vue'),
  },
];
