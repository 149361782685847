<template>
  <div>
    <template v-if="value.display == 'table'">
      <MZDTable
        v-if="tableData"
        :tableHead="tableData.tableHead"
        :tableData="tableData.tableDatas"
        :getSpanArr_type="tableNeedsMergeCells"
        :rowclassname="value"
        :widths="value.widths"
        :pages="pages"
        :totaldata="totaldata"
        :fun_page="fun_page"
        :currentpage="currentpage"
        :pagesize="pagesize"
        :whetherZero="value.whetherZero"
        :isbfb="value.isbfb"
        :fixeds="value.fixeds"
      ></MZDTable>
    </template>

    <MZLineChart
      v-else
      :value="chartData"
      :meStr="value.timeGrain"
      :y_two="y_two_type"
    ></MZLineChart>
  </div>
</template>

<script>
import MZLineChart from "./MZLineChart_two";
import MZDTable from "./MZDTable";
import {
  reday_chart,
  ready_table_leng,
  ready_table,
  ready_table_netsales,
  ready_table_leng_netsales,
  convert,
} from "@/util/tableConversion";

export default {
  props: {
    value: {
      type: Object,
      default: () => {
        return {};
      },
    },
    //分页
    pages: {
      type: Boolean,
      default: false,
    },
    //分页数据
    totaldata: {
      type: Number,
      default: 0,
    },
    //分页函数
    fun_page: {
      type: Function,
      default: () => {
        return {};
      },
    },
    //设置条数大小
    pagesize: {
      type: Number,
      default: 20,
    },
    // 绑定当前页数   因为重新渲染可能使组件渲染 分页还原
    currentpage: {
      type: Number,
    },
    //判断是否使用循环 y轴
    y_two_type: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    value(val) {
      this.updateValue();
    },
  },
  data() {
    return {
      chartData: null,
      tableData: {},
      tableNeedsMergeCells: false,
    };
  },
  components: {
    MZLineChart,
    MZDTable,
  },
  methods: {
    updateValue() {
      const value = this.value;
      if (value.display == "table") {
        if (value.xValuesAsHeader) {
          // 翻转表格（x轴的值作为表头）
          this.tableData = value.groups
            ? ready_table_leng(value, value.timeGrain)
            : ready_table_netsales(value, value.timeGrain);
        } else {
          this.tableData = value.groups
            ? ready_table_leng(value, value.timeGrain)
            : ready_table(value, value.timeGrain);
        }

        this.tableNeedsMergeCells = !!value.groups;
      } else {
        if (value.groups) {
          if (
            value.groups &&
            value.groups.length > 0 &&
            value.groups[0].series.length > 0
          ) {
            const len = value.groups[0].series[0].data.length;
            if (len * value.groups.length == value.xValues.length) {
              // 分组显示（xValues数据重复，表示需要分组显示）
              this.chartData = value;
            } else {
              this.chartData = reday_chart(value); // 平铺
            }
          } else {
            this.chartData = reday_chart(value); // 平铺
          }
        } else {
          this.chartData = value;
        }
      }
    },
  },
  mounted() {
    this.updateValue();
  },
};
</script>
