<script>
import Loading from "./loading.vue";

export default {
  install(Vue, pluginOptions = {}) {
    const VueLoading = Vue.extend(Loading);
    let loading = null;
    function $loading() {
      return new Promise(resolve => {
        if (!loading) {
          loading = new VueLoading();
          loading.$mount();
          document
            .querySelector(pluginOptions.container || "body")
            .appendChild(loading.$el);
        }
        loading.show();
        resolve();
      });
    }
    $loading.end = () => {
      return new Promise(resolve => {
        if (!loading || !loading.isShow) {
          resolve();
          return;
        }
        loading.hide();
      });
    };
    Vue.loading = Vue.prototype.$loading = $loading;
  }
};
</script>
