// http://wiki.mzdata.top/confluence/pages/viewpage.action?pageId=20809892
import request from '@/libs/API_common.js'
import axios from 'axios';
let failDetailToken = null
//查询商品信息
export const getSkuInfo = params => request({
  url: `/coupon/api/v1/sku/skuInfo`,
  method: 'get',
  params
})
//查询门店信息
export const getStoreBaseInfo = params => request({
  url: `/coupon/api/v1/store/getStoreBaseInfo`,
  method: 'get',
  params
})
//创建策略
export const createConsumeStrategy = params => request({
  url: `/coupon/api/v1/consumeStrategy/createConsumeStrategy`,
  method: 'post',
  data: params
})
//查询策略列表
export const getStrategyList = params => request({
  url: `/coupon/api/v1/consumeStrategy/getStrategyList`,
  method: 'get',
  params
})
//查询策略详情
export const getDataByStrategyId = params => request({
  url: `/coupon/api/v1/consumeStrategy/getDataByStrategyId`,
  method: 'get',
  params
})
//更新接口
export const updateConsumeStrategy = params => request({
  url: `/coupon/api/v1/consumeStrategy/updateConsumeStrategy`,
  method: 'post',
  data: params
})
//删除
export const deleteConsumeStrategy = (params, paramsSerializer) => request({
  url: `/coupon/api/v1/consumeStrategy/deleteConsumeStrategy`,
  method: 'delete',
  params,
  paramsSerializer,
})

//更新优先级
export const updateStrategyLevel = params => request({
  url: `/coupon/api/v1/consumeStrategy/updateStrategyLevel`,
  method: 'post',
  data: params
})

// 查询优惠券
export const getCouponListByCouponIdList = params => request({
  url: `/coupon/api/v1/coupon/getCouponListByCouponIdList`,
  method: 'get',
  params
})

// 查询消费间隔可触达人数
export const  getReachNumber = params => request({
  url: '/coupon/api/v1/consumeStrategy/getReachNumber',
  method: 'post',
  data: params
})

//查看详情
export const  getStrategyDetailInfo = params => request({
  url: '/coupon/api/v1/consumeStrategy/getStrategyDetailInfo',
  method: 'get',
  params
})

//发送失败明细
export const failDetail = params => {
  if (typeof failDetailToken === "function") {
    failDetailToken("cancel");
  }
  return request({
      url: "/coupon/api/v1/consumeStrategy/getSendErrDetail",
      method: 'GET',
      params,
      noLoading: true,
      cancelToken: new axios.CancelToken((c) => {
        failDetailToken = c;
      })
  })
} 

