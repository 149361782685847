/*
 * 报表中心--路由配置
 */

// 报表中心
const dashboardCenter = () => import('@/pages/dashboardCenter/index.vue');
const dashboardCenterNew = () => import('@/pages/dashboardCenter/indexV2.vue');

export default [
  // 报表中心
  {
    path: '/dashboardCenter',
    name: 'dashboardCenter',
    component: dashboardCenter,
  },
  // 报表中心
  {
    path: '/dashboardCenterNew',
    name: 'dashboardCenterNew',
    component: dashboardCenterNew,
  },
];
