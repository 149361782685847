import store from '../store';
import { getStoreBuildTodoCount, getNodeList } from '@/api/workflow/index';
import { queryTaskBadgeCount } from '@/api/crm';

export const getStoreBuildTodoNum = async () => {
  const { data = 0 } = await getStoreBuildTodoCount();
  store.commit('setStoreBuildTodoCount', data);
};

export const getAbnormalNodeCount = async () => {
  const result = await getNodeList({
    isError: 2,
    pageNo: 1,
    pageSize: 999,
    suggest: '',
  });
  const data = result.data.total;
  console.log('setAbnormalNodeCount', data)
  store.commit('setAbnormalNodeCount', data);
};

export const getCrmTaskAssignNum = async () => {
  const { data } = await queryTaskBadgeCount();
  store.commit('setCrmTaskCount', data?.result || 0);
};
