<!-- 面包屑 -->
<template>
  <div id="mz-antd-bread-wrap">
    <a-breadcrumb :routes="routes">
      <template slot="itemRender" slot-scope="{ route, params, routes, paths }">
        <!-- <span v-if="routes.indexOf(route) === routes.length - 1" class="route-name"> -->
        <span v-if="routes.indexOf(route) === routes.length - 1" class="route-name">
          {{ route.name }}
        </span>
        <!-- paths.join('/') -->
        <router-link v-else :to="route.path" class="route-name">
          {{ route.name }}
        </router-link>
      </template>
    </a-breadcrumb>
    <slot name="rightRender"></slot>
  </div>
</template>

<script>
export default {
  props: {
    routes: {
      type: Array,
      default() {
        return [{ path: '', name: '' }];
      },
    },
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
#mz-antd-bread-wrap {
  padding: 16px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: PingFangSC;

  /deep/ .ant-breadcrumb {
    a.route-name {
      color: #6B7381;
      font-size: 14px;
      font-weight: 400;
      cursor: pointer;
    }
    .ant-breadcrumb-separator {
      color: #6B7381;
    }
    & > span:last-child {
      font-weight: 500;
      color: #212D42;
    }
  }
}
</style>
