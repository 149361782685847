// import component
import dynamicTable from "./dynamic-table/index";

// define class
class Components {
    constructor(data) {
        this.data = data;
    }
    install(Vue) {
        this.data.forEach((item) => {
            Vue.component(item.name, item);
        });
    }
}

// export instance
export default new Components([
    dynamicTable,
]);
