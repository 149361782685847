import request from '@/libs/API.js'
import couponRequest from '@/libs/API_v3.js'
// 获取门店列表
// export const getStoreTree = () => request({
//   url: `/authCenter/store/list`,
//   method: 'get',
// })

export const getStoreTree = () => request({
  //避免全局loading...
  url: `/store/getStoreTree`,
  method: 'get'
})
// 指标列表
export const getStoreMeasureList = query => request({
  url: `store/getIndexList`,
  method: 'post',
  data: query
})
// 订单类型/业务类型 {order_class:1}
export const getOrderMetaInfo = query => request({
  url: `order/getOrderMetaInfo`,
  method: 'post',
  data: query
})
// 获取用户人群列表
export const getUserGroupMetaInfo = () => request({
  url: `usertag/getUserGroupMetaInfo`,
  method: 'get',
})

// 获取品类树
export const getCommodityTree = () => request({
  url: `commodityAnalysis/commodityTree`,
  method: 'get',
})

export const getCommodityTree_two = (params) => request({
  url: `/commodityAnalysis/v2/commodityCateTree`,
  method: 'get',
  params: params
})





// 懒加载 品类
export const commodityCateLevel = (params) => request({
  url: `/commodityAnalysis/commodityCateLevel`,
  method: 'get',
  params: params
})


// 当前用户信息
export const switchTenant = (data) => request({
  url: '/auth/switchTenant',
  method: 'GET',
  params: data,
  baseURL: '/dataone-api',
})


//岗位树
export const getPostTree = data => request({
  url: `/store/getPostTree`,
  method: 'get',
  params: data
})

// 菜品树 
export const dishList = data => request({
  url: `/store/dishList`,
  method: 'get',
  params: data
})

export const getPopulationTree = data => request({
  url: `/userPackage/getPopulationTree`,
  method: 'get',
  params: data
})
// 标签树 
export const labelList = data => couponRequest({
  url: `/userTag/getUserTagTree`,
  method: 'get',
  params: data
})

// 商品树标签列表
export const newlabelList = params => request({
  url: `/common/label/list/goods`,
  method: 'get',
  params
})

// 新的商品树
export const newgoodslist = params => request({
  url: `/common/goods/tree`,
  method: 'post',
  data: params
})

