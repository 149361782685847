/****************************************************************
 * 加盟开店
 ****************************************************************/
/**
 * Banner
 */
const franchiseeBanners = () => import(/* franchiseeBanners */ '../../pages/franchisee/banners/index');
const franchiseeBannersForm = () => import(/* franchiseeBannersForm */ '../../pages/franchisee/banners/form.vue');

/**
 * 创业项目
 */
const franchiseeProject = () => import(/* franchiseeProject */ '../../pages/franchisee/project/index');
const franchiseeProjectForm = () => import(/* franchiseeProjectForm */ '../../pages/franchisee/project/form.vue');

/**
 * 培训内容
 */
const franchiseeTraining = () => import(/* franchiseeTraining */ '../../pages/franchisee/training/index');
const franchiseeTrainingForm = () => import(/* franchiseeTrainingForm */ '../../pages/franchisee/training/form.vue');

export default [
	{
		path: '/franchiseeBanners/',
		name: 'franchiseeBanners',
		component: franchiseeBanners
	},
	{
		path: '/franchiseeBanners/edit/',
		name: 'franchiseeBannersForm',
		component: franchiseeBannersForm
	},

	{
		path: '/franchiseeProject/',
		name: 'franchiseeProject',
		component: franchiseeProject
	},
	{
		path: '/franchiseeProject/edit/',
		name: 'franchiseeProjectForm',
		component: franchiseeProjectForm
	},

	{
		path: '/franchiseeTraining/marketing',
		name: 'franchiseeTraining',
		component: franchiseeTraining
	},
	{
		path: '/franchiseeTraining/marketing/edit/',
		name: 'franchiseeTrainingForm',
		component: franchiseeTrainingForm
	},
	{
		path: '/franchiseeTraining/product',
		name: 'franchiseeTrainingProduct',
		component: franchiseeTraining
	},
	{
		path: '/franchiseeTraining/product/edit/',
		name: 'franchiseeTrainingProductForm',
		component: franchiseeTrainingForm
	},
	{
		path: '/franchiseeTraining/opening',
		name: 'franchiseeTrainingOpening',
		component: franchiseeTraining
	},
	{
		path: '/franchiseeTraining/opening/edit/',
		name: 'franchiseeTrainingOpeningForm',
		component: franchiseeTrainingForm
	},
];