/**
 * 运费模板
 */
const freightAdd = () => import('@/pages/trade/mall/freight/freightAdd.vue'); // 运费模板添加
const freightList = () => import('@/pages/trade/mall/freight/freightList.vue'); // 运费模板列表
const orderDetail = () => import('@/pages/trade/mall/order/orderDetail/index.vue'); // 运费模板列表
export default [
    {
        path: '/trade/mall/freight/add',
        name: 'freightAdd',
        component: freightAdd
    },
    {
        path: '/trade/mall/freight/freightList',
        name: 'freightList',
        component: freightList
    },    
	{
        path: '/trade/mall/order/detail',
        name: 'orderDetail',
        component: orderDetail
    },
]
