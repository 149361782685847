/*
 * 智能订货2.0--路由配置
 */
//基础信息--门店信息
const smartBaseStore = () => import('@/pages/storeSmart/baseInfo/storeInfo/list.vue');
//基础信息--商品信息
const smartBaseGoods = () => import('@/pages/storeSmart/baseInfo/goodsInfo/list.vue');
const smartBaseGoodsEdit = () => import('@/pages/storeSmart/baseInfo/goodsInfo/edit.vue');
const smartBaseStoreEdit = () => import('@/pages/storeSmart/baseInfo/storeInfo/edit.vue');
//订货模板
const smartOrderTemplate = () => import('@/pages/storeSmart/orderManage/orderTemplate/list.vue');
// const smartOrderTemplateEdit = () =>
//   import("@/pages/storeSmart/orderManage/orderTemplate/editGood/edit.vue");
const smartOrderTemplateEdit = () =>
  import('@/pages/storeSmart/orderManage/orderTemplate/steps/index.vue');
const smartOrderAllocateStores = () =>
  import('@/pages/storeSmart/orderManage/orderTemplate/allocateStores/index.vue');
//订货日历
const smartOrderCalendar = () =>
  import('@/pages/storeSmart/orderManage/orderCalendar/orderV1/calendarList.vue');
//订货单
const smartOrderList = () =>
  import('@/pages/storeSmart/orderManage/orderCalendar/orderV1/orderList.vue');
//订货审批
const smartOrderApprList = () => import('@/pages/storeSmart/orderManage/approvalFlow/list.vue');
const smartOrderApprDetail = () => import('@/pages/storeSmart/orderManage/approvalFlow/detail.vue');

//终端配置
const smartOrderSetMetric = () => import('@/pages/storeSmart/config/setMetric.vue');
//商品配置
const smartSalesGoods = () => import('@/pages/storeSmart/salesManage/goodsConfig/list.vue');
//订货商品
const smartOrderGoods = () => import('@/pages/storeSmart/salesManage/orderGoods/list.vue');
//门店分组
const smartSalesStore = () => import('@/pages/storeSmart/salesManage/storeGroup/list.vue');
//巡检策略门店分组（临时分组，下一版会改成按范围选择门店）
const inspecSalesStore = () => import('@/pages/commodityIntelligence/inspect/storeGroup/list.vue');

//选品策略
const selectStrategy = () => import('@/pages/storeSmart/selectStrategy/list.vue');
const selectStrategyAbc = () => import('@/pages/storeSmart/selectStrategy/editAbc.vue');
const selectStrategyProduct = () => import('@/pages/storeSmart/selectStrategy/editProduct.vue');

//单品策略
const strategyBoard = () => import('@/pages/storeSmart/promotionStrategy/board.vue');
const promotionStrategy = () => import('@/pages/storeSmart/promotionStrategy/list.vue');
const promotionStrategyEdit = () => import('@/pages/storeSmart/promotionStrategy/edit.vue');
const promotionStrategyDetail = () =>
  import('@/pages/storeSmart/promotionStrategy/strategyDetail.vue');

//订货分析
//总部看板
const bossBoard = () => import('@/pages/storeSmart/orderAnalysis/bossBoard/index.vue');
//督导看板
const leaderBoard = () => import('@/pages/storeSmart/orderAnalysis/leaderBoard/index.vue');
//不达标分析
const nonConformance = () => import('@/pages/storeSmart/orderAnalysis/nonConformance/index.vue');
//报表下载
const reportAnalysisDownload = () =>
  import('@/pages/storeSmart/orderAnalysis/reportDownload/index.vue');
//品类偏差
const deviationCategoryAnalysis = () =>
  import('@/pages/storeSmart/orderAnalysis/deviation/category.vue');
//门店偏差
const deviationStoreAnalysis = () => import('@/pages/storeSmart/orderAnalysis/deviation/store.vue');
//商品偏差
const deviationSkuAnalysis = () => import('@/pages/storeSmart/orderAnalysis/deviation/sku.vue');

//订货单列表
const orderList = () => import('@/pages/storeSmart/orderManage/shopOrder/index.vue');
//订货单编辑
const orderListEdit = () => import('@/pages/storeSmart/orderManage/shopOrder/edit.vue');
// //订单详情
const orderDetail = () => import('@/pages/storeSmart/orderManage/shopOrder/detail.vue');
//订货出货单列表
const outboundOrderList = () => import('@/pages/storeSmart/orderManage/outboundOrder/index.vue');
//供应链报表 - BItab展示
const scBIReportList = () => import('@/pages/storeSmart/orderAnalysis/report/scBIReportList.vue');
//门店报表 - BItab展示
const storeBIReportList = () => import('@/pages/storeSmart/orderAnalysis/report/storeBIReportList.vue');
//事业部报表 - BItab展示
const deptBIReportList = () => import('@/pages/storeSmart/orderAnalysis/report/deptBIReportList.vue');

export default [
  {
    path: '/storeSmart/baseStore',
    name: 'smartBaseStore',
    component: smartBaseStore,
  },
  {
    path: '/storeSmart/baseStore/list',
    name: 'smartBaseStore',
    component: smartBaseStore,
  },
  {
    path: '/storeSmart/baseStore/edit',
    name: 'smartBaseStoreEdit',
    component: smartBaseStoreEdit,
  },
  {
    path: '/storeSmart/baseGoods/list',
    name: 'smartBaseGoods',
    component: smartBaseGoods,
  },
  {
    path: '/storeSmart/baseGoods/edit',
    name: 'smartBaseGoodsEdit',
    component: smartBaseGoodsEdit,
  },
  {
    path: '/storeSmart/orderTemplate/list',
    name: 'smartOrderTemplate',
    component: smartOrderTemplate,
  },
  {
    path: '/storeSmart/orderTemplate/edit',
    name: 'smartOrderTemplateEdit',
    component: smartOrderTemplateEdit,
  },
  {
    path: '/storeSmart/orderTemplate/allocateStores',
    name: 'smartOrderAllocateStores',
    component: smartOrderAllocateStores,
  },
  {
    path: '/storeSmart/orderCalendar',
    name: 'smartOrderCalendar',
    component: smartOrderCalendar,
  },
  {
    path: '/storeSmart/salesManage/goods',
    name: 'smartSalesGoods',
    component: smartSalesGoods,
  },
  {
    path: '/storeSmart/salesManage/orderGoods',
    name: 'smartOrderGoods',
    component: smartOrderGoods,
  },
  {
    path: '/storeSmart/salesManage/store',
    name: 'smartSalesStore',
    component: smartSalesStore,
  },
  {
    path: '/inspectManage/store',
    name: 'inspecSalesStore',
    component: inspecSalesStore,
  },
  {
    path: '/storeSmart/smartOrderList',
    name: 'smartOrderList',
    component: smartOrderList,
  },
  {
    path: '/storeSmart/smartOrderApprList',
    name: 'smartOrderApprList',
    component: smartOrderApprList,
  },
  {
    path: '/storeSmart/smartOrderApprDetail',
    name: 'smartOrderApprDetail',
    component: smartOrderApprDetail,
  },
  {
    path: '/storeSmart/setMetric',
    name: 'smartOrderSetMetric',
    component: smartOrderSetMetric,
  },
  {
    path: '/storeSmart/selectStrategy/list',
    name: 'selectStrategy',
    component: selectStrategy,
  },
  {
    path: '/storeSmart/selectStrategy/editAbc',
    name: 'selectStrategyAbc',
    component: selectStrategyAbc,
  },
  {
    path: '/storeSmart/selectStrategy/editProduct',
    name: 'selectStrategyProduct',
    component: selectStrategyProduct,
  },
  {
    path: '/storeSmart/promotionStrategy/board',
    name: 'strategyBoard',
    component: strategyBoard,
  },
  {
    path: '/storeSmart/promotionStrategy/list',
    name: 'promotionStrategy',
    component: promotionStrategy,
  },
  {
    path: '/storeSmart/promotionStrategy/edit',
    name: 'promotionStrategyEdit',
    component: promotionStrategyEdit,
  },
  {
    path: '/storeSmart/promotionStrategy/strategyDetail',
    name: 'promotionStrategyDetail',
    component: promotionStrategyDetail,
  },

  {
    path: '/storeSmart/bossBoard',
    name: 'bossBoard',
    component: bossBoard,
  },
  {
    path: '/storeSmart/leaderBoard',
    name: 'leaderBoard',
    component: leaderBoard,
  },
  {
    path: '/storeSmart/nonConformance',
    name: 'nonConformance',
    component: nonConformance,
  },
  {
    path: '/storeSmart/reportAnalysis',
    name: 'reportAnalysisDownload',
    component: reportAnalysisDownload,
  },
  {
    path: '/storeSmart/deviation/category',
    name: 'deviationCategoryAnalysis',
    component: deviationCategoryAnalysis,
  },
  {
    path: '/storeSmart/deviation/store',
    name: 'deviationStoreAnalysis',
    component: deviationStoreAnalysis,
  },
  {
    path: '/storeSmart/deviation/sku',
    name: 'deviationSkuAnalysis',
    component: deviationSkuAnalysis,
  },
  {
    path: '/storeSmart/orderManager/orderList',
    name: 'orderList',
    component: orderList,
  },
  {
    path: '/storeSmart/orderManager/orderListEdit',
    name: 'orderListEdit',
    component: orderListEdit,
  },
  {
    path: '/storeSmart/orderManager/outboundOrderList',
    name: 'outboundOrderList',
    component: outboundOrderList,
  },
  {
    path: '/storeSmart/orderManager/orderDetail',
    name: 'orderDetail',
    component: orderDetail,
  },
  {
    path: '/storeSmart/scBIReportList',
    name: 'scBIReportList',
    component: scBIReportList,
  },
  {
    path: '/storeSmart/storeBIReportList',
    name: 'storeBIReportList',
    component: storeBIReportList,
  },
  {
    path: '/storeSmart/deptBIReportList',
    name: 'deptBIReportList',
    component: deptBIReportList,
  },
];
