// 明之BI v2  --- 之后可能会迁移至新系统
import BIV2 from './BI-v2/index';

// pos收银模块
import posOrder from './pos/index';
// 门店智能模块
import storeIntelRouters from './storeIntelligent/index';
//商城模块
import shop from './shop/index';
import cms from './cms/index';
import setting from './setting';
//后期客户运营
import customerOperation from './customerOperation/index';

//客户智能-全量客户
import allCustomers from './allCustomers/index';
//门店智能
import storeAi from './storeAi/index';
//智能分析
import storeAnalysis from './analysis/index';

//智能订货2.0
import storeSmart from './storeSmart/index';
//智能巡店
import patrolTask from './patrolTask/index';
//商品智能
import commodityIntelligence from './commodityIntelligence/index';
//小程序相关
import wxapp from './wxapp/index';
// 会员小程序
import memberWeapp from './memberWeapp/index';

// trade by bkm
import trade from './trade/index';

import microStore from './micro-store/index';
import dataMiddlePlatform from './dataMiddlePlatform/index';

// 明学院模块
import mzSchool from './mzSchool';
import { MzLayout } from '@/layouts';

// 带训管理模块
import leadTraining from './leadTraining';
// 策略管理模块
import eventManagement from './eventManagement/index';
// 经营报表管理模块
import operationReport from './operationReport/index';
// 数据集成
import dataIntegration from './dataIntegration/index';
// 工作流模块
import workflow from './workflow';
import dashboardCenter from './dashboardCenter';
// 外部人员管理
import externalPersonnelManagement from './externalPersonnelManagement';
// 数字点评
import digitalReview from './digitalReview';
// 表管理模块
import entityManagement from './entityManagement/index';
// 数据概览
import dataOverview from './dataOverview/index';
import profileManagement from './profile/index';
import crmManagement from './crm/index';
// const Layout = () => import(/* webpackChunkName: "layout" */ "@/layouts");

const componentsExample = () =>
  import(/* webpackChunkName: "componentsExample" */ '../pages/example');

const NotFound = () => import(/* webpackChunkName: "404" */ '../pages/404');
// 实时数据
const Overview = () => import(/* webpackChunkName: "Overview" */ '../pages/overview');

const Templates = () =>
  import(
    /* webpackChunkName: "cusromerOverView" */
    '@/pages/templates'
  );
const TemplatesXCXPC = () =>
  import(
    /* webpackChunkName: "cusromerOverView" */
    '@/pages/templatesXCXPC'
  );

const ShortMessage = () =>
  import(
    /* webpackChunkName: "shortMessageView" */
    '@/pages/shortMessage'
  );
// 同步门店管理
const Storemanagement = () =>
  import(
    /* webpackChunkName: "periodAnalysis" */
    '@/pages/BI-v2/settings/storeManage/index'
  );

//曼哈顿计划页面

//商品同步
const commoditySynchronization = () =>
  import(/* webpackChunkName: "couponSearch" */ '../pages/Manhattan/commoditySynchronization');

// 订单管理
const orderManagement = () =>
  import(/* webpackChunkName: "couponSearch" */ '../pages/Manhattan/orderManagement');

// 合伙人分佣
const partnerSubCommission = () =>
  import(/* webpackChunkName: "couponSearch" */ '../pages/Manhattan/partnerSubCommission');

const manhattanStepFrom = () =>
  import(/* webpackChunkName: "couponStepFrom" */ '../pages/Manhattan/stepForm');

// 开团管理
const regimentManagement = () =>
  import(/* webpackChunkName: "couponSearch" */ '../pages/Manhattan/regimentManagement');

//门店开通
const storeOpening = () =>
  import(/* webpackChunkName: "couponSearch" */ '../pages/Manhattan/storeOpening');

//合伙人招募
const partnerRecruitment = () =>
  import(/* webpackChunkName: "couponSearch" */ '../pages/Manhattan/partnerRecruitment');

//合伙人审核
const partnerReview = () =>
  import(/* webpackChunkName: "couponSearch" */ '../pages/Manhattan/partnerReview');

//品牌团餐
const brandGroupMeals = () =>
  import(/* webpackChunkName: "couponSearch" */ '../pages/Manhattan/brandGroupMeals');

//企业团餐
const enterpriseGroup = () =>
  import(
    /* webpackChunkName: "enterpriseGroup" */ '../pages/Manhattan/enterpriseGroup/enterpriseGroup'
  );
//新增人群
const enterpriseGroupAdd = () =>
  import(
    /* webpackChunkName: "enterpriseGroupAdd" */ '../pages/Manhattan/enterpriseGroup/enterpriseGroupAdd'
  );

//优惠券管理
const CouponSearch = () => import(/* webpackChunkName: "couponSearch" */ '../pages/coupon/search');
const CouponStepFrom = () =>
  import(/* webpackChunkName: "couponStepFrom" */ '../pages/coupon/stepForm');
const CouponThirdCode = () =>
  import(/* webpackChunkName: "CouponThirdCode" */ '../pages/coupon/thirdCouponCodes');

const CouponPackageSearch = () =>
  import(/* webpackChunkName: "couponSearch" */ '../pages/couponPackage/search');
const CouponPackageFrom = () =>
  import(/* webpackChunkName: "CouponPackageFrom" */ '../pages/couponPackage/form');
const CouponPackageSearchLJ = () =>
  import(/* webpackChunkName: "couponSearch" */ '../pages/couponPackage/searchLJ');
const CouponPackageFromLJ = () =>
  import(/* webpackChunkName: "CouponPackageFrom" */ '../pages/couponPackage/formLJ');
const CouponPackageView = () =>
  import(/* webpackChunkName: "CouponPackageFrom" */ '../pages/couponPackage/view');

const CouponNotice = () => import(/* webpackChunkName: "CouponNotice" */ '../pages/couponNotice/');
const CouponNoticeAdd = () =>
  import(/* webpackChunkName: "CouponNotice" */ '../pages/couponNotice/add');

const H5ActivitySearch = () =>
  import(/* webpackChunkName: "h5ActivitySearch" */ '../pages/h5activity/search');
const H5ActivityStepForm = () =>
  import(/* webpackChunkName: "h5ActivityStepForm" */ '../pages/h5activity/stepForm');

//客户运营
const inviteCourtesy = () =>
  import(
    /* webpackChunkName: "inviteCourtesy" */ '../pages/customerOperation/inviteCourtesy/index'
  );
const inviteCourtesyAdd = () =>
  import(
    /* webpackChunkName: "inviteCourtesyAdd" */ '../pages/customerOperation/inviteCourtesy/form'
  );
//列表页banner区
//社区团餐
const communityBanner = () =>
  import(
    /* webpackChunkName: "communityBanner" */ '../pages/Manhattan/banner/community/bannerList'
  );
const communityBannerAdd = () =>
  import(
    /* webpackChunkName: "communityBannerAdd" */ '../pages/Manhattan/banner/community/bannerAdd'
  );

//品牌团餐
const brandBanner = () =>
  import(/* webpackChunkName: "communityBanner" */ '../pages/Manhattan/banner/brand/bannerList');
const brandBannerAdd = () =>
  import(/* webpackChunkName: "communityBannerAdd" */ '../pages/Manhattan/banner/brand/bannerAdd');

//企业团餐
const enterpriseBanner = () =>
  import(
    /* webpackChunkName: "communityBanner" */ '../pages/Manhattan/banner/enterprise/bannerList'
  );
const enterpriseBannerAdd = () =>
  import(
    /* webpackChunkName: "communityBannerAdd" */ '../pages/Manhattan/banner/enterprise/bannerAdd'
  );

//餐饮订货管理相关
//餐饮菜品管理列表页
const dishManageList = () => import('@/pages/dish/manage/list');
//餐饮菜品管理详情页
const dishManageDetail = () => import('@/pages/dish/manage/detail');
//餐饮订货管理
const dishOrderManage = () => import('@/pages/dish/order/index');
//包材物料管理
const packageManageDetail = () => import('@/pages/dish/manage/packageDetail');
let routers = [
  // {
  //     path: "/login",
  //     name: "login",
  //     component: index
  // },
  {
    path: '/',
    redirect: '/overview',
  },
  {
    path: '/',
    component: MzLayout,
    children: [
      {
        path: '/partnerRecruitment',
        name: 'partnerRecruitment',
        component: partnerRecruitment,
      },
      {
        path: '/partnerReview',
        name: 'partnerReview',
        component: partnerReview,
      },
      {
        path: '/brandGroupMeals',
        name: 'brandGroupMeals',
        component: brandGroupMeals,
      },

      {
        path: '/storeOpening',
        name: 'storeOpening',
        component: storeOpening,
      },
      {
        path: '/regimentManagement',
        name: 'regimentManagement',
        component: regimentManagement,
      },

      {
        path: '/partnerSubCommission/manhattanStepFrom/:id/:type',
        name: 'manhattanStepFrom',
        component: manhattanStepFrom,
      },

      {
        path: '/partnerSubCommission',
        name: 'partnerSubCommission',
        component: partnerSubCommission,
      },
      {
        path: '/orderManagement',
        name: 'orderManagement',
        component: orderManagement,
      },
      {
        path: '/commoditySynchronization',
        name: 'commoditySynchronization',
        component: commoditySynchronization,
      },
      {
        path: '/Storemanagement',
        name: 'Storemanagement',
        component: Storemanagement,
      },
      {
        path: '/templates',
        name: 'templates',
        component: Templates,
      },
      {
        path: '/couponSearch',
        name: 'CouponSearch',
        component: CouponSearch,
      },
      {
        path: '/business',
        name: 'business',
        component: NotFound,
      },
      {
        path: '/overview',
        name: 'overview',
        component: Overview,
      },
      {
        path: '/shortMessage',
        name: 'shortMessage',
        component: ShortMessage,
      },
      //优惠券后台
      {
        //优惠券管理
        path: 'CouponSearch',
        name: 'CouponSearch',
        component: CouponSearch,
      },
      {
        path: 'couponSearch/CouponStepFrom',
        name: 'CouponStepFrom',
        component: CouponStepFrom,
      },
      {
        path: 'couponSearch/CouponStepFromEdit/:id',
        name: 'CouponStepFromEdit',
        component: CouponStepFrom,
      },
      {
        path: 'couponSearch/CouponStepFromUpdate/:uid',
        name: 'CouponStepFromUpdate',
        component: CouponStepFrom,
      },
      //查看券
      {
        path: 'couponSearch/thirdCode/:couponId',
        name: 'CouponThirdCode',
        component: CouponThirdCode,
      },
      {
        path: 'CouponPackageSearch',
        name: 'CouponPackageSearch',
        component: CouponPackageSearch,
      },
      {
        path: 'CouponPackageSearch/CouponPackageFrom',
        name: 'CouponPackageFrom',
        component: CouponPackageFrom,
      },
      {
        // 查看
        path: '/CouponPackageSearch/CouponPackageView/:id',
        name: 'couponPackageView',
        component: CouponPackageView,
      },
      {
        //编辑
        path: '/CouponPackageSearch/couponPackageEdit/:id',
        name: 'couponPackageEdit',
        component: CouponPackageFrom,
      },

      //邻几用
      {
        path: 'CouponPackageSearchLJ',
        name: 'CouponPackageSearchLJ',
        component: CouponPackageSearchLJ,
      },
      {
        path: 'CouponPackageSearch/CouponPackageFromLJ',
        name: 'CouponPackageFromLJ',
        component: CouponPackageFromLJ,
      },
      {
        //编辑
        path: '/CouponPackageSearch/couponPackageEditLJ/:id',
        name: 'couponPackageEditLJ',
        component: CouponPackageFromLJ,
      },
      // CouponNotice
      {
        //编辑
        path: '/CouponNotice',
        name: 'CouponNotice',
        component: CouponNotice,
      },
      {
        path: '/CouponNotice/CouponNoticeDetail',
        name: 'couponNotice',
        component: CouponNoticeAdd,
      },
      {
        //编辑
        path: '/CouponNotice/CouponNoticeDetailEdit/:id',
        name: 'CouponNoticeDetailEdit',
        component: CouponNoticeAdd,
      },
      {
        //编辑
        path: '/CouponNotice/CouponNoticeDetailCheck/:id',
        name: 'CouponNoticeDetailCheck',
        component: CouponNoticeAdd,
      },

      // {
      //   path: "h5ActivitySearch",
      //   name: "h5ActivitySearch",
      //   component: H5ActivitySearch
      // },
      {
        path: '/h5Utils',
        name: 'h5ActivitySearch',
        component: H5ActivitySearch,
      },
      {
        path: 'h5ActivitySearch/h5ActivityNew',
        name: 'h5ActivityNew',
        component: H5ActivityStepForm,
      },

      {
        path: 'h5ActivitySearch/h5ActivityEdit/:id',
        name: 'h5ActivityEdit',
        component: H5ActivityStepForm,
      },
      {
        path: '/shortMessage',
        name: 'shortMessage',
        component: ShortMessage,
      },
      //客户运营

      //邀请有礼
      {
        path: 'inviteCourtesy',
        name: 'inviteCourtesy',
        component: inviteCourtesy,
      },
      //邀请有礼新增
      {
        path: 'inviteCourtesy/inviteCourtesyAdd',
        name: 'inviteCourtesyAdd',
        component: inviteCourtesyAdd,
      },
      //邀请有礼编辑
      {
        path: 'inviteCourtesy/inviteCourtesyEdit/:uid',
        name: 'inviteCourtesyEdit',
        component: inviteCourtesyAdd,
      },
      //邀请有礼查看
      {
        path: 'inviteCourtesy/inviteCourtesyCat/:id',
        name: 'inviteCourtesyCat',
        component: inviteCourtesyAdd,
      },
      //社区团餐banner
      {
        path: 'Manhattan',
        name: 'communityBanner',
        component: communityBanner,
      },
      {
        path: 'Manhattan/communityBanner',
        name: 'communityBanner',
        component: communityBanner,
      },
      //社区团餐banner新增
      {
        path: 'Manhattan/communityBanner/communityBannerAdd',
        name: 'communityBannerAdd',
        component: communityBannerAdd,
      },
      //社区团餐banner编辑
      {
        path: 'Manhattan/communityBanner/communityBannerEdit/:uid',
        name: 'communityBannerAdd',
        component: communityBannerAdd,
      },
      //品牌团餐banner
      {
        path: 'Manhattan/brandBanner',
        name: 'brandBanner',
        component: brandBanner,
      },
      //品牌团餐banner新增
      {
        path: 'Manhattan/brandBanner/brandBannerAdd',
        name: 'brandBannerAdd',
        component: brandBannerAdd,
      },
      //品牌团餐banner编辑
      {
        path: 'Manhattan/brandBanner/brandBannerEdit/:uid',
        name: 'brandBannerAdd',
        component: brandBannerAdd,
      },
      //企业团餐banner
      {
        path: 'Manhattan/enterpriseBanner',
        name: 'enterpriseBanner',
        component: enterpriseBanner,
      },
      //企业团餐banner新增
      {
        path: 'Manhattan/enterpriseBanner/enterpriseBannerAdd',
        name: 'enterpriseBannerAdd',
        component: enterpriseBannerAdd,
      },
      //企业团餐banner编辑
      {
        path: 'Manhattan/enterpriseBanner/enterpriseBannerEdit/:uid',
        name: 'enterpriseBannerAdd',
        component: enterpriseBannerAdd,
      },

      //企业团餐

      // 人群管理
      {
        path: 'enterpriseGroup',
        name: 'enterpriseGroup',
        component: enterpriseGroup,
      },
      //新增
      {
        path: 'enterpriseGroup/enterpriseGroupAdd',
        name: 'enterpriseGroupAdd',
        component: enterpriseGroupAdd,
      },
      {
        //人群管理编辑
        path: '/enterpriseGroup/enterpriseGroupEdit/:uid',
        name: 'enterpriseGroupUpdate',
        component: enterpriseGroupAdd,
      },
      {
        path: '/iframe/:id',
        name: 'iframe',
        component: TemplatesXCXPC, //iframe
      },
      //餐饮订货管理相关
      //餐饮菜品管理列表页
      {
        path: '/dishManageList',
        name: 'dishManageList',
        component: dishManageList,
      },
      //餐饮菜品管理详情页
      {
        path: '/dishManageDetail',
        name: 'dishManageDetail',
        component: dishManageDetail,
      },
      //餐饮订货管理
      {
        path: '/dishOrderManage',
        name: 'dishOrderManage',
        component: dishOrderManage,
      },
      //包材物料管理
      {
        path: 'packageManageDetail',
        name: 'packageManageDetail',
        component: packageManageDetail,
      },
      // 组件使用例子
      {
        path: '/componentsExample',
        name: 'componentsExample',
        component: componentsExample,
      },
      //客户智能-全量客户
      allCustomers,
      //门店智能
      ...storeAi,
      //智能分析
      ...storeAnalysis,
    ],
  },
];

const other = [
  BIV2,
  storeIntelRouters,
  shop,
  cms,
  customerOperation,
  setting,
  posOrder,
  microStore,
  dataMiddlePlatform,
  mzSchool,
  leadTraining,
  workflow,
  wxapp,
  storeSmart,
  patrolTask,
  commodityIntelligence,
  memberWeapp,
  trade,
  eventManagement,
  operationReport,
  dataIntegration,
  dashboardCenter,
  externalPersonnelManagement,
  digitalReview,
  entityManagement,
  dataOverview,
  profileManagement,
  crmManagement,
];
// 拼接其它模块路由
// let otherList = [];
// otherList = otherList.concat(storeIntelRouters);
// otherList = otherList.concat(shop, cms, customerOperation, setting, posOrder, );
routers[1].children = routers[1].children.concat(...other);

export default [
  ...routers,
  {
    path: '*',
    name: '404',
    component: NotFound,
  },
];
