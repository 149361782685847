
// 客户运营
/**
 * 用户分群召回
 */
const deepSleepUserRecall = () => import(/* webpackChunkName: "deepSleepUserRecall" */ '@/pages/customerOperation/automaticPolicy/deepSleepUserRecall/deepSleepUserRecall.vue');
const deepSleepUserRecallAdd = () => import(/* webpackChunkName: "deepSleepUserRecallAdd" */ '@/pages/customerOperation/automaticPolicy/deepSleepUserRecall/deepSleepUserRecallAdd.vue');

/**
 * 小程序PUSH
 */
const miniPUSH = () => import(/* webpackChunkName: "deepSleepUserRecall" */ '@/pages/customerOperation/miniPUSH/miniPUSH.vue')
const miniPUSHAdd = () => import(/* webpackChunkName: "deepSleepUserRecall" */ '@/pages/customerOperation/miniPUSH/miniPUSHAdd.vue')

/**
 * 用户画像
 */
const personas = () => import(/* webpackChunkName: "personas" */ '@/pages/customerOperation/personas/personas.vue')
const personasView = () => import (/* webpackChunkName: "personasView" */ '@/pages/customerOperation/personas/personasView.vue')

/**
 * 消费行为
 */
const consumerBehavior = ()=>import(/* webpackChunkName: "consumerBehavior" */ '@/pages/customerOperation/consumerBehavior/consumerBehavior.vue')
const consumerBehaviorAdd = ()=>import(/* webpackChunkName: "consumerBehaviorAdd" */ '@/pages/customerOperation/consumerBehavior/consumerBehaviorAdd.vue')
const cunsumeIntervalInfo = ()=>import(/* webpackChunkName: "cunsumeIntervalInfo" */ '@/pages/customerOperation/consumerBehavior/cunsumeIntervalInfo.vue')

// 客户概览
const customerProfile = ()=>import(/* webpackChunkName: "customerProfile" */ '@/pages/customerOperation/customerProfile/index.vue')
const customerProfileNew = ()=>import(/* webpackChunkName: "customerProfile" */ '@/pages/customerOperation/customerProfile/indexNew.vue')

//增加小程序跳转路径
const toMiniApp = () => import('@/pages/customerOperation/toMiniApp/list.vue')
const toMiniAppAdd = () => import('@/pages/customerOperation/toMiniApp/add.vue')

//优惠券过期通知短信
const couponNotifyDetail = () => import(/* webpackChunkName: "couponNotifyDetail" */ '@/pages/customerOperation/couponNotify/detail/index.vue')
//优惠券管理
const drawRecord = () => import(/* webpackChunkName: "drawRecord" */ '@/pages/coupon/draw-record/index.vue')
const couponSendTaskIndex = () => import(/* webpackChunkName: "couponSendTask" */ '@/pages/coupon/sendTask/index.vue')
const couponSendTaskForm = () => import(/* webpackChunkName: "couponSendTask" */ '@/pages/coupon/sendTask/form.vue')
const couponSendTaskDetail = () => import(/* webpackChunkName: "couponSendTask" */ '@/pages/coupon/sendTask/detail.vue')

// 标签
const userTag = () => import(/* webpackChunkName: "userTag" */ '@/pages/customerOperation/userTag/index.vue')
//添加标签
const userTagAdd = () => import(/* userTag */ '@/pages/customerOperation/userTag/add/index.vue')

// 普通人群
const userGroup = () => import(/* userGroup */ '@/pages/customerOperation/userGroup')
// 普通人群新增
const userGroupAdd = () => import(/* userGroupAdd */ '@/pages/customerOperation/userGroup/add.vue')


//注册未下单发券策略
const registerNoOrder = () => import(/* webpackChunkName: "registerNoOrder" */ '@/pages/customerOperation/registerNoOrder/index.vue')

//偏好商品和偏好品类发券
const preference = () => import(/* webpackChunkName: "registerNoOrder" */ '@/pages/customerOperation/automaticPolicy/preference/index.vue')

//第三方开放平台授权
const wxThirdPartyAutho = () => import(/* webpackChunkName: "wxThirdPartyAutho" */ '@/pages/customerOperation/wxThirdParty/autho/index.vue')

//-------------自动化策略 V1--------
//场景人群列表
const userGroupSceneV1 = () => import(/* userGroupScene */ '@/pages/customerOperation/userGroupScene')
//查看场景人群详情
const userGroupSceneDetailV1 = () => import(/* userGroupSceneDetail */ '@/pages/customerOperation/userGroupScene/detail')
// 列表页
const automaticPolicyListV1 = () => import(/* automaticPolicyList */ '@/pages/customerOperation/automaticPolicy/V1/index.vue')
//配置页
const automaticPolicyConfigV1 = () => import(/* automaticPolicyConfig */ '@/pages/customerOperation/automaticPolicy/V1/config')
//数据查看页
const automaticPolicyDataV1 = () => import(/* automaticPolicyData */ '@/pages/customerOperation/automaticPolicy/V1/data')


//-------------自动化策略 V2--------
//自动化策略场景人群列表
const automaticPolicySceneListV2 = () => import(/* automaticPolicyList */ '@/pages/customerOperation/automaticPolicy/V2/scene/list/index.vue')
//自动化策略场景人群编辑
const automaticPolicySceneEditV2 = () => import(/* automaticPolicyList */ '@/pages/customerOperation/automaticPolicy/V2/scene/edit/index.vue')
//自动化策略列表
const automaticPolicyListV2 = () => import(/* automaticPolicyList */ '@/pages/customerOperation/automaticPolicy/V2/strategy/list/index.vue')
//自动化策略编辑
const automaticPolicyEditV2 = () => import(/* automaticPolicyList */ '@/pages/customerOperation/automaticPolicy/V2/strategy/edit/index.vue')
//看板
const automaticPolicyBoardV2 = () => import(/* automaticPolicyList */ '@/pages/customerOperation/automaticPolicy/V2/board/index.vue')
const automaticPolicyBoardDetailV2 = () => import(/* automaticPolicyList */ '@/pages/customerOperation/automaticPolicy/V2/board/detail.vue')


//--------通知管理----------

// 短信通知-添加
const shortMessageAdd = () => import('@/pages/shortMessage/shortMessageAdd.vue')
// 短信通知-详情
const shortMessageDetail = () => import(/* webpackChunkName: "shortMessageDetail" */ '@/pages/shortMessage/shortMessageDetail.vue')

//服务通知-模板列表
const subscribeTemplateList = () => import(/* webpackChunkName: "wxThirdPartyAutho" */ '@/pages/customerOperation/notification/subscribe/index.vue')
// 通知模板配置
const templateConfig = () => import(/* webpackChunkName: "wxThirdPartyAutho" */ '@/pages/customerOperation/notification/subscribe/templateConfig/index.vue')
// 通知模板配置 -- 填充规则
const fillTemplateRule = () => import(/* webpackChunkName: "wxThirdPartyAutho" */ '@/pages/customerOperation/notification/subscribe/templateConfig/fillRule.vue')


// 服务通知-创建推送
const wxSubscribeAdd = () => import(/* webpackChunkName: "wxThirdPartyAutho" */ '@/pages/customerOperation/notification/subscribe/add/index.vue')


// 企微SCRM
const enterpriseSCRM = () => import(/* customerGroup */ '@/pages/customerOperation/notification/enterpriseSCRM/index')

//群发通知
const enterpriseBatchSend = () => import(/* customerGroup */ '@/pages/customerOperation/notification/enterpriseSCRM/batchSend/index')
const enterpriseBatchSendAdd = () => import(/* customerGroup */ '@/pages/customerOperation/notification/enterpriseSCRM/batchSend/add')

// 企微活码拉新Live
const scrmIndex = () => import(/* scrm */ '@/pages/customerOperation/scrm/index')
const scrmChannelCode = () => import(/* scrm */ '@/pages/customerOperation/scrm/channelCode')
const scrmChannelCodeWizard = () => import(/* scrm */ '@/pages/customerOperation/scrm/channelCodeBatchCreate')
const scrmChannelCodeForm = () => import(/* scrm */ '@/pages/customerOperation/scrm/channelCodeForm')
const scrmLanding = () => import(/* scrm */ '@/pages/customerOperation/scrm/landing')
const scrmLandingWizard = () => import(/* scrm */ '@/pages/customerOperation/scrm/landingWizard')
const scrmMiniProgram = () => import(/* scrm */ '@/pages/customerOperation/scrm/miniprogram')
const scrmMiniProgramWizard = () => import(/* scrm */ '@/pages/customerOperation/scrm/miniprogramWizard')


export default [
	//用户分群召回
	{
		path: '/deepSleepUserRecall',
		name: 'deepSleepUserRecall',
		component: deepSleepUserRecall
	},
	{
		path: '/deepSleepUserRecall/deepSleepUserRecallAdd',
		name: 'deepSleepUserRecallAdd',
		component: deepSleepUserRecallAdd
	},
	{
		path: '/deepSleepUserRecall/deepSleepUserRecallEdit/:uid',
		name: 'shopClassifyEdit',
		component: deepSleepUserRecallAdd
	},
	//小程序PUSH
	{
		path: '/miniPUSH',
		name: 'miniPUSH',
		component: miniPUSH
	},
	{
		path: '/miniPUSH/miniPUSHAdd',
		name: 'miniminiPUSHAddPUSH',
		component: miniPUSHAdd
	},
	{
		path: '/miniPUSH/miniPUSHEdit/:uid',
		name: 'miniPUSHEdit',
		component: miniPUSHAdd
	},
	//用户画像
	{
		path: '/personas',
		name: 'personas',
		component: personas
	},
	{
		path: '/personas/personasView/:uid/:title',
		name: 'personasView',
		component: personasView
	},
	//消费行为
	{
		path: '/consumerBehavior',
		name: 'consumerBehavior',
		component: consumerBehavior
	},
	{
		path: '/consumerBehavior/consumerBehaviorAdd',
		name: 'consumerBehaviorAdd',
		component: consumerBehaviorAdd
	},
	{
		path: '/consumerBehavior/consumerBehaviorEdit/:uid',
		name: 'consumerBehaviorEdit',
		component: consumerBehaviorAdd
	},
	{
		path: '/consumerBehavior/cunsumeIntervalInfo/:uid',
		name: 'cunsumeIntervalInfo',
		component: cunsumeIntervalInfo
	},
	//客户概览
	{
		path: '/customerProfile',
		name: 'customerProfile',
		component: customerProfile
	},

	{
		path: '/customerProfile/new',
		name: 'customerProfile',
		component: customerProfileNew
	},
	// 短信营销
	{
		path: '/shortMessage/shortMessageAdd',
		name: 'shortMessageAdd',
		component: shortMessageAdd
	},
	{
		path: '/shortMessage/shortMessageDetail',
		name: 'shortMessageDetail',
		component: shortMessageDetail
	},
	{
		path: '/toMiniApp',
		name: 'toMiniApp',
		component: toMiniApp
	},
	{
		path: '/toMiniApp/toMiniAppAdd',
		name: 'toMiniAppAdd',
		component: toMiniAppAdd
	},
	{
		path: '/toMiniApp/toMiniAppEdit',
		name: 'toMiniAppEdit',
		component: toMiniAppAdd
	},
	//优惠券过期通知 短信
	{
		path: '/couponNotify/detail/:id',
		name: 'toMiniAppEdit',
		component: couponNotifyDetail
	},
	//优惠券管理
	{
		path: '/coupon/draw-record',
		name: 'drawRecord',
		component: drawRecord
	},
	//优惠券投放任务
	{
		path: '/coupon/send/tasks',
		name: 'couponSendTask',
		component: couponSendTaskIndex
	},
	{
		path: '/coupon/send/task/new',
		name: 'couponSendTaskNew',
		component: couponSendTaskForm
	},
	{
		path: '/coupon/send/task/edit/:id',
		name: 'couponSendTaskEdit',
		component: couponSendTaskForm
	},
	{
		path: '/coupon/send/task/detail/:id',
		name: 'couponSendTaskDetail',
		component: couponSendTaskDetail
	},
	//标签
	{
		path: '/customerOperation/userTag',
		name: 'userTag',
		component: userTag
	},
	{
		path: '/customerOperation/userTag/add',
		name: 'userTagAdd',
		component: userTagAdd
	},
	{
		path: '/customerOperation/userTag/edit/:uid',
		name: 'userTagAdd',
		component: userTagAdd
	},

	//人群管理2.0
	{
		path: '/customerOperation/userGroup',
		name: 'userGroup',
		component: userGroup
	},
	{
		path: '/customerOperation/userGroup/add',
		name: 'userGroupAdd',
		component: userGroupAdd
	},
	{
		path: '/customerOperation/userGroup/edit/:uid',
		name: 'userGroupAdd',
		component: userGroupAdd
	},

	//注册未下单发券策略
	{
		path: '/registerNoOrder',
		name: 'registerNoOrder',
		component: registerNoOrder
	},

	//偏好商品
	{
		path: '/preference/goods',
		name: 'preference/goods',
		component: preference
	},
	//偏好品类
	{
		path: '/preference/category',
		name: 'preference/category',
		component: preference
	},

	//微信第三方平台授权
	{
		path: '/customerOperation/wxThirdParty/autho',
		name: '/customerOperation/wxThirdParty/autho',
		component: wxThirdPartyAutho
	},
	//订阅通知模板列表
	{
		path: '/customerOperation/notification/subscribe',
		name: '/customerOperation/notification/subscribe',
		component: subscribeTemplateList
	},
	// 通知模板配置
	{
		path: '/customerOperation/notification/subscribe/templateConfig',
		name: 'templateConfig',
		component: templateConfig
	},
	{
		path: '/customerOperation/notification/subscribe/templateConfig/fillRule',
		name: 'fillTemplateRule',
		component: fillTemplateRule
	},
	//订阅通知--创建推送
	{
		path: '/customerOperation/notification/subscribe/add',
		name: 'wxSubscribeAdd',
		component: wxSubscribeAdd
	},


	//-----------自动化策略 V1--------------
	// 场景人群列表
	{
		path: '/customerOperation/userGroup/scene',
		name: 'userGroupScene',
		component: userGroupSceneV1
	},
	// 场景人群详情
	{
		path: '/customerOperation/userGroup/scene/detail/:id',
		name: 'userGroupSceneDetail',
		component: userGroupSceneDetailV1
	},
	//列表
	{
		path: '/customerOperation/automaticPolicy/list/:sceneCategoryId',
		name: '/customerOperation/automaticPolicy/list',
		component: automaticPolicyListV1
	},
	//创建
	{
		path: '/customerOperation/automaticPolicy/config/:sceneCategoryId',
		name: '/customerOperation/automaticPolicy/config',
		component: automaticPolicyConfigV1
	},
	//编辑
	{
		path: '/customerOperation/automaticPolicy/config/:sceneCategoryId/:id',
		name: '/customerOperation/automaticPolicy/config',
		component: automaticPolicyConfigV1
	},
	//查看数据
	{
		path: '/customerOperation/automaticPolicy/data/:sceneCategoryId/:id',
		name: '/customerOperation/automaticPolicy/data',
		component: automaticPolicyDataV1
	},



//-------------自动化策略 V2--------
//场景人群列表
{
	path: '/customerOperation/automaticPolicy/scene/list',
	name: 'automaticPolicyScene',
	component: automaticPolicySceneListV2
},
//场景人群编辑
{
	path: '/customerOperation/automaticPolicy/scene/edit/:id/:name',
	name: 'automaticPolicyScene',
	component: automaticPolicySceneEditV2
},
//自动化策略列表
{
	path: '/customerOperation/automaticPolicy/strategy/list',
	name: 'automaticPolicyScene',
	component: automaticPolicyListV2
},
//自动化策略创建
{
	path: '/customerOperation/automaticPolicy/strategy/create/:sceneId/:name',
	name: 'automaticPolicyScene',
	component: automaticPolicyEditV2
},
//自动化策略编辑
{
	path: '/customerOperation/automaticPolicy/strategy/edit/:id/:name',
	name: 'automaticPolicyScene',
	component: automaticPolicyEditV2,
	meta: { disabled: false }
},
//自动化策略查看详情
{
	path: '/customerOperation/automaticPolicy/strategy/detail/:id/:name',
	name: 'automaticPolicyScene',
	component: automaticPolicyEditV2,
	meta: { disabled: true }
},
//自动化策略看板
{
	path: '/customerOperation/automaticPolicy/board',
	name: 'automaticPolicyScene',
	component: automaticPolicyBoardV2
},
//自动化策略看板详情
{
	path: '/customerOperation/automaticPolicy/board/detail/:id',
	name: 'automaticPolicyBoardDetail',
	component: automaticPolicyBoardDetailV2
},


	//--------------通知-------------
	// 企微SCRm
	{
		path: '/customerOperation/notification/enterpriseSCRM/index',
		name: 'enterpriseSCRM',
		component: enterpriseSCRM
	},

	// 群发通知
	{
		path: '/customerOperation/notification/enterpriseWechat/batchSend',
		name: 'enterpriseBatchSend',
		component: enterpriseBatchSend
	},
	{
		path: '/customerOperation/notification/enterpriseWechat/batchSend/add',
		name: 'enterpriseBatchSendAdd',
		component: enterpriseBatchSendAdd
	},
	{
		path: '/customerOperation/notification/enterpriseWechat/batchSend/edit/:id',
		name: 'enterpriseBatchSendAdd',
		component: enterpriseBatchSendAdd
	},


  // 活码拉新
	{
		//name: 'scrmIndex',
		path: '/scrm',
		component: scrmIndex,
		children: [
			{
				path: '',
				name: 'scrmHome',
				redirect: '/scrm/channelCode'
			},
			{
				path: 'channelCode',
				name: 'scrmChannelCode',
				component: scrmChannelCode,
			},
			{
				path: 'landing',
				name: 'scrmLanding',
				component: scrmLanding,
			},
			{
				path: 'miniprogram',
				name: 'scrmMiniProgram',
				component: scrmMiniProgram,
			},
		]
	},
	{
		path: '/scrm/channelCode/create',
		name: 'scrmChannelCodeWizard',
		component: scrmChannelCodeWizard,
	},
	{
		path: '/scrm/channelCode/:id',
		name: 'scrmChannelCodeForm',
		component: scrmChannelCodeForm,
	},
	{
		path: '/scrm/landing/:id',
		name: 'scrmLandingForm',
		component: scrmLandingWizard,
	},
	{
		path: '/scrm/landingWizard',
		name: 'scrmLandingWizard',
		component: scrmLandingWizard,
	},
	{
		path: '/scrm/miniprogram/:id',
		name: 'scrmMiniProgramForm',
		component: scrmMiniProgramWizard,
	},
	{
		path: '/scrm/miniprogramWizard',
		name: 'scrmMiniProgramWizard',
		component: scrmMiniProgramWizard,
	},
];
