/**
 * 团购管理
 */
const grouponAdd = () => import(/* webpackChunkName: "groupon" */ '@/pages/trade/groupon/commodity/add/goods/index'); //团购添加
const grouponEdit = () => import(/* webpackChunkName: "groupon" */ '@/pages/trade/groupon/commodity/add/goods/index'); //商品团购编辑
const grouponView = () => import(/* webpackChunkName: "groupon" */ '@/pages/trade/groupon/commodity/add/goods/index'); //团购查看
const grouponManager = () => import(/* webpackChunkName: "groupon" */ '@/pages/trade/groupon/commodity/index'); //商品团购列表
const grouponTakeout = () => import(/* webpackChunkName: "groupon" */ '@/pages/trade/groupon/commodity/index'); //外卖团购列表
const grouponStores = () => import(/* webpackChunkName: "groupon" */ '@/pages/trade/groupon/store/index'); //团购平台首页店铺列表
const grouponCoupon = () => import(/* webpackChunkName: "groupon" */ '@/pages/trade/groupon/coupon/index'); //团购券管理
const grouponCouponAdd = () => import(/* webpackChunkName: "groupon" */ '@/pages/trade/groupon/coupon/add/index'); //团购券管理
const grouponCouponEdit = () => import(/* webpackChunkName: "groupon" */ '@/pages/trade/groupon/coupon/add/index'); //团购券编辑
const grouponMallGoods = () => import(/* webpackChunkName: "groupon" */ '@/pages/trade/groupon/mall/index'); //快递团购管理

const grouponTakeoutAdd = () => import(/* webpackChunkName: "groupon" */ '@/pages/trade/groupon/commodity/add/takeout/index'); //外卖团购新增
const grouponTakeoutEdit = () => import(/* webpackChunkName: "groupon" */ '@/pages/trade/groupon/commodity/add/takeout/index'); //外卖团购编辑

export default [
    {
        path: '/trade/groupon/index',
        name: '/trade/groupon/index',
        component: grouponManager
    },
    {
        path: '/trade/groupon/takeout',
        name: '/trade/groupon/takeout',
        component: grouponTakeout
    },
    {
        path: '/trade/groupon/add',
        name: '/trade/groupon/add',
        component: grouponAdd
    },
    {
        path: '/trade/groupon/edit',
        name: '/trade/groupon/edit',
        component: grouponEdit
    },
    {
        path: '/trade/groupon/takeoutadd',
        name: '/trade/groupon/takeoutadd',
        component: grouponTakeoutAdd
    },
    {
        path: '/trade/groupon/takeoutedit',
        name: '/trade/groupon/takeoutedit',
        component: grouponTakeoutEdit
    },
    {
        path: '/trade/groupon/view',
        name: '/trade/groupon/view',
        component: grouponView
    },
    {
        path: '/trade/groupon/stores',
        name: '/trade/groupon/stores',
        component: grouponStores
    },
    {
        path: '/trade/groupon/coupon',
        name: '/trade/groupon/coupon',
        component: grouponCoupon,
    },
    {
        path: '/trade/groupon/coupon/add',
        name: '/trade/groupon/coupon/add',
        component: grouponCouponAdd
    },
    {
        path: '/trade/groupon/coupon/edit',
        name: 'trade/groupon/coupon/edit',
        component: grouponCouponEdit
    },
    {
        path: '/trade/groupon/mall/index',
        name: 'tradeGrouponMallIndex',
        component: grouponMallGoods
    },
]
