<template>
  <div class="header-container" :class="{ 'header-container-menu-fold': isCollapse }">
    <div class="lf">
      <div class="brand-logo">
        <a href="/insight360/">
          <img class="mz-logo" :src="mzLogo" />
        </a>
      </div>
      <div class="module-list">
        <div
          v-for="(module, index) in modules"
          :key="module.key"
          @click="handleClick(module)"
          class="menu-list__item"
          :class="{
            disabled: module.status != 1,
            'menu-list__item--active': module.id === currentModuleId,
          }"
        >
          {{ module.moduleName }}
        </div>
      </div>
    </div>

    <div class="rt">
      <a-dropdown placement="bottomRight" trigger="click" :overlayStyle="{ zIndex: '9999999992' }">
        <div @click="(e) => e.preventDefault()" class="qrcodeBox">
          <img src="https://pic.mzdata.top/50/c1/50c1481dc78dea6b8eba1042910d6880_200_200.png" />
          <div class="name">明之门店</div>
          <div class="deadLine" :style="{ color: !status ? '#1969f1' : '' }">{{ vipExpired }}</div>
        </div>
        <div slot="overlay" class="contentBox">
          <div class="topContent">
            <div class="title">微信扫描二维码</div>
            <img src="https://pic.mzdata.top/9f/7b/9f7b8f84aa654d76806fb8888160cdd2_344_344.jpg" />
            <div class="desc">{{ `更多精彩服务尽在\n “明之门店”小程序` }}</div>
          </div>
          <div class="bottomContent">
            {{ !status ? '扫码开通会员' : `服务有效期至${vipExpired}` }}
          </div>
        </div>
      </a-dropdown>
      <a-dropdown placement="bottomRight" :overlayStyle="{ zIndex: '9999999992' }">
        <div @click="(e) => e.preventDefault()" class="tanant-area">
          <img :src="$store.state.tenant.tenant.logo" alt="" class="tenant-logo" />
          <div class="name-and-role">
            <span class="user-name">{{ showUserNameAndTenant }}</span>
            <span class="user-name role-name">{{ showRole }}</span>
          </div>
          <a-icon class="down-icon" type="down" />
        </div>
        <tenentDrawer slot="overlay" />
      </a-dropdown>
    </div>
  </div>
</template>

<script>
import SettingDrawer from './components/SettingDrawer';
import tenentDrawer from './components/tenentDrawer';
import { mapState } from 'vuex';
let timer = null;
let timer1 = null;

export default {
  name: 'mzHeader',
  props: {
    menuIsShow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showSetting: false,
      status: 0,
    };
  },
  computed: {
    ...mapState({
      modules: (state) => state.menu.modules,
      currentMenu: (state) => state.menu.currentMenu,
      isCollapse: (state) => state.menu.isCollapse,
    }),
    currentModuleId() {
      return this.currentMenu && this.currentMenu.moduleId ? this.currentMenu.moduleId : null;
    },

    showUserNameAndTenant() {
      const name = this.$store.state.tenant.userInfo.realName;
      const tenantName = this.$store.state.tenant.tenant.name;
      return `${name} - ${tenantName || ''} `;
    },
    showRole() {
      const rolesName = (this.$store.state.tenant.roles || []).map((i) => i.name).join(',');
      return `${rolesName || ''} `;
    },
    // tenantName() {
    //   return this.$store.state.tenant.tenant.name;
    // },
    mzLogo() {
      return this.isCollapse
        ? 'https://pic.mzdata.top/08/b0/08b090c4861463359580a6d573c3c7d4_102_102.png'
        : 'https://pic.mzdata.top/b0/1d/b01dc4c827e8e2f55fe87a315f07ba94_228_64.png';
    },
    // 获取vip过期时间
    vipExpired() {
      const vipExpired = this.$store.state.tenant.vipInfo.vipExpired;
      this.status = this.$store.state.tenant.vipInfo.status;
      // //0-非会员 1-试用版 2-续费版
      return !this.status ? '立即续费' : `${vipExpired} `;
    },
  },
  components: {
    SettingDrawer,
    tenentDrawer,
  },
  methods: {
    // 点击模块
    handleClick(item) {
      this.$emit('change', item);
    },

    changeShow() {
      // if(this.loading) return
      this.showSetting && (this.showSetting = false);
      this.$emit('changeShow');
    },
    showModule() {
      timer = setTimeout(() => {
        this.$emit('showModule');
        // this.loading = true
        setTimeout(() => {
          // this.loading = false
        }, 500);
      }, 300);
    },
    clickShowSetting() {
      // clearTimeout(timer1)
      if (this.menuIsShow) {
        this.$emit('closeMenu');
      }
      this.showSetting = !this.showSetting;
    },
    handleShowSetting() {
      timer1 = setTimeout(() => {
        this.showSetting = true;
      }, 300);
    },
    handleMouseOver() {
      clearTimeout(timer);
      clearTimeout(timer1);
    },
    showConfig() {},
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/mixin.scss';

.contentBox {
  width: 190px;
  padding: 16px;
  border-radius: 8px;
  background-color: #ffffff;
  border: 1px solid #e9edf4;
  margin-top: 8px;
  .topContent {
    padding: 8px 0;
    color: #1d2129;
    font-weight: 400;
    border-bottom: 1px solid #e9edf4;
    text-align: center;
    img {
      margin: 12px 0;
      width: 88px;
      height: 88px;
    }
    .desc {
      font-size: 12px;
      white-space: pre-wrap;
    }
  }
  .bottomContent {
    border-radius: 16px;
    background-color: #f0f6ff;
    margin-top: 12px;
    color: #6b7381;
    font-weight: 400;
    font-size: 12px;
    width: 100%;
    text-align: center;
    padding: 8px 0;
  }
}
.header-container {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  height: 64px;
  width: 100%;
  box-shadow: 0px 2px 6px 0px #eff1fa;
  border-radius: 0px 0px 16px 16px;
  background: #ffffff;
  @include flex-mixin(row, center, space-between);
  .lf {
    @include flex-mixin(row, center, flex-start);
  }
  .rt {
    display: flex;
    margin-left: 8px;
    .qrcodeBox {
      border-radius: 8px;
      background-color: #f0f6ff;
      padding: 12px 10px;
      width: 190px;
      height: 40px;
      margin: auto 8px;
      @include flex-mixin(row, center, center);
      &:hover {
        cursor: pointer;
      }
      img {
        width: 20px;
        height: 20px;
      }
      .name {
        margin-left: 8px;
        margin: 5px;
        color: #212d42;
      }
      .deadLine {
        color: #6b7381;
        font-weight: 400;
        font-size: 12px;
        padding-left: 5px;
        border-left: 1px solid #e9edf4;
        margin-top: 2px;
      }
    }
    .tanant-area {
      border-left: 1px solid #e9edf4;
      height: 64px;
      padding: 0 16px 0 8px;
      cursor: pointer;
      @include flex-mixin(row, center, space-between);

      .name-and-role {
        @include flex-mixin(column, flex-start, flex-start);
      }
      /deep/ .tenant-logo {
        width: 40px;
        height: 40px;
        background: #ffffff;
        border: 1px solid #e9edf4;
        padding: 6px;
        margin-right: 8px;
        border-radius: 50%;
      }

      &:hover {
        background-color: #ecf8ff;
        .down-icon {
          color: var(--active-color);
          transform: rotate(180deg);
        }
      }
    }

    .user-name {
      padding: 0 4px;
      max-width: 160px;
      font-size: 14px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      color: #2a3950;
      line-height: 20px;
      margin-bottom: 4px;

      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .role-name {
      font-size: 12px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      color: #6b7381;
      line-height: 17px;
    }
    .down-icon {
      margin-left: 4px;
      color: #6b7381;
      /* width: 16px; */
      font-size: 14px;
      transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    }
  }

  .brand-logo {
    width: 200px;
    padding-left: 16px;
    height: 64px;

    display: flex;
    align-items: center;
    justify-content: flex-start;

    background: #ffffff;
    border-bottom-left-radius: 16px;
    flex-shrink: 0;

    transition: width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .mz-logo {
    width: 114px;
    height: 32px;
  }

  .module-list {
    padding: 0 8px;
    @include flex-mixin(row, center, flex-start);
    overflow: auto;
    .menu-list__item {
      margin: 0 24px;
      height: 64px;
      line-height: 64px;
      position: relative;
      color: var(--menu-text-color);
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      flex-shrink: 0;
      cursor: pointer;
      &:hover {
        color: var(--active-color);
        font-weight: bold;
        &::after {
          position: absolute;
          content: '';
          bottom: 0;
          left: 0;
          width: 100%;
          height: 4px;
          background: #1969f1;
          border-radius: 100px 100px 0px 0px;
        }
      }
    }
    .menu-list__item--active {
      color: var(--active-color);
      font-weight: bold;
      &::after {
        position: absolute;
        content: '';
        bottom: 0;
        left: 0;
        width: 100%;
        height: 4px;
        background: var(--active-color);
        border-radius: 100px 100px 0px 0px;
      }
    }
  }
}

.header-container-menu-fold {
  .brand-logo {
    width: 88px;
  }
  .mz-logo {
    width: 31px;
  }
}

::-webkit-scrollbar {
  height: 4px;
}
::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background: rgba($color: #666, $alpha: 0.2);
}

::-webkit-scrollbar-track {
  border-radius: 0;
  background: rgba($color: #fff, $alpha: 0.2);
}
</style>
