
// 门店
//banner管理
const bannerList = () => import(/* webpackChunkName:"wxappStore" */ '../../../pages/wxapp/store/banner/bannerList.vue');
const bannerAdd = () => import(/* webpackChunkName:"wxappStore" */ '../../../pages/wxapp/store/banner/bannerAdd.vue');
const miniStoreManage = () => import(/* webpackChunkName:"wxappStore" */ '../../../pages/wxapp/store/storeManage');
const miniStoreManageAdd = () => import(/* webpackChunkName: "wxappStore" */ '../../../pages/wxapp/store/storeManage/add');

export default [
	{
		path: '/wxapp/store/bannerList',
		name: 'bannerList',
		component: bannerList
	},
	{
		path: '/wxapp/store/bannerAdd',
		name: 'bannerAdd',
		component: bannerAdd
  },
  {
    //人群管理编辑
    path: "/wxapp/store/bannerEdit/:uid",
    name: "bannerEdit",
    component: bannerAdd
	},
	

	// 门店管理
	{
		path: '/wxapp/storeManage',
		name: 'miniStoreManage',
		component: miniStoreManage
	},
	{
		path: '/wxapp/storeManage/add',
		name: 'miniStoreManageAdd',
		component: miniStoreManageAdd
	}
]