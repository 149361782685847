export default [
  // 档案模板管理
  {
    path: '/profile',
    name: 'profileDefaultIndex',
    redirect: '/profile/store/index',
  },
  {
    path: '/profile/:name/template',
    name: 'profileTemplateEdit',
    component: () => import('@/pages/profile/template/index.vue'),
  },
  // 档案列表
  {
    path: '/profile/:name/list',
    name: 'profileList',
    component: () => import('@/pages/profile/list/index.vue'),
  },
  // 档案详情
  {
    path: '/profile/:name/view/:id',
    name: 'profileViewDetail',
    component: () => import('@/pages/profile/view/index.vue'),
  },
  // 档案编辑页
  {
    path: '/profile/:name/edit/:id',
    name: 'profileEdit',
    component: () => import('@/pages/profile/detail/index.vue'),
  },
  {
    path: '/profile/:name/create',
    name: 'profileCreate',
    component: () => import('@/pages/profile/detail/index.vue'),
  },
];
