var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footer-wrapper"},[_c('div',{staticClass:"items"},[_c('div',[(_vm.enableSelection && _vm.enableFooterSelection)?_c('el-checkbox',{on:{"change":function($event){return _vm.$emit('toggleAllSelection')}},model:{value:(_vm.isFooterSelectedAll),callback:function ($$v) {_vm.isFooterSelectedAll=$$v},expression:"isFooterSelectedAll"}}):_vm._e()],1),_vm._t("footerActions")],2),_c('el-pagination',{attrs:{"current-page":_vm.pager.number,"page-size":_vm.pager.size,"page-sizes":_vm.pager.sizes,"total":_vm.totalCount,"layout":_vm.layout},on:{"size-change":(nVal) => {
                // update page size
                _vm.onChange({
                    size: nVal,
                });
            },"current-change":(nVal) => {
                // update page number
                _vm.onChange({
                    number: nVal,
                });
            }}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }