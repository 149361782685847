// 流程模板构建页面
const draw = () => import(/* webpackChunkName: "draw" */ '@/pages/workflow/template/draw/index');
// 流程模板列表
const workflowTemplateList = () =>
  import(/* webpackChunkName: "workflowTemplateList" */ '@/pages/workflow/template/list/index');
// 异常管理列表
const workflowNodeMngList = () =>
  import(/* webpackChunkName: "workflowNodeMngList" */ '@/pages/workflow/nodeMng/list/index');
// 数据管理列表
const workflowDataMngList = () =>
  import(/* webpackChunkName: "workflowDataMngList" */ '@/pages/workflow/dataMng/list/index');
// 数据管理列表
const workflowDataMngDetail = () =>
  import(/* webpackChunkName: "workflowDataMngDetail" */ '@/pages/workflow/dataMng/detail/index');
// 数据管理列表
const workflowDataMngConfigDetail = () =>
  import(
    /* webpackChunkName: "workflowDataMngConfigDetail" */ '@/pages/workflow/dataMng/configDetail/index'
  );

// 我的待办
const todoList = () =>
  import(/* webpackChunkName: "todoList" */ '@/pages/workflow/todoList/home/index');
// 待办详情
const todoListDetail = () =>
  import(/* webpackChunkName: "todoListDetail" */ '@/pages/workflow/todoList/detail/index');
// 我的已办
const doneList = () =>
  import(/* webpackChunkName: "doneList" */ '@/pages/workflow/doneList/home/index');
// 已办详情
const doneListDetail = () =>
  import(/* webpackChunkName: "doneListDetail" */ '@/pages/workflow/doneList/detail/index');
// 开店项目管理
const buildStore = () =>
  import(/* webpackChunkName: "buildStore" */ '@/pages/workflow/buildStore/list/index');
// 编辑表单数据
const editProjectForm = () =>
  import(/* webpackChunkName: "doneListDetail" */ '@/pages/workflow/buildStore/editForm/index');
// 项目管理详情
const buildStoreDetail = () =>
  import(/* webpackChunkName: "buildStoreDetail" */ '@/pages/workflow/buildStore/detail/index');
// 子流程发起记录
const buildStoreChildProcess = () =>
  import(
    /* webpackChunkName: "buildStoreChildProcess" */ '@/pages/workflow/buildStore/childProcess/index'
  );
// 子流程进度概览页
const buildStoreChildDetail = () =>
  import(
    /* webpackChunkName: "buildStoreChildProcess" */ '@/pages/workflow/buildStore/childProcess/detail'
  );
// 开店概览
const overview = () =>
  import(/* webpackChunkName: "buildStore" */ '@/pages/workflow/overview/index');
// 开店概览--light
const overviewLight = () =>
  import(/* webpackChunkName: "buildStore" */ '@/pages/workflow/overviewLight/index');
// 开店目标
const StoreGoalMng = () =>
  import(/* webpackChunkName: "StoreGoalMng" */ '@/pages/workflow/StoreGoalMng/index');
// 项目阶段管理
const projectStageMng = () =>
  import(/* webpackChunkName: "projectStageMng" */ '@/pages/workflow/projectStageMng/index');
// 任务交接
const taskTransfer = () =>
  import(/* webpackChunkName: "taskTransfer" */ '@/pages/workflow/taskTransfer/index');

export default [
  {
    path: '/workflow/template/draw',
    name: 'draw',
    component: draw,
  },
  {
    path: '/workflow/template/list',
    name: 'workflowTemplateList',
    component: workflowTemplateList,
  },
  {
    path: '/workflow/nodeMng/list',
    name: 'workflowNodeMngList',
    component: workflowNodeMngList,
  },
  {
    path: '/workflow/dataMng/list',
    name: 'workflowDataMngList',
    component: workflowDataMngList,
  },
  {
    path: '/workflow/dataMng/detail',
    name: 'workflowDataMngDetail',
    component: workflowDataMngDetail,
  },
  {
    path: '/workflow/dataMng/configDetail',
    name: 'workflowDataMngConfigDetail',
    component: workflowDataMngConfigDetail,
  },
  {
    path: '/workflow/todoList/home',
    name: 'todoList',
    component: todoList,
  },
  {
    path: '/workflow/todoList/detail',
    name: 'todoListDetail',
    component: todoListDetail,
  },
  {
    path: '/workflow/buildStore/list',
    name: 'buildStore',
    component: buildStore,
  },
  {
    path: '/workflow/projectMonitor/list',
    name: 'projectMonitor',
    component: buildStore,
  },
  {
    path: '/workflow/projectMonitor/editForm',
    name: 'editProjectForm',
    component: editProjectForm,
    meta: {
      keepAlive: true,
    },
  },

  {
    path: '/workflow/buildStore/detail',
    name: 'buildStoreDetail',
    component: buildStoreDetail,
    meta: {
      keepAlive: true,
    },
  },
  {
    path: '/workflow/buildStore/childProcess/index',
    name: 'buildStoreChildProcess',
    component: buildStoreChildProcess,
  },
  {
    path: '/workflow/buildStore/childProcess/detail',
    name: 'buildStoreChildDetail',
    component: buildStoreChildDetail,
  },
  {
    path: '/workflow/doneList/home',
    name: 'doneList',
    component: doneList,
  },
  {
    path: '/workflow/doneList/detail',
    name: 'doneListDetail',
    component: doneListDetail,
  },
  {
    path: '/workflow/overview',
    name: 'overview',
    component: overview,
  },
  {
    path: '/workflow/overviewLight',
    name: 'overviewLight',
    component: overviewLight,
  },
  {
    path: '/workflow/StoreGoalMng',
    name: 'StoreGoalMng',
    component: StoreGoalMng,
  },
  {
    path: '/workflow/projectStageMng',
    name: 'projectStageMng',
    component: projectStageMng,
  },
  {
    path: '/workflow/taskTransfer',
    name: 'taskTransfer',
    component: taskTransfer,
  },
];
