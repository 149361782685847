<template>
  <div class='commodity-tree'>
    <!-- 品类 层级类型的 -->
    <div v-if="category">
      <el-input
        class="filter"
        placeholder="输入关键字过滤"
        :clearable="true"
        v-model="filterText"
      ></el-input>

      <!-- <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane
          v-for="item in mor_level"
          :label="item.label"
          :name="item.name"
          :key="item.name"
        ></el-tab-pane>
      </el-tabs> -->

      <ul class="tree-tab-list">
        <li
          v-for="(item, index) in mor_level"
          :class="{ 'tree-tab--list-item': activeName == index }"
          @click="handleClick(index, item.name)"
          :key="index"
        >
          {{ item.label }}
        </li>
      </ul>
      <!--  v-loading="loading" -->
      <el-tree
        class="store-inner-tree"
        :data="data"
       
        ref="commodityTree"
        @check="test"
        @check-change="checkChanged"
        show-checkbox
        node-key="node_code"
        empty-text="暂无数据"
        @node-click="handleNodeClick"
        :indent="15"
        :props="defaultProps"
        :check-on-click-node="true"
        :expand-on-click-node="false"
        :default-checked-keys="checkedKeys"
        :default-expanded-keys="expandedKeys"
        :filter-node-method="filterNode"
        default-expand-all
      >
        <span class="el-tree-node" slot-scope="{ node }">
          <span>{{ node.label }}</span>
        </span>
      </el-tree>
    </div>

    <!-- 优惠券 -->
    <div v-if="coupon_type" class="coupon-tree">
      <el-input
        class="filter"
        placeholder="输入关键字过滤"
        :clearable="true"
        v-model="filterText1"
      ></el-input>

      <ul class="tree-tab-list" v-if="typesofcouponsType == false">
        <li
          v-for="(item, index) in btnList"
          :class="{ 'tree-tab--list-item': tabValue_label == item.id }"
          @click="treeTab_label(item.id)"
          :key="index"
        >
          {{ item.name }}
        </li>
      </ul>

      <!-- <el-tree
        class="store-inner-tree"
        :data="data2"
        v-loading="loading"
        ref="tree2"
        @check-change="checkChanged"
        show-checkbox
        node-key="id"
        empty-text
        :indent="15"
        :props="defaultPropscoupon"
        :check-on-click-node="true"
        :expand-on-click-node="false"
        :filter-node-method="filterNode1"
        default-expand-all
      > -->
      <!--  v-loading="loading" -->
      <el-tree
        v-if="typesofcouponsType"
        class="store-inner-tree"
        :data="data2"
        
        ref="tree2"
        @check-change="handleCheckChange_product"
        show-checkbox
        node-key="id"
        empty-text
        :indent="15"
        :props="defaultPropscoupon"
        :check-on-click-node="true"
        :expand-on-click-node="false"
        :filter-node-method="filterNode1"
        default-expand-all
      >
        <span class="el-tree-node" slot-scope="{ node }">
          <span>{{ node.label }}</span>
        </span>
      </el-tree>

      <div v-if="typesofcouponsType == false">
        <el-tree
          class="store-inner-tree"
          ref="tree2"
          @check-change="handleCheckChange_product"
          show-checkbox
          node-key="id"
          :indent="15"
          :props="defaultPropscoupon_load"
          :filter-node-method="filterNode1"
          lazy
          :load="loadNode"
          :default-expanded-keys="defaultarr"
        >
          <span class="el-tree-node" slot-scope="{ node }">
            <span>{{node | couponLabel}}</span>
          </span>
        </el-tree>
      </div>
    </div>

    <!-- 岗位单选  多选  -->
    <div v-if="scheduling">
      <div v-if="schedulingType == false">
        <el-input
          class="filter"
          placeholder="输入关键字过滤"
          :clearable="true"
          v-model="filterText2"
        ></el-input>
<!--  v-loading="loading" -->
        <el-tree
          class="store-inner-tree"
          :data="data3"
        
          ref="tree3"
          @check-change="checkChanged"
          show-checkbox
          node-key="code"
          empty-text
          :indent="15"
          :props="defaultPropsScheduling"
          :check-on-click-node="true"
          :expand-on-click-node="false"
          :filter-node-method="filterNode2"
          default-expand-all
        >
          <span class="el-tree-node" slot-scope="{ node }">
            <span>{{ node.label }}</span>
          </span>
        </el-tree>
      </div>

      <div v-if="schedulingType">
        <el-row style="margin: 5px 0 10px">
          <el-button
            type="text"
            style="padding: 0 !important;margin: 0 0 0 10px"
            @click="handleBtnClick(item)"
            v-for="(item, index) in btnList"
            :key="index"
            :class="item.activeFlag ? 'active' : 'deActive'"
          >
            {{ item.label }}
          </el-button>
        </el-row>
        <el-row :gutter="10">
          <el-col
            :span="24"
            v-for="(item, index) in dataList"
            :key="item.labelType"
          >
            <el-tree
              v-show="tabValue === item.labelType"
              class="store-inner-tree"
              :ref="'tagTree' + index"
              @check="test_dx('tagTree' + index)"
              :data="item.labels"
              show-checkbox
              node-key="labelCode"
              empty-text
              :indent="15"
              :props="defaultProps1"
              :check-on-click-node="true"
              :expand-on-click-node="false"
              @node-click="test_dx('tagTree' + index)"
              default-expand-all
            >
              <span class="el-tree-node" slot-scope="{ node }">
                <span>{{ node.label }}</span>
              </span>
            </el-tree>
          </el-col>
        </el-row>
      </div>
    </div>

    <!-- 分类树  -->
    <div v-if="classifications">
      <el-input
        class="filter"
        placeholder="输入关键字过滤"
        :clearable="true"
        v-model="filterText6"

      ></el-input>
<!--  v-loading="loading" -->
      <div style="margin: 0 20px">
        <el-tree
          class="store-inner-tree"
          :data="data7"
         
          ref="tree7"
          @check-change="checkChanged"
          show-checkbox
          node-key="node_code"
          empty-text
          :indent="15"
          :props="defaultPropsScheduling_fenlei"
          :check-on-click-node="true"
          :expand-on-click-node="false"
          :filter-node-method="filterNode6"
          default-expand-all
        >
          <span class="el-tree-node" slot-scope="{ node }">
            <span>{{ node.label }}</span>
          </span>
        </el-tree>
      </div>
    </div>

    <!-- 菜品单选  多选  -->
    <div v-if="product">
      <el-input
        class="filter"
        placeholder="输入关键字过滤"
        :clearable="true"
        v-model="filterText3"
      ></el-input>
<!--  v-loading="loading" -->
      <el-tree
        class="store-inner-tree"
        :data="data4"
       
        ref="tree4"
        @check-change="handleCheckChange"
        show-checkbox
        node-key="code"
        empty-text
        :indent="15"
        :props="defaultPropsScheduling"
        :check-on-click-node="true"
        :expand-on-click-node="false"
        :filter-node-method="filterNode3"
        default-expand-all
      >
        <span class="el-tree-node" slot-scope="{ node }">
          <span>{{ node.label }}</span>
        </span>
      </el-tree>
    </div>

    <!-- crowd人群树 -->
    <div v-if="crowd">
      <el-input
        class="filter"
        placeholder="输入关键字过滤"
        :clearable="true"
        v-model="filterText4"
      ></el-input>
<!--  v-loading="loading" -->
      <el-tree
        class="store-inner-tree"
        :data="data5"
        
        ref="tree5"
        @check-change="handleCheckChange"
        show-checkbox
        node-key="id"
        empty-text
        :indent="15"
        :props="defaultPropscoupon"
        :check-on-click-node="true"
        :expand-on-click-node="false"
        :filter-node-method="filterNode4"
        default-expand-all
      >
        <span class="el-tree-node" slot-scope="{ node }">
          <span>{{ node.label }}</span>
        </span>
      </el-tree>
    </div>

    <!-- labelTree 标签树 -->
    <div v-if="labelTree">
      <el-input
        class="filter"
        placeholder="输入关键字过滤"
        :clearable="true"
        v-model="filterText5"
      ></el-input>
<!--  v-loading="loading" -->
      <el-tree
        class="store-inner-tree"
        :data="data6"
        
        ref="tree6"
        @check-change="handleCheckChange"
        show-checkbox
        node-key="nodeCode"
        empty-text
        :indent="15"
        :props="defaultLabel"
        :check-on-click-node="true"
        :expand-on-click-node="false"
        :filter-node-method="filterNode5"
        default-expand-all
      >
        <span class="el-tree-node" slot-scope="{ node }">
          <span>{{ node.label }}</span>
        </span>
      </el-tree>
    </div>

    <!-- 策略树--消费行为营销 -->
    <div v-if="consumeBehvior" class="consume-behavior">
      <el-input
        class="filter"
        placeholder="输入关键字过滤"
        :clearable="true"
        v-model="filterText7"
        @input="chanegeFilter"
      ></el-input>
      <el-radio-group v-model="selectStrategyId" v-if="data8.length">
        <el-radio
          v-for="item in data8"
          :key="item.id"
          :label="item.id"
          @change="changeSelectStrategy">{{item.name}}</el-radio>
      </el-radio-group>
      <div v-else style="color: #87898c;margin:15px">暂无数据</div>
    </div>
  </div>
</template>

<script>
import {
  getCommodityTree_two,
  getPostTree,
  dishList,
  getPopulationTree,
  labelList, //标签树
} from "@/api/commonApi.js";
import debounce from "lodash/debounce";
import { handleFormateData } from "@/util/index";
import API from "../libs/API";
import {
  coupontree,
  coupontreeLabelList,
  goodstree_pinlei,
} from "@/api/userList.js";
import axios from "axios";
import { getStrategyList, getDataByStrategyId } from "@/api/customerOperation/consumerBehavior";

export default {
  props: {
    isDefaultAllChecked: {
      type: Boolean,
      default: true,
    },
    isOnlyShowOuter: {
      type: Boolean,
      default: false,
    },
    isCrossCategory: {
      type: Boolean,
      default: false,
    },
    defaultCheckCodes: {
      type: Array,
      default: () => [],
    },
    //显示分类树
    classifications: {
      type: Boolean,
      default: false,
    },
    //显示品类选项
    category: {
      type: Boolean,
      default: false,
    },
    //显示优惠劵选项
    coupon_type: {
      type: Boolean,
      default: false,
    },
    //显示岗位选项
    scheduling: {
      type: Boolean,
      default: false,
    },
    //岗位单选还是多选
    schedulingType: {
      type: Boolean,
      default: false,
    },

    //显示菜品选项
    product: {
      type: Boolean,
      default: false,
    },
    //岗位、菜品、人群 、 标签  优惠劵  单选 还是多选
    productType: {
      type: Boolean,
      default: false,
    },

    //人群树
    crowd: {
      type: Boolean,
      default: false,
    },
    //标签树
    labelTree: {
      type: Boolean,
      default: false,
    },
    // 策略树--消费行为营销
    consumeBehvior: {
      type: Boolean,
      default: false,
    },
    /*********************************************券 满减  兑换类型  区分***************************************************************/
    typesofcouponsType: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // 策略树--消费行为营销
      selectStrategyId: '',
      refList: [],
      tabValue: "",
      activeNametab: "",
      btnList: [],
      dataList: [],
      checkedKeys: [],
      expandedKeys: [],
      filterText: "",
      filterText1: "",
      filterText2: "",
      filterText3: "",
      filterText4: "",
      filterText5: "",
      filterText6: "",
      filterText7: "",
      defaultProps: {
        children: "children",
        label: "node_name",
      },
      defaultPropscoupon: {
        children: "child",
        label: "name",
      },
      defaultPropsScheduling: {
        children: "children",
        label: "name",
      },
      defaultPropsScheduling_fenlei: {
        children: "children",
        label: "node_name",
      },
      defaultLabel: {
        children: "children",
        label: "nodeName",
      },
      defaultProps1: {
        children: "children",
        label: "labelName",
      },

      data: [],
      data2: [],
      data22: [],
      data3: [],
      data4: [],
      data5: [],
      data6: [],
      data7: [],
      data8: [],
      loading: false,
      storeData: [],
      index: null,
      checked_level: "",
      totalLevel: "",
      mor_level: [],
      activeName: 0,
      tagList: "",
      tagListtype: "",
      onetag: "",
      couponIds: [],
      defaultPropscoupon_load: {
        children: "childList",
        label: "name",
        isLeaf: "isLeaf",
      },
      btnList: [],
      tabValue_label: "",
      dataList: [],
      nodeName: "",
      node_had: "",
      resolve_had: "",
      label_id: "",
      defaultarr: [],
      defaultarr1: [],
      level: "",
      levels: "",
      one_id: "",
      one_loadNode: true,
    };
  },
  watch: {
    filterText(val) {
      this.$refs.commodityTree.filter(val);
    },
    filterText1(val) {
      this.$refs.tree2.filter(val);
    },
    filterText2(val) {
      this.$refs.tree3.filter(val);
    },
    filterText3(val) {
      this.$refs.tree4.filter(val);
    },
    filterText4(val) {
      this.$refs.tree5.filter(val);
    },
    filterText5(val) {
      this.$refs.tree6.filter(val);
    },
    filterText6(val) {
      this.$refs.tree5.filter(val);
    },
    filterText7(val) {
      // this.$refs.tree5.filter(val);
    },
    schedulingType() {
      this.getSchedulingTree();
    },
    productType() {
      this.getproductTree();
      this.getCouponTree();
      this.treeTab_label(this.tabValue_label);
    },
    typesofcouponsType() {
      // console.log(this.typesofcouponsType, 222);

      // this.treeTab_label(this.tabValue_label);
      this.getCouponTree();
      this.checkChanged();
    },
  },
  filters: {
    couponLabel(data){
      return !data.isLeaf ? data.label : data.label + '(' + data.id + ')'
    }
  },
  methods: {
    treeTab_label(id) {
      this.tabValue_label = id;
      this.label_id = id;
      this.node_had.childNodes = []; //把存起来的node的子节点清空，不然会界面会出现重复树！
      this.loadNode(this.node_had, this.resolve_had); //再次执行懒加载的方法
    },

    loadNode(node, resolve) {
      if (node.level === 0) {
        this.node_had = node; //这里是关键！在data里面定义一个变量，将node.level == 0的node存起来
        this.resolve_had = resolve; //同上，把node.level == 0的resolve也存起来

        return coupontreeLabelList()
          .then((response) => {
            this.nodeName = response.data.data.nodeName;
            this.btnList = response.data.data.labels;
            if (this.label_id == "") {
              this.tabValue_label = response.data.data.labels[0].id;
            }

            this.dataList = response.data.data.tree;

            response.data.data.tree[0].id =
              this.tabValue_label + "_" + response.data.data.tree[0].id;

            response.data.data.tree[1].id =
              this.tabValue_label + "_" + response.data.data.tree[1].id;

            this.level = response.data.data.level;

            this.data22 = [
              {
                name: response.data.data.nodeName,
                id: response.data.data.nodeId,
                leaf: false,
                childList: [],
              },
            ];
            if (this.productType == false) {
              this.one_id = response.data.data.nodeId;
            }

            this.defaultarr = [
              ...response.data.data.treeNodes,
              response.data.data.tree[0].id,
            ];

            return resolve(this.data22);
          })
          .catch((error) => {
            this.$message.error(error);
          });
      }

      if (node.level === 1) {
        this.level = node.data.level;
        return resolve(this.dataList);
      }

      // if (node.level > this.level) return resolve([]);

      setTimeout(() => {
        return coupontree({
          level: node.data.level,
          condition: node.data.id,
          identityDisable: this.productType,
        })
          .then((response) => {
            // console.log(response);

            this.level = node.data.level;

            if (this.productType) {
              this.one_id = response.data.data.tree[0].id;
            }

            return resolve(response.data.data.tree);
          })
          .catch((error) => {
            this.$message.error(error);
          });
      }, 500);

      // if (this.one_loadNode) {
      this.$nextTick().then(() => {
        setTimeout(() => {
          this.$refs.tree2.setCheckedKeys([this.one_id]);
          this.one_loadNode = false;
          this.checkChanged;
        }, 1000);
      });
      //}
    },

    test_dx(str) {
      this.$nextTick(() => {
        let tagList = this.$refs[str][0].getCheckedKeys(true);

        if (tagList.length > 1) {
          let newtag = tagList.map((item) => {
            if (item != this.onetag[0]) {
              return item;
            }
          });
          this.$refs[str][0].setCheckedKeys(newtag);
          this.tagList = newtag;
        } else {
          this.$refs[str][0].setCheckedKeys(tagList);
          this.tagList = tagList;
          this.onetag = tagList;
        }

        this.$nextTick(() => {
          this.checkChanged();
        });
      });
    },
    test(data, data1) {
      if (this.isCrossCategory) {
        for (let i = 0, len = this.storeData.length; i < len; i++) {
          if (this.storeData[i].some((item) => item === data.node_code)) {
            this.index = i;
            break;
          }
        }
        this.$nextTick(() => {
          if (data1.checkedKeys.length > 0) {
            let arr = this.storeData[this.index];
            let result = data1.checkedKeys.filter((item) => arr.includes(item));
            this.$refs.commodityTree.setCheckedKeys(result);
          } else {
            this.$refs.commodityTree.setCheckedKeys([]);
          }
        });
      }
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.node_code.indexOf(value) !== -1;
    },
    filterNode1(value, data) {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },
    filterNode2(value, data) {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },
    filterNode3(value, data) {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },
    filterNode4(value, data) {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },
    filterNode5(value, data) {
      if (!value) return true;
      return data.nodeName.indexOf(value) !== -1;
    },
    filterNode6(value, data) {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },
    handleFormateCommodityTreeData(data) {
      data.forEach((item) => {
        if (item.children && item.children.length > 0) {
          item.children.forEach((zitem) => {
            if (zitem.children && zitem.children.length > 0) {
              delete zitem.children;
            }
          });
        }
      });
    },
    handleGetCommodityTree() {
      // this.loading = true;
      getCommodityTree_two({ level: 1 })
        .then((response) => {
          let data = response.data.data.goodsTree;

          if (this.isCrossCategory) {
            data[0].disabled = true;
          }

          this.expandedKeys = [data[0].node_code];

          if (response.data.data.goodsTree && response.data.data.goodsTree) {
            let dataStore = response.data.data.goodsTree[0].children;
            let arr = [];
            for (let i = 0, len = dataStore.length; i < len; i++) {
              let sArr = [];
              sArr.push(dataStore[i].node_code);
              for (
                let j = 0, len1 = dataStore[i].children.length;
                j < len1;
                j++
              ) {
                sArr.push(dataStore[i].children[j].node_code);
              }
              arr.push(sArr);
            }
            this.storeData = arr;
          }
          if (this.isOnlyShowOuter) {
            this.handleFormateCommodityTreeData(data);
          }

          this.checked_level = 1;

          let arr = [
            "一级",
            "二级",
            "三级",
            "四级",
            "五级",
            "六级",
            "七级",
            "八级",
            "九级",
            "十级",
          ];

          this.totalLevel = response.data.data.totalLevel;

          for (let index = 0; index < response.data.data.totalLevel; index++) {
            let dataarr = {
              label: arr[index],
              name: "tab" + index,
            };

            this.mor_level.push(dataarr);
          }

          this.data = data;
          this.$nextTick(() => {
            if (this.data) {
              this.$emit("load");
            }
            if (this.isDefaultAllChecked) {
              let result = [];
              handleFormateData(JSON.parse(JSON.stringify(data)), result);
              let arr = result.map((item) => item.node_code);
              this.$refs.commodityTree.setCheckedKeys(arr);
            } else if (this.defaultCheckCodes.length > 0) {
              let dataObj = JSON.parse(JSON.stringify(data));
              dataObj[0].children.forEach((item) => {
                if (this.defaultCheckCodes.includes(item.node_code)) {
                  let result = [];
                  result = item.children.map((sitem) => sitem.node_code);
                  this.checkedKeys = result;
                }
              });
            }
          });
        })
        .catch((error) => {
          this.$message.error(error);
        })
        .then(() => {
          // this.loading = false;
        });
    },
    classificationTree() {
      if (this.classifications == false) return;

      // this.loading = true;

      axios
        .get("/dynamic-promotion/skuCategory/tree", {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          console.log(response);

          this.data7 = response.data.data;

          let result = [];
          handleFormateData(
            JSON.parse(JSON.stringify(response.data.data)),
            result
          );
          let arr = result.map((item) => item.node_code);
          this.$refs.tree7.setCheckedKeys(arr);

          this.checkChanged();
        })
        .catch((error) => {
          this.$message.error(error);
        })
        .then(() => {
          // this.loading = false;
        });
    },
    /*************优惠券*********************************************************************************/
    getCouponTree() {
      if (this.coupon_type == false) return;

      // this.loading = true;

      let type = "";

      if (this.typesofcouponsType == false) {
        type = "?type=exchange";
      } else if (this.typesofcouponsType) {
        type = "?cancelType=online&channelType=message";
      } else {
        type = "";
      }

      let url = "/marketing/couponTree" + type;

      API.post(url)
        .then((response) => {
          this.data2 = [response.data.data[0]];

          if (this.productType == false) {
            this.$nextTick(() => {
              // 默认全部选中

              let result = [];
              handleFormateData(this.data2, result);
              let arr = result.map((item) => item.id);

              this.$refs.tree2.setCheckedKeys(arr);

              this.$refs.tree2.getCheckedNodes(true).forEach((val) => {
                val.type === 2 ? this.couponIds.push(val.id) : "";
              });
            });
          } else {
            this.$refs.tree2.setCheckedKeys([response.data.data[1].id]);
          }
          this.checkChanged();
        })
        .catch((error) => {
          this.$message.error(error);
        })
        .then(() => {
          // this.loading = false;
        });
    },
    getSchedulingTree() {
      if (this.scheduling == false) return;

      // this.loading = true;
      getPostTree({ single: this.schedulingType })
        .then((response) => {
          // console.log(response);

          if (this.schedulingType == false) {
            this.data3 = response.data.data;

            this.$nextTick(() => {
              // 默认全部选中

              let result = [];
              handleFormateData(this.data3, result);
              let arr = result.map((item) => item.code);

              this.$refs.tree3.setCheckedKeys(arr);
            });
          } else {
            let obj = response.data.data.map((item, index) => {
              let itemData = {};
              itemData.label = item.labelType;
              this.refList[index] = "tagTree" + index;
              if (index === 0) {
                itemData.activeFlag = true;
              } else {
                itemData.activeFlag = false;
              }
              itemData.code = item.labelType;
              return itemData;
            });

            this.btnList = obj;
            this.activeNametab = obj[0].labelType;
            this.tabValue = obj[0].label;
            this.dataList = response.data.data;
          }
        })
        .catch((error) => {
          this.$message.error(error);
        })
        .then(() => {
          // this.loading = false;
        });
    },

    getproductTree() {
      if (this.product == false) return;

      // this.loading = true;
      dishList({ single: false })
        .then((response) => {
          // console.log(response);

          this.data4 = response.data.data;

          if (this.productType == false) {
            this.$nextTick(() => {
              // 默认全部选中

              let result = [];
              handleFormateData(this.data4, result);
              let arr = result.map((item) => item.code);

              this.$refs.tree4.setCheckedKeys(arr);
            });
          } else {
            this.$refs.tree4.setCheckedKeys([]);
          }
        })
        .catch((error) => {
          this.$message.error(error);
        })
        .then(() => {
          // this.loading = false;
        });
    },
    /*********************************人群*********************************************/

    getcrowdTree() {
      if (this.crowd == false) return;

      // this.loading = true;

      getPopulationTree()
        .then((response) => {
          // console.log(response);

          this.data5 = response.data.data;

          if (this.productType == false) {
            this.$nextTick(() => {
              // 默认全部选中

              // let result = [];
              // handleFormateData(this.data5, result);
              // let arr = result.map(item => item.id);

              this.$refs.tree5.setCheckedKeys(["-1"]);
            });
          } else {
            this.$refs.tree5.setCheckedKeys([]);
          }
        })
        .catch((error) => {
          this.$message.error(error);
        })
        .then(() => {
          // this.loading = false;
        });
    },

    setDisabled(arr) {
      arr.forEach((val) => {
        if (!val.status) {
          val.disabled = true;
        }
        if (val.children.length) {
          this.setDisabled(val.children);
        }
      });
    },
    getLabelTree() {
      if (this.labelTree == false) return;

      // this.loading = true;

      labelList({ single: false })
        .then((response) => {
          // console.log(response);
          let data6 = [response.data.data];
          this.setDisabled(data6);

          this.data6 = data6;

          if (this.productType == false) {
            this.$nextTick(() => {
              // 默认全部选中

              let result = [];
              handleFormateData(this.data6, result);
              let arr = result.map((item) => {
                if (!item.disabled) return item.nodeCode;
              });

              this.$refs.tree6.setCheckedKeys(arr);
            });
          } else {
            this.$refs.tree6.setCheckedKeys([]);
          }
        })
        .catch((error) => {
          this.$message.error(error);
        })
        .then(() => {
          // this.loading = false;
        });
    },
    handleBtnClick(btnObj) {
      this.btnList.map((item) => {
        if (item.code !== btnObj.code) {
          item.activeFlag = false;
        }
      });
      btnObj.activeFlag = true;
      this.tabValue = btnObj.code;
    },
    handleNodeClick(node) {
      let array = [...this.$refs.commodityTree.getCheckedKeys()];
      if (array.indexOf("other") > -1) {
        array.splice(array.indexOf("other"), 1);
        this.$refs.commodityTree.setCheckedKeys(array);
      }
    },
    getCheckedStoreCodes() {
      return this.$refs.commodityTree.getCheckedKeys(true);
    },
    setCheckedStoreCodes(codes) {
      this.$refs.commodityTree.setCheckedKeys(codes);
    },
    handleClick(index, event) {
      this.activeName = index;
      this.checked_level = ++index;

      getCommodityTree_two({ level: this.checked_level })
        .then((response) => {
          let data = response.data.data.goodsTree;

          if (this.isCrossCategory) {
            data[0].disabled = true;
          }

          this.expandedKeys = [data[0].node_code];

          if (response.data.data.goodsTree && response.data.data.goodsTree) {
            let dataStore = response.data.data.goodsTree[0].children;
            let arr = [];
            for (let i = 0, len = dataStore.length; i < len; i++) {
              let sArr = [];
              sArr.push(dataStore[i].node_code);
              for (
                let j = 0, len1 = dataStore[i].children.length;
                j < len1;
                j++
              ) {
                sArr.push(dataStore[i].children[j].node_code);
              }
              arr.push(sArr);
            }
            this.storeData = arr;
          }
          if (this.isOnlyShowOuter) {
            this.handleFormateCommodityTreeData(data);
          }

          this.data = data;
          this.$nextTick(() => {
            if (this.data) {
              this.$emit("load");
            }
            if (this.isDefaultAllChecked) {
              let result = [];
              handleFormateData(JSON.parse(JSON.stringify(data)), result);
              let arr = result.map((item) => item.node_code);
              this.$refs.commodityTree.setCheckedKeys(arr);
            } else if (this.defaultCheckCodes.length > 0) {
              let dataObj = JSON.parse(JSON.stringify(data));
              dataObj[0].children.forEach((item) => {
                if (this.defaultCheckCodes.includes(item.node_code)) {
                  let result = [];
                  result = item.children.map((sitem) => sitem.node_code);
                  this.checkedKeys = result;
                }
              });
            }
          });
        })
        .catch((error) => {
          this.$message.error(error);
        })
        .then(() => {
          // this.loading = false;
        });
    },
    /*********************************策略树--消费行为营销*********************************************/
    // 获取策略列表
    async getStrategyList(search) {
      // console.log('getStrategyList',search)
      const res = await getStrategyList({pageNo: 0, pageSize:20, strategyName: search})
      if(res.data.status === '1') {
        this.data8 = res.data.data.list
        if(this.data8.length) {
          this.selectStrategyId = this.data8[0].id
          this.changeSelectStrategy(this.selectStrategyId)
        }else {
          this.selectStrategyId = ''
          this.$emit('changeSelectStrategy', -1, {})
        }
      }
    },
    // 获取策略详情
    async changeSelectStrategy(val) {
      const res = await getDataByStrategyId({strategyId: val})
      if(res.data.status === '1') {
        this.$emit('changeSelectStrategy', val, res.data.data)
      }
    },
  },
  mounted() {
    if (this.category) {
      this.handleGetCommodityTree();
    }
    if(this.consumeBehvior) {
      this.getStrategyList('')
    }

    this.getCouponTree();
    this.getSchedulingTree();
    this.getproductTree();
    this.getcrowdTree();
    this.getLabelTree();
    this.classificationTree();
  },
  created() {
    this.chanegeFilter =  debounce((data) => {
        this.getStrategyList(data)
      },300)
    this.checkChanged = debounce((data1,data2) => {
    // console.log('数据000',data1)

      if (this.$refs.tree2 != undefined) {
        this.couponIds = [];
        this.$refs.tree2.getCheckedNodes(true).forEach((val) => {
          val.type === 2 ? this.couponIds.push(val.id) : "";
        });
      }
      let storeCodes = {
        _data:this.$refs.commodityTree ? this.$refs.commodityTree.getCheckedNodes() :[],
        nodeCodes:
          this.$refs.commodityTree == undefined
            ? []
            : this.$refs.commodityTree.getCheckedKeys(),
        checked_level: this.checked_level,
        totalLevel: this.totalLevel,
        // couponIds:
        //   this.$refs.tree2 == undefined
        //     ? []
        //     : this.$refs.tree2.getCheckedKeys(true),
        couponIds:
          this.$refs.tree2 == undefined
            ? []
            : this.$refs.tree2.getCheckedKeys(),
        postsData: this.schedulingType
          ? this.tagList
          : this.$refs.tree3 == undefined
          ? []
          : this.$refs.tree3.getCheckedKeys(true),
        product:
          this.$refs.tree4 == undefined
            ? []
            : this.$refs.tree4.getCheckedKeys(true),
        crowd:
          this.$refs.tree5 == undefined
            ? []
            : this.$refs.tree5.getCheckedKeys(true),
        labeltree:
          this.$refs.tree6 == undefined
            ? []
            : this.$refs.tree6.getCheckedKeys(true),

        classificationtree:
          this.$refs.tree7 == undefined
            ? []
            : this.$refs.tree7.getCheckedKeys(true),


      };

      this.$emit("check-change", storeCodes);
    }, 1000);

    this.handleCheckChange = debounce((data, checked, indeterminate) => {
      if (this.productType) {
        if (checked) {
          let arr = [data.code];
          this.$refs.tree4.setCheckedKeys(arr);
        }
        this.checkChanged();
      } else {
        this.checkChanged();
      }
    }, 300);

    this.handleCheckChange_product = debounce(
      (data, checked, indeterminate) => {
        if (this.productType) {
          if (checked) {
            let arr = [data.id];
            this.$refs.tree2.setCheckedKeys(arr);
          }
          this.checkChanged();
        } else {
          this.checkChanged();
        }
      },
      200
    );
  },
};
</script>
<style scoped lang="scss">
.commodity-tree {
  .consume-behavior {
    padding: 8px 0;
    font-size: 14px !important;
    color: #87898c;

    .tenant-name {
      margin-bottom: 10px
    }
    /deep/ .el-radio-group {
      padding:10px 15px;
      
    }

    /deep/ .el-checkbox {
      margin-bottom: 14px;
      color: #87898c;
      display: block;
    }
    /deep/ .el-radio {
      margin-bottom: 14px;
      color: #87898c;
      display: block;
    }
  }
}
.app-body-side {
  position: relative;
}

.tree-tab-list-botton {
  height: 1px;
  margin: 16px 0 13px;
  background: #e4e4e4;
}

.tree-tab-list {
  list-style: none;
  overflow: hidden;
  margin: 0 15px 0px;

  li {
    float: left;
    padding: 0 11px;
    height: 29px;
    line-height: 29px;
    color: #999999;
    font-size: 12px;
    font-weight: 400;
    border: 1px solid #eaeaea;
    margin: 0 5px 10px 0;
    border-radius: 2px;
     cursor: pointer;
  }
}

.tree-tab--list-item {
  border: 1px solid #1682e6 !important;
  color: #1682e6 !important;
}
.collapseBtn {
  width: 10px;
  height: 20px;
  position: fixed;
  top: 50%;
  right: 0;
  margin-top: -10px;
  background-color: red;
}

.filter >>> .el-input__inner {
  border-width: 0;
  font-size: 12px;
}

.store-inner-tree {
  height: calc(100% - 40px);
  overflow-y: scroll;
}

.store-inner-tree >>> .el-tree-node__content {
  height: 22px;
}

.coupon-tree /deep/ .el-tree-node .el-tree-node__content{
  height: auto !important;
  padding: 4px 0;
}

.store-inner-tree .el-tree-node {
  font-size: 12px;
  white-space: break-spaces;
}

.store-inner-tree .el-tree-node .iconfont {
  font-size: 12px;
}

.store-inner-tree >>> .el-tree-node.is-current {
  background: transparent;
}

.store-inner-tree >>> .el-tree-node:focus > .el-tree-node__content {
  background: transparent;
}

.el-tree-node__content > .el-tree-node__expand-icon {
  color: #262626;
}
</style>
