<template>
  <div class="menu-container" :class="{ 'menu-container-collapsed': isCollapse }">
    <div class="current-menu">
      <div class="label">{{ moduleInfo.moduleName }}</div>
    </div>

    <a-menu
      class="menu"
      v-model="selectedKeys"
      :openKeys.sync="openKeys"
      mode="inline"
      :inlineIndent="isCollapse ? 20 : 16"
      @click="handleSelect"
    >
      <template v-for="menuItem in menus[currentMenu.moduleId]">
        <a-menu-item
          v-if="!menuItem.children"
          :key="menuItem.id"
          :disabled="menuItem.isAuthed == 0"
        >
          <div class="mz-menu-item">
            <MzIcon
              v-if="menuItem.menuIconCode"
              class="module-icon"
              :type="menuItem.menuIconCode"
            />
            <span class="menu-name">{{ menuItem.menuName }}</span>
          </div>
        </a-menu-item>
        <sub-menu
          v-else
          :key="menuItem.id"
          :menu-info="menuItem"
          :storeBuildTodoCount="storeBuildTodoCount"
          :abnormalNodeCount="abnormalNodeCount"
        />
      </template>
    </a-menu>
    <div class="collapse-container" @click="toggleCollapsed">
      <div class="collapse-btn">
        <a-icon :type="isCollapse ? 'menu-unfold' : 'menu-fold'" />
      </div>
      <span :class="['collapse-label', { active: !isCollapse }]" v-if="!isCollapse">折叠菜单</span>
    </div>
  </div>
</template>

<script>
import { Menu } from 'ant-design-vue';
import { setRecentMenuList } from '@/storage/menu';
import { mapState } from 'vuex';
import { getToken } from '@/util/cookie';
const SubMenu = {
  template: `
      <a-sub-menu :key="menuInfo.id" v-bind="$props" v-on="$listeners">
        <span slot="title">
          <div class="mz-menu-item">
            <MzIcon v-if="menuInfo.parentId == 0" class="module-icon" :type="menuInfo.menuIconCode || 'icon-jingyingfenxi'" />
            <div v-else-if="menuInfo.isSecondMenuFlag" class="sub-menu-circle" ></div>
            <span class="menu-name" :class="{'showToDoHint': (menuInfo.id == openStoreTaskMenuId && storeBuildTodoCount) || (menuInfo.id == projectMngMenuId && abnormalNodeCount) || (menuInfo.id == crmModuleId && crmTaskCount)}">{{menuInfo.menuName}}</span>
          </div>
        </span>
        <template v-for="item in menuInfo.children">
          <a-menu-item v-if="!item.children" :key="item.id" :disabled="item.isAuthed == 0 || !item.targetUrl || item.targetUrl === ' '">
            <div class="mz-menu-item">
              <div v-if="item.isSecondMenuFlag" class="sub-menu-circle"></div>
              <span class="menu-name">{{item.menuName==='RFM分析'?'客户分层':item.menuName}}</span>
              <a-badge :count="getItemBadgeCount(item)" style="margin-left:4px" :number-style="{ color: '#fff' }"  v-if="item.menuName=='待办' || item.menuName=='节点管控'|| item.menuName=='我的任务'" />
            </div>
          </a-menu-item>
          <sub-menu v-else :key="item.id" :menu-info="item" />
        </template>
      </a-sub-menu>
    `,
  name: 'SubMenu',
  // must add isSubMenu: true
  isSubMenu: true,
  props: {
    ...Menu.SubMenu.props,
    // Cannot overlap with properties within Menu.SubMenu.props
    menuInfo: {
      type: Object,
      default: () => ({}),
    },
    storeBuildTodoCount: {
      type: Number,
      default: 0,
    },
    abnormalNodeCount: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      menuNameMapCountKey: {
        待办: 'storeBuildTodoCount',
        节点管控: 'abnormalNodeCount',
      },
    };
  },
  computed: {
    ...mapState({
      storeBuildTodoCount: (state) => state.menu.storeBuildTodoCount,
      abnormalNodeCount: (state) => state.menu.abnormalNodeCount,
      crmTaskCount: (state) => state.menu.crmTaskCount,
    }),
    openStoreTaskMenuId() {
      let hostName = window.location.hostname;
      let isPre = hostName.includes('pre.mzdata.top');
      return process.env.NODE_ENV === 'development' || isPre ? 1033 : 1042;
    },
    projectMngMenuId() {
      let hostName = window.location.hostname;
      let isPre = hostName.includes('pre.mzdata.top');
      return process.env.NODE_ENV === 'development' || isPre ? 1083 : 1083;
    },
    crmModuleId() {
      let hostName = window.location.hostname;
      let isPre = hostName.includes('pre.mzdata.top');
      return process.env.NODE_ENV == 'development' || isPre ? 1172 : 1171;
    },
  },
  methods: {
    getItemBadgeCount(item) {
      return {
        '待办': this.storeBuildTodoCount,
        '节点管控': this.abnormalNodeCount,
        '我的任务': this.crmTaskCount,
      }[item.menuName] || 0;
    }
  }
};

export default {
  components: {
    'sub-menu': SubMenu,
  },

  data() {
    return {
      collapsed: false,
      matchingUrl: '', //当前页面匹配的菜单路径
      selectedKeys: [],
      openKeys: [],
    };
  },

  watch: {
    currentMenu: {
      handler(data) {
        this.selectedKeys = data.selectedKeys;
        this.openKeys = data.openKeys;
      },
      immediate: true,
    },
  },

  computed: {
    ...mapState({
      menus: (state) => state.menu.menus,
      modules: (state) => state.menu.modules,
      currentMenu: (state) => state.menu.currentMenu,
      isCollapse: (state) => state.menu.isCollapse,
      storeBuildTodoCount: (state) => state.menu.storeBuildTodoCount,
      abnormalNodeCount: (state) => state.menu.abnormalNodeCount,
    }),

    moduleInfo() {
      let res = this.modules.find((item) => item.id == this.currentMenu.moduleId);
      return res || {};
    },
  },

  methods: {
    handleSelect({ key }) {
      let menu = this.findMenuInfo('id', key);
      let realUrl = menu.targetUrl;

      console.log(menu.targetUrl);
      if (realUrl == '/customBi/home') {
        let token = getToken();
        let domainUrl = '';
        if (process.env.NODE_ENV === 'production') {
          domainUrl = 'https://bi.mzdata.top';
        } else {
          domainUrl = 'http://dev.bi.mzdata.top';
        }
        window.open(`${domainUrl}/transtoken?token=${token}`, '_blank');
        return;
      }

      this.$router.push(menu.targetUrl);
      setRecentMenuList(menu);
    },
    toggleCollapsed() {
      this.collapsed = !this.collapsed;
      this.$emit('handleCollapse', this.collapsed);
      if (this.collapsed) {
        this.openKeys = [];
      }
    },

    //查找菜单中对应字段的对应项
    findMenuInfo(key, value, source = this.menus, childrenKey = 'children') {
      let reslute = {};
      const handleFind = (menuItem, modules) => {
        menuItem.find((item) => {
          if (item[key] == value) {
            reslute = item;
          } else if (item[childrenKey]) {
            handleFind(item[childrenKey], modules);
          }
        });
      };

      for (let modules in source) {
        let menuItem = source[modules];
        handleFind(menuItem, modules);
      }
      return reslute;
    },
  },
};
</script>
<style lang="scss" scoped>
.menu-container {
  position: relative;
  width: 200px;
  // width: 208px;
  height: calc(100vh - 64px);
  overflow: auto;
  display: flex;
  // 纵向排列
  flex-direction: column;
  background: var(--page-background);

  .module-icon {
    font-size: 16px;
    margin-right: 8px;
  }
  & /deep/ .mz-menu-item {
    display: flex;
    align-items: center;
    position: relative;
    i,
    .menu-name {
      vertical-align: middle;
      white-space: nowrap;
      text-overflow: ellipsis;
      word-break: break-all;
      overflow: hidden;
    }
    .showToDoHint {
      position: relative;
      padding-right: 12px;
      &::after {
        position: absolute;
        content: '';
        width: 7px;
        height: 7px;
        border-radius: 50%;
        top: 15px;
        right: 0px;
        background: #f5222d;
      }
    }

    .sub-menu-circle {
      position: absolute;
      left: -18px;
      width: 6px;
      height: 6px;
      background: var(--menu-text-color);
      border-radius: 50%;
    }
  }

  & /deep/ .ant-menu-submenu-open {
    .ant-menu-item-selected,
    .ant-menu-submenu-selected,
    .ant-menu-submenu-active {
      .sub-menu-circle {
        background: var(--active-color);
      }
    }
  }
  & /deep/ .ant-menu-item-active {
    .sub-menu-circle {
      background: var(--active-color);
    }
  }

  .current-menu {
    position: sticky;
    top: 0px;
    z-index: 1;
    background: white;
    display: flex;
    align-items: center;
    padding: 16px 0 16px 24px;
    .label {
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: bold;
      color: #212d42;
      line-height: 22px;
      letter-spacing: 0em;
    }
  }

  .collapse-container {
    flex: 0 0 48px;
    position: sticky;
    bottom: 0;
    display: flex;
    align-items: center;
    padding: 0 16px 0 28px;
    width: 100%;
    box-shadow: 0px 1px 12px 0px rgba(0, 21, 41, 0.08);
    cursor: pointer;
    .collapse-label {
      margin-left: 8px;
      transition: all 0.2s;
      opacity: 0;
      font-size: 0;
      transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      &.active {
        opacity: 1;
        font-size: 12px;
      }
    }
  }

  .ant-menu-vertical .ant-menu-item:not(:last-child),
  .ant-menu-vertical-left .ant-menu-item:not(:last-child),
  .ant-menu-vertical-right .ant-menu-item:not(:last-child),
  .ant-menu-inline .ant-menu-item:not(:last-child) {
    margin-bottom: 0;
  }
}

/deep/ .ant-menu-submenu-inline .ant-menu-submenu-arrow {
  transform: rotate(-90deg);
  right: 8px;
}

.menu-container-collapsed {
  width: 88px;

  .current-menu {
    padding-left: 12px;
  }
  & /deep/ .module-icon {
    margin-right: 0 !important;
    line-height: 1 !important;
  }
  & /deep/ .mz-menu-item {
    flex-direction: column;
    align-items: center;
    .menu-name {
      width: 100%;
      margin-top: 4px;
      font-size: 12px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      line-height: 17px;
      opacity: 1 !important;
      max-width: unset !important;
    }
    .showToDoHint {
      padding-right: 10px;
      &::after {
        position: absolute;
        content: '';
        width: 5px;
        height: 5px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  .ant-menu-item,
  .ant-menu-submenu-vertical {
    height: 64px;
  }
}
</style>
